import React, {useState} from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from "components/button/Button";
import InputField from 'components/inputField/InputField';
import {useMutation} from 'react-query';
import fetch from 'services/fetch';
import {toast} from "react-toastify";
import NotificationContent from 'components/notifications/NotificationContent';

const UpdatePasswordForm = (props) => {
  const closeModalHandler = props.closeModal;
  const [passwordError, setPasswordError] = useState("");
  const [passwordNewError, setPasswordNewError] = useState("");
  const {mutateAsync: updatePassword} = useMutation(fetch('updatePassword'));

  return (
    <Formik
      initialValues={
        {
          password: '',
          password_new: '',
          password_confirm: ''
        }
      }
      validationSchema={Yup.object({
        password: Yup.string()
          .required('Old Password is required'),
        password_new: Yup.string()
          .min(8, 'New Password must be at least 8 characters')
          .when('password', (value, field) =>
            value ? field.required('New Password is required!') : field
          ),
        password_confirm: Yup.string()
          .when('password_new', {
            is: input => input && input.length > 7,
            then: Yup.string().oneOf([Yup.ref('password_new')], "Passwords doesn't match")
          })
      })}
      onSubmit={async (values, {setSubmitting}) => {
        setSubmitting(false);
        setPasswordError(null);
        setPasswordNewError(null);
        let data = await updatePassword({
          oldPassword: values.password,
          newPassword: values.password_new
        });
        if(!data.IsSuccess) {
          if(data.Key === 'PasswordWrong') {
            setPasswordError(data.Message);
          } else {
            setPasswordNewError(data.Message);
          }
        } else {
          closeModalHandler();
          toast(<NotificationContent type="success" description={"Your password has been successfully updated"} />, {
            className: 'white-toast default-toast'
          })
        }
      }}
    >
      {({errors, touched, isSubmitting}) => (
        <div className="">
          <Form className="grid">
            <div className="mb-6">
              <InputField classes="border-b border-metal" type="text" name="password" placeholder="Old Password">
                Old Password
              </InputField>
              {touched.password && (errors.password || passwordError) &&
              <div className="text-reor">{errors.password || passwordError}</div>}
            </div>
            <div className="mb-6">
              <InputField classes="border-b border-metal" type="text" name="password_new" placeholder="New Password">
                New Password
              </InputField>
              {touched.password_new && (errors.password_new || passwordNewError) &&
              <div className="text-reor">{errors.password_new || passwordNewError}</div>}
            </div>
            <div className="mb-12">
              <InputField classes="border-b border-metal" type="text" name="password_confirm" placeholder="Confirm Password">
                Confirm Password
              </InputField>
              {touched.password_confirm && (errors.password_confirm) &&
              <div className="text-reor">{errors.password_confirm}</div>}
            </div>

            <div className="flex items-center">
              <Button
                classes="py-3 mr-3"
                btnTheme="ghost"
                size="normal"
                clicked={closeModalHandler}
                case="uppercase">
                Cancel
              </Button>
              <Button
                isLoading={isSubmitting}
                type="submit"
                btnTheme="primary"
                size="normal"
                case="uppercase">
                <p className="small semibold">Update Password</p>
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
};

export default UpdatePasswordForm
