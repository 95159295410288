import React from 'react';

import InternalBeneficiary from './internalBeneficiary/InternalBeneficiary';
import ExternalBeneficiary from './externalBeneficiary/ExternalBeneficiary';
import Wrapper from 'components/wrapper/Wrapper';

const BeneficiaryDetails = (props) => {
    const params = new URLSearchParams(props.location.search);
    const isInternalString = params.get('isInternal');
    const isInternal = (isInternalString === 'true'); // convert string to bool

    return (
        <Wrapper>
            { isInternal ? <InternalBeneficiary /> : <ExternalBeneficiary /> }
        </Wrapper>
    )
}

export default BeneficiaryDetails;
