import { Field } from 'formik';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import queryConfig from 'services/query';

import classes from './DynamicInputs.module.css';

import Loading from 'components/loading/Loading';
import InputField from 'components/inputField/InputField'

const DynamicInputs = ({ rules, touched, errors, selectedCountry, nodeRef }) => {
    const { t } = useTranslation();

    const { data: staticData, isLoading: isLoadingStaticData } = useQuery(queryConfig.getStaticData({ refetchOnWindowFocus: false }));

    return <div ref={nodeRef}>
        {rules.aba &&
            <InputField
                name="aba"
                placeholder={t("labels.ABA")}
                errors={errors}
                touched={touched}
                classes='mb-3' >
                {t("labels.ABA")}
            </InputField>
        }
        {rules.bic &&
            <InputField
                name="bic"
                placeholder={t("labels.BIC")}
                errors={errors}
                touched={touched}
                classes='mb-3' >
                {t("labels.BIC")}
            </InputField>
        }
        {rules.accountNumber &&
            <InputField
                name="accountNumber"
                placeholder={t("labels.AccNumber")}
                errors={errors}
                touched={touched}
                classes='mb-3' >
                {t("labels.AccNumber")}
            </InputField>
        }
        {rules.sortCode &&
            <InputField
                name="sortCode"
                placeholder={t("labels.sortCode")}
                errors={errors}
                touched={touched}
                classes='mb-3' >
                {t("labels.sortCode")}
            </InputField>
        }
        {rules.iban &&
            <InputField
                name="iban"
                placeholder={t("labels.IBAN")}
                errors={errors}
                touched={touched}
                classes='mb-3' >
                {t("labels.IBAN")}
            </InputField>
        }
        {rules.country &&
            <div className="mb-3">
                <label className="caption flex text-desaturate-light" htmlFor="country">{t("labels.Country")}</label>
                <Loading condition={isLoadingStaticData}>
                    <Field name="country" className="select" as="select" disabled>
                        {staticData?.Countries ?
                            staticData.Countries.map(country => {
                                return <option key={country.Key} value={country.Key}>{country.Name}</option>
                            }) : <option>no data found</option>
                        }
                    </Field>
                </Loading>
            </div>
        }
        {rules.state && selectedCountry === "US" &&
            <div className="mb-3">
                <label className="caption flex text-desaturate-light" htmlFor="state">{t("labels.State")}</label>
                <Loading condition={isLoadingStaticData}>
                    <Field name="state" className="select" as="select">
                        {staticData?.UsaStates ?
                            staticData.UsaStates.map(state => {
                                return <option key={state.Key} value={state.Key}>{state.Name}</option>
                            }) : <option>no data found</option>
                        }
                    </Field>
                </Loading>
            </div>
        }
        {rules.state && selectedCountry === "CA" &&
            <div className="mb-3">
                <label className="caption flex text-desaturate-light" htmlFor="state">{t("labels.State")}</label>
                <Loading condition={isLoadingStaticData}>
                    <Field name="state" className="select" as="select">
                        {staticData?.CanadaStates ?
                            staticData.CanadaStates.map(state => {
                                return <option key={state.Key} value={state.Key}>{state.Name}</option>
                            }) : <option>no data found</option>
                        }
                    </Field>
                </Loading>
            </div>
        }
        {rules.address &&
            <InputField
                name="address"
                placeholder={t("labels.Address")}
                errors={errors}
                touched={touched}
                classes='mb-3' >
                {t("labels.Address")}
            </InputField>
        }
        <div className={[classes["input-group"], (rules.city && rules.zip ? classes['gap'] : ""),
        (rules.city || rules.zip ? "mb-12" : "")].join(' ')}
        >
            {rules.city &&
                <InputField
                    name="city"
                    placeholder={t("labels.City")}
                    errors={errors}
                    touched={touched}
                >
                    {t("labels.City")}
                </InputField>
            }
            {rules.zip &&
                <InputField
                    name="zip"
                    placeholder={t("labels.Zip")}
                    errors={errors}
                    touched={touched}
                >
                    {t("labels.Zip")}
                </InputField>
            }
        </div>
        <InputField
            name="bankName"
            placeholder={t("labels.BankName")}
            errors={errors}
            touched={touched}
            classes='mb-3'
        >
            {t("labels.BankName")}
        </InputField>
    </div>
}

export default DynamicInputs
