import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

import classes from './NewBankInputs.module.css'
import { ReactComponent as InfoIcon } from 'assets/images/svg/info.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/checked-icon.svg';
import { CSSTransition } from 'react-transition-group';
import Utils from 'services/utils';
import DynamicInputs from 'components/beneficiaries/newBeneficiaryForm/newBankInputs/dynamicInputs/DynamicInputs';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import queryConfig from 'services/query';
import InlineNotification from 'components/inlineNotification/InlineNotification';

const NewBankInputs = ({ values, touched, errors, setValues, setValidationSchema, setTouched, selectedPaymentType, setSelectedPaymentType, setIsError, setIsBtnLoading, isError }) => {
    const { t } = useTranslation();
    const regularPaymentRef = useRef();
    const priorityAba = useRef();
    const priorityBic = useRef();
    const [priorityRules, setPriorityRules] = useState([]);
    const [regularRules, setRegularRules] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");

    const { rulesLabel } = Utils;

    const { data: rules, isLoading: isLoadingRules } = useQuery(queryConfig.getRules({
        "currency": values.currency,
        "bank_account_country": values.bankCountry,
        "beneficiary_country": values.country
    }));

    useEffect(() => {
        if (!isLoadingRules) {
            setIsBtnLoading(false);
            let mappedRules = Utils.mapRules(rules.details);
            let { priority, regular } = Utils.getRulesByType(mappedRules, values.counterpartyType);
            setPriorityRules(priority);
            setRegularRules(regular);
            setSelectedCountry(values.country);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rules, isLoadingRules])

    const setPaymentTypeHandler = (evt) => {
        let value = evt.target.value;
        if (value === selectedPaymentType) {
            setSelectedPaymentType("");
            setValidationSchema({});
        } else {
            setSelectedPaymentType(value);
            setIsError(false);

            const { bankDetailsInitialFormValues, paymentTypesEnum } = Utils;
            setValues({ ...values, ...bankDetailsInitialFormValues }, false);
            setTouched({});

            let rule = findRule(value, paymentTypesEnum);

            let newValidationSchema = setNewValidationSchema(rule, bankDetailsInitialFormValues);
            setValidationSchema(newValidationSchema);
        }
    }

    const findRule = (value, paymentTypesEnum) => {
        const [payment_type, index] = value.split("-");
        let rule = {};
        if (payment_type === paymentTypesEnum[1]) {
            rule = priorityRules[index];
        } else {
            rule = regularRules[index];
        }
        console.log(rule);
        return rule;
    }

    const setNewValidationSchema = (rule, allDynamicFields) => {
        let validationObj = {};
        Object.entries(allDynamicFields).forEach(([key, value]) => {
            if (rule[key]) {
                let capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
                validationObj[key] = Yup.string().required(`${capitalizedKey} field is required`).matches(rule[key], `${capitalizedKey} field is invalid`);
            }
            //add other validation that coriunder needs, but currencyCloud does not provide
            if (key === "bankName") {
                let capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
                validationObj[key] = Yup.string().required(`${capitalizedKey} field is required`);
            }
        });
        return validationObj;
    }

    return <>
        {regularRules.length > 0 && <>
            <div className="flex mb-6">
                <h3 className="mr-3">{t("RegularPayment")}</h3>
                <InfoIcon className="info-icon" />
            </div>
            {regularRules.map((item, index) => {
                let counter = 0;
                return <div key={index}>
                    <div className="flex items-center mb-6">
                        <input type="checkbox"
                            id={`regular-${index}`}
                            value={`regular-${index}`}
                            checked={selectedPaymentType === `regular-${index}`}
                            className='checkbox-input'
                            onChange={setPaymentTypeHandler}
                        />
                        <label htmlFor={`regular-${index}`} className="checkbox mr-2">
                            <CheckedIcon />
                        </label>
                        <label htmlFor={`regular-${index}`} className="cursor-pointer">
                            {Object.keys(rulesLabel).map((ruleKey, ruleIndex) => {
                                if (item[ruleKey]) {
                                    counter++;
                                    let word = "and";
                                    if (counter === 1) {
                                        word = "";
                                    }
                                    return `${word} ${t(`checkboxLabel.${rulesLabel[ruleKey]}`)}  `
                                }
                                return '';
                            })
                            }
                        </label>
                    </div>

                    <CSSTransition
                        in={(selectedPaymentType === `regular-${index}`)}
                        timeout={100}
                        nodeRef={regularPaymentRef}
                        unmountOnExit
                        classNames={{
                            enter: classes.formEnter,
                            enterActive: classes.formEnterActive,
                            enterDone: classes.formEnterDone,
                            exit: classes.formExit,
                            exitActive: classes.formExitActive,
                            exitDone: classes.formExitDone,
                        }}
                    >
                        <DynamicInputs nodeRef={regularPaymentRef} touched={touched} errors={errors} rules={item} selectedCountry={selectedCountry} />
                    </CSSTransition>
                </div>
            })}
        </>
        }
        {priorityRules.length > 0 && <>
            <div className={["flex mb-6", (regularRules.length > 0 ? "mt-12" : "")].join(' ')}>
                <h3 className="mr-3">{t("PriorityPayment")}</h3>
                <InfoIcon className="info-icon" />
            </div>

            {priorityRules.map((item, index) => {
                let counter = 0;
                return <div key={index}>
                    <div className="flex items-center mb-6">
                        <input type="checkbox"
                            id={`priority-${index}`}
                            value={`priority-${index}`}
                            checked={selectedPaymentType === `priority-${index}`}
                            className='checkbox-input'
                            onChange={setPaymentTypeHandler}
                        />
                        <label htmlFor={`priority-${index}`} className="checkbox mr-2">
                            <CheckedIcon />
                        </label>
                        <label htmlFor={`priority-${index}`} className="cursor-pointer">
                            {Object.keys(rulesLabel).map((ruleKey, ruleIndex) => {
                                if (item[ruleKey]) {
                                    counter++;
                                    let word = "and";
                                    if (counter === 1) {
                                        word = "";
                                    }
                                    return `${word} ${t(`checkboxLabel.${rulesLabel[ruleKey]}`)}  `
                                }
                                return '';
                            })
                            }
                        </label>
                    </div>

                    <CSSTransition
                        in={(selectedPaymentType === `priority-${index}`)}
                        timeout={100}
                        nodeRef={index === 0 ? priorityAba : priorityBic}
                        unmountOnExit
                        classNames={{
                            enter: classes.formEnter,
                            enterActive: classes.formEnterActive,
                            enterDone: classes.formEnterDone,
                            exit: classes.formExit,
                            exitActive: classes.formExitActive,
                            exitDone: classes.formExitDone,
                        }}
                    >
                        <DynamicInputs nodeRef={index === 0 ? priorityAba : priorityBic} touched={touched} errors={errors} rules={item} selectedCountry={selectedCountry} />
                    </CSSTransition>
                </div>
            })}

            {isError &&
                <InlineNotification classes="mt-12" isGreyBg={true} isError={true} title="Error" text="Please provide bank account details." dismissible={false} />
            }
        </>
        }
    </>
}

export default NewBankInputs
