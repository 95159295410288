import React, { useEffect, useState, useContext } from 'react'
import WalletBox from 'components/walletList/walletBox/WalletBox';
import TransactionList from 'components/transactionList/TransactionList';

import { useQuery } from "react-query";
import queryConfig from 'services/query';
import { AppContext } from 'context/app';

const WalletDetails = (props) => {
    const [wallet, setWallet] = useState([]);
    const { incognito } = useContext(AppContext)

    const { data: walletList } = useQuery(queryConfig.getTotal());

    useEffect(() => {
        let selectedCurrencyCode = props.match.params.id;
        if (walletList?.length > 0) {
            setWallet(walletList.find(item => item.CurrencyIso === selectedCurrencyCode));
        }
    }, [walletList, props.match.params.id])

    return (
        <>
            <div className="flex mt-6 pt-1 mb-10">
                <WalletBox value={wallet} isIncognito={incognito} />
            </div>
            <TransactionList title="Transactions" currency={props.match.params.id} />
        </>
    )
}

export default WalletDetails
