import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { queryCache } from 'react-query'

export const AuthContext = React.createContext({
    isAuth: false,
    isMerchant: false,
    login: () => { },
    loginMerchant: () => { },
    logout: () => { },
    userEmail: "",
    setUserEmail: () => { },
    username: "",
    setUsername: () => { }
});

const AuthContextProvider = props => {
    const [isMerchant, setIsMerchant] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    //const [mutate] = useMutation(fetch("login"));

    const [userEmail, setUserEmail] = useState("");
    const [username, setUsername] = useState("");

    // const { refetch: fetchCustomerData } = useQuery(queryConfig.getCustomer({ refetchOnWindowFocus: false, enabled: false }));
    // const [saveLoginHistoryRecord] = useMutation(fetchExternal('addAccessLog'));

    useEffect(() => {
        //TODO rethink below
        let credentialsHeaderName = localStorage.getItem('credentialsHeaderName');
        let credentialsToken = localStorage.getItem('credentialsToken');
        let isMerchant = localStorage.getItem('isMerchant');
        if (credentialsHeaderName && credentialsToken) {
            setIsAuthenticated(true);
            if (isMerchant) {
                setIsMerchant(isMerchant);
            }
        }
    }, []);
    /*
    const createAccessLogRecord = async (isMerchant) => {
        let customerNumber;
        if (!isMerchant) {
            // const userData = await fetchCustomerData();
            // customerNumber = userData['CustomerNumber'];
        } else {
            let merchantNumber = localStorage.getItem('merchantNumber');
            customerNumber = merchantNumber;
        }
        // await saveLoginHistoryRecord({
        //     customerNumber, isMerchant,
        // }, {
        //     onSuccess: () => {
        //         queryCache.invalidateQueries('getLogByCustomerNumber')
        //     }
        // });
    }
*/
    const loginHandler = async (data) => {
        if (data.IsSuccess) {
            setIsMerchant(false);
            setIsAuthenticated(true);

            //TODO rethink below
            localStorage.setItem('credentialsHeaderName', data.CredentialsHeaderName);
            localStorage.setItem('credentialsToken', data.CredentialsToken);


            setIsMerchant(false);
            setIsAuthenticated(true);
        } else {
            toast.error("Credentials are not correct!");
        }
    };

    const loginMerchantHandler = async (data) => {
        // let data = await mutateMerchant({ email, password, userName, userRole });
        if (data?.IsSuccess) {
            localStorage.setItem('credentialsHeaderName', data.CredentialsHeaderName);
            localStorage.setItem('credentialsToken', data.CredentialsToken);
            localStorage.setItem('isMerchant', true);
            localStorage.setItem('merchantNumber', data.Number);


            setIsMerchant(true);
            setIsAuthenticated(true);
        } else {
            toast.error("Credentials are not correct!");
        }
    };

    const logoutHandler = (value) => {
        queryCache.invalidateQueries(true);
        localStorage.removeItem('credentialsHeaderName');
        localStorage.removeItem('credentialsToken');
        localStorage.removeItem('isMerchant');
        localStorage.removeItem('merchantNumber');
        setIsAuthenticated(value);
    }

    const setUserEmailHandler = (value) => {
        setUserEmail(value);
    }

    const setUsernameHandler = (value) => {
        setUsername(value);
    }

    return (
        <AuthContext.Provider
            value={{
                isAuth: isAuthenticated,
                isMerchant: isMerchant,
                login: loginHandler,
                loginMerchant: loginMerchantHandler,
                logout: logoutHandler,
                userEmail: userEmail,
                setUserEmail: setUserEmailHandler,
                username: username,
                setUsername: setUsernameHandler
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;