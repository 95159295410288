import React from 'react'
import WalletList from "components/walletList/WalletList"
import BeneficiariesList from 'components/beneficiaries/beneficiariesList/BeneficiariesList'

const Accounts = () => {
    return <>
        <WalletList />
        <BeneficiariesList /> 
    </>
}

export default Accounts
