
import axios from 'axios';
import Constants from './constants';
const APM_BASE_URL = Constants.api.apimUrl;

// LIMIT simultanously requests beeing made
const MAX_REQUESTS_COUNT = 100
const INTERVAL_MS = 15
let PENDING_REQUESTS = 0

// create new axios instance
const api = axios.create({})

/**
 * Axios Request Interceptor
 */
api.interceptors.request.use(function (config) {
    return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                PENDING_REQUESTS++
                clearInterval(interval)
                resolve(config)
            }
        }, INTERVAL_MS)
    })
});

/**
 * Axios Response Interceptor
 */
api.interceptors.response.use(function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
}, function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
});


function _generateHeader(credentialsHeaderName, credentialsToken) {
    let config = { headers: {} };
    config.headers['Content-Type'] = 'application/json';
    config.headers['Ocp-Apim-Subscription-Key'] = Constants.api.apimKey;
    config.headers['Ocp-Apim-Trace'] = 'true';
    if (credentialsHeaderName && credentialsToken) {
        config.headers["header-name"] = credentialsHeaderName;
        config.headers["header-token"] = credentialsToken;
    }
    return config;
}

function _generateUrl(endpoint) {
    return `${APM_BASE_URL}/${endpoint}`;
}

const _fetch = (uri) => {
    return async (payload) => {
        let credentialsHeaderName = localStorage.getItem('credentialsHeaderName');
        let credentialsToken = localStorage.getItem('credentialsToken');
        const { data } = await api.post(_generateUrl(uri), payload, _generateHeader(credentialsHeaderName, credentialsToken));
        return data.d || data;
    }
}
/*
function _generateHeaderForExternalRequest(credentialsHeaderName, credentialsToken) {
    let config = { headers: {} };
    config.headers['Content-Type'] = 'application/json';
    config.headers['Ocp-Apim-Subscription-Key'] = Constants.api.apimKey;
    config.headers['Ocp-Apim-Trace'] = 'true';
    return config;
}
*/
const fetchExternal = (uri) => {
    return async ({ customerNumber = null, isMerchant = false, initialPayload = {} }, config) => {
        const url = _generateUrl(uri);
        const coriAuth = {
            customerNumber,
            isMerchant
        }
        const payload = { payload: initialPayload, coriAuth: coriAuth }
        const { data } = await api.post(url, payload);
        return Promise.resolve(data);
    }
}

export default _fetch;
export { fetchExternal }