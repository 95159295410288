import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from "react-query";

import classes from './Account.module.css';
import { AuthContext } from 'context/auth-context';
import queryConfig from 'services/query';
import UserAvatar from 'react-user-avatar';

const Account = () => {
    const authContext = useContext(AuthContext);
    let merchantNumber = localStorage.getItem('merchantNumber');
    let queryAccountDetailsConfig = authContext.isMerchant ? queryConfig.getMerchant({ "merchantNumber": merchantNumber }, { refetchOnWindowFocus: false }) : queryConfig.getCustomer({ refetchOnWindowFocus: false });

    const { data: accountDetails, isLoading: isLoadingCustomer } = useQuery(queryAccountDetailsConfig);
    const { data: appIdentity, isLoading: isLoadingIdentity } = useQuery(queryConfig.getIdentityDetails({ refetchOnWindowFocus: false }));

    const [settingDetails, setSettingsDetails] = useState({ name: "TX" });
    useEffect(() => {
        let details = {};
        if (!isLoadingCustomer && accountDetails) {
            authContext.isMerchant ? (details.name = `${accountDetails?.Name}`) : (details.name = `${accountDetails?.FirstName} ${accountDetails?.LastName}`);
            authContext.isMerchant ? (details.profileImage = undefined) : (details.profileImage = accountDetails?.ProfileImage);
            details.avatar = "data:image/png;base64," + btoa(String.fromCharCode.apply(null, new Uint8Array(details.profileImage)));
            details.accountNumber = accountDetails.CustomerNumber;
            details.companyName = "company name";
            details.email = accountDetails.EmailAddress;
            details.phone = accountDetails.CellNumber;
        }
        if (!isLoadingIdentity) {
            //setCompany(appIdentity?.BrandName)
        }
        setSettingsDetails(details);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingCustomer, isLoadingIdentity, accountDetails, appIdentity])

    return (
        <>
            <div className={classes["beneficiary-container"]}>
                <div className="col-start-2 col-end-3 row-start-2 row-end-last flex flex-col items-center">
                    <UserAvatar size="40" className={classes['transaction-img']} name="DE" colors={['#E0BAB9', '#A3AFBF', '#656567']} />
                    <div>
                    </div>
                </div>
                <div className={classes["group"]}>
                    <p className={classes.label + " text-desaturate-light"}>ID</p>
                    <p className="">{settingDetails.accountNumber}</p>
                </div>
                <div className={classes["group"]}>
                    <p className={classes.label + " text-desaturate-light"}>Name</p>
                    <p className="">{settingDetails.name}</p>
                </div>
                <div className={classes["group"]}>
                    <p className={classes.label + " text-desaturate-light"}>Company</p>
                    <p className="">{settingDetails.companyName}</p>
                </div>
                <div className={classes["group"]}>
                    <p className={classes.label + " text-desaturate-light"}>Email</p>
                    <p className="">{settingDetails.email}</p>
                </div>
                <div className={classes["group"]}>
                    <p className={classes.label + " text-desaturate-light"}>Mobile phone</p>
                    <p className="">{settingDetails.phone}</p>
                </div>
            </div>
        </>
    )
}

export default Account
