import React, { useState, useEffect, useContext } from 'react';

import Utils from 'services/utils';
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryConfig from 'services/query';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import fetch from 'services/fetch';
import { useTranslation } from 'react-i18next';

import classes from './SendForm.module.css';

import { ReactComponent as PaysetLogo } from 'assets/images/svg/payset-logo.svg';

import { SendContext } from 'context/send';
import Button from 'components/button/Button';
import Pincode from 'components/pincode/Pincode';

import CustomInput from 'components/customInput/CustomInput';
import Loading from 'components/loading/Loading';
import UploadButton from 'components/uploadButton/UploadButton';
import InputField from 'components/inputField/InputField';
import NotificationContent from 'components/notifications/NotificationContent';

const SendForm = ({ isExternal, newFriendId }) => {
    const { t } = useTranslation();
    let history = useHistory();

    const [maxAmount, setMaxAmount] = useState('');
    //const [isInternalTab, setIsInternalTab] = useState(false);

    const [showPin, setShowPin] = useState(false);
    const [pinErrorMsg, setPinErrorMsg] = useState("");
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [formValues, setFormValues] = useState({});

    const { selectedFriend, withAddBeneficiary } = useContext(SendContext);
    const { data: walletList, isLoading } = useQuery(queryConfig.getTotal());

    const { transactionInitialFormValues } = Utils;

    const [initialFormValues, setInitialFormValues] = useState({
        ...transactionInitialFormValues
    })

    const queryClient = useQueryClient();
    const {mutateAsync: mutateSend} = useMutation(fetch("transferAmount"), {
        onSuccess: () => {
            queryClient.invalidateQueries('cbo.balance.getTotal')
            queryClient.invalidateQueries('cbo.balance.getRows')
        }
    });

    //const {mutateAsync: mutateAddbeneficiary} = useMutation(fetch("storeBeneficiaryBankAccount"));

    const {mutateAsync: mutateSendExternal} = useMutation(fetch("requestPayment"), {
        onSuccess: () => {
            queryClient.invalidateQueries('cbo.balance.getTotal')
            queryClient.invalidateQueries('cbo.balance.getRows')
        }
    });


    useEffect(() => {
        if (walletList && walletList.length > 0) {
            setMaxAmount(walletList[0].Current);
            setInitialFormValues(prevState => ({
                ...prevState,
                currency: walletList[0].CurrencyIso
            }))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletList])

    const handleCurrencyChange = (event, setFieldValue) => {
        setFieldValue('currency', event.target.value);
        walletList.forEach((value) => {
            if (value.CurrencyIso === event.target.value) {
                setMaxAmount(value.Current);
            }
        });
    };

    let validationSchema = {
        amount: Yup.number()
            .max(maxAmount, `The maximum allowed amount is ${maxAmount}`)
            .required('Amount field is required')
    }

    const onPinSucess = () => {
        let data = {
            selectedFriend: selectedFriend,
            values: {
                currency: formValues.currency,
                amount: formValues.amount
            }
        };
        showNotification(data, "success")
    }

    const onPinFail = (message) => {
        setPinErrorMsg(message);
    }

    const onCallFail = (message) => {
        showNotification(message, "error");
    }

    const onPinEntered = async (pinCode) => {
        const data = await mutateSend({
            destAccountId: selectedFriend?.friendId || newFriendId,
            amount: +formValues.amount,
            currencyIso: formValues.currency,
            pinCode: pinCode,
            text: formValues.description,
            balanceTransferType: 1
        });
        return data;
    }

    const closeModalHandler = (event) => {
        event.preventDefault();
        history.goBack();
    }
    const showNotification = (data, type) => {
        history.goBack();
        if (type === "success") {
            toast(<NotificationContent type="send" data={data} />, {
                className: 'white-toast default-toast'
            })
        } else {
            toast(<NotificationContent type="error" description={data} />, {
                className: 'white-toast default-toast'
            })
        }
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object(validationSchema)}
            enableReinitialize={true}
            onSubmit={async (values) => {
                setIsBtnLoading(true);
                if (Object.keys(selectedFriend).length === 0 && !newFriendId) {
                    alert("Select a friend first");
                    setIsBtnLoading(false);
                    return;
                }

                setTimeout(async () => {
                    if (selectedFriend.isInternal || newFriendId) {
                        setIsBtnLoading(false);
                        setShowPin(true);
                        setFormValues(values);
                    } else {
                        const mutateResponse = await mutateSendExternal({
                            data: {
                                Amount: +values.amount,
                                Comment: values.description,
                                BeneficiaryBankAccountId: selectedFriend.friendId,
                                //SourceBalanceRequestId: 3,
                                CurrencyIsoCode: values.currency,
                                SourceCurrency: values.currency,
                                //TargetCurrency: values.currency
                            },
                            isAFunctionCall: false
                        });
                        setIsBtnLoading(false);

                        if (mutateResponse?.IsSuccess) {
                            let data = {
                                selectedFriend: selectedFriend,
                                values: {
                                    currency: values.currency,
                                    amount: +values.amount
                                }
                            };
                            showNotification(data, "success")
                        } else {
                            showNotification(mutateResponse?.Message || "Something went wrong", "error");
                        }
                    }
                }, 500);
            }}
        >
            {({
                values,
                touched,
                handleSubmit,
                submitForm,
                errors,
                setFieldValue
            }) => {
                return (
                    <Form className={classes['form-container']} onSubmit={handleSubmit}>
                        {!isExternal && withAddBeneficiary && <>
                            <h3>{t("TransactionDetails")}</h3>
                        </>
                        }
                        <div>
                            <label className="caption inline-flex opacity-50" htmlFor="currency">{t("labels.from")}</label>
                            <div className="flex justify-between pt-1 pb-2 border-b border-platinum">
                                <p>{`${values.currency}`} {t("labels.account")}</p>
                                <Loading condition={isLoading} >
                                    <Field
                                        name="currency"
                                        as="select"
                                        className="p"
                                        onChange={(e) => { handleCurrencyChange(e, setFieldValue) }}
                                    >
                                        {walletList?.length > 0 ?
                                            walletList.map((value, index) => {
                                                return <option key={value.CurrencyIso} value={value.CurrencyIso}>{Utils.currencyCodes[value.CurrencyIso]} {value.Current}</option>
                                            })
                                            : <option>{t("NoData")}</option>
                                        }
                                    </Field>
                                </Loading>
                            </div>
                        </div>
                        <InputField
                            name="description"
                            placeholder="Purpose of the payment"
                            classes='mt-3 p'
                        >
                            {t("labels.description")}
                        </InputField>
                        { isExternal &&
                            <div className="mt-10">
                                <label className="caption flex mb-4" htmlFor="upload">{t("labels.uploadDoc")}</label>
                                <UploadButton name="file" />
                            </div>
                        }
                        <div className="mt-10">
                            <label className="caption flex mb-2 opacity-50" htmlFor="amount">{t("labels.amount")}</label>
                            <div className="amount-group flex">
                                <p className={"huge-font mono text-metal " + classes["amount-sign"]}>{Utils.currencyCodes[`${values.currency}`]}</p>
                                <Field
                                    id="amount"
                                    className={"huge-font mono " + classes.amount}
                                    name="amount"
                                    placeholder="0"
                                    options={{
                                        numeral: true,
                                        numeralPositiveOnly: true
                                    }}
                                    component={CustomInput} />
                            </div>
                            {errors.amount && touched.amount && <div className="text-reor mb-8">{errors.amount}</div>}
                        </div>
                        <div className="mt-10">
                            {isExternal ?
                                <div className="with-fee">
                                    {t("withFee")}
                                </div>
                                : <div className="no-fee flex items-center">
                                    <PaysetLogo alt="payset-logo" className={classes["payset-logo"]} />
                                    <p className="text ml-3">{t("noFee")}</p>
                                </div>
                            }
                        </div>

                        {showPin ?
                            <Pincode
                                setShowPincode={setShowPin}
                                errorMsg={pinErrorMsg}
                                setErrorMsg={setPinErrorMsg}
                                onPinEntered={onPinEntered}
                                onPinSucess={onPinSucess}
                                onPinFail={onPinFail}
                                onCallFail={onCallFail}
                                className="mt-12"
                            />
                            : <div className="flex items-center mt-12">
                                <Button
                                    classes="py-3 px-4 mr-3"
                                    btnTheme="primary"
                                    size="normal"
                                    isLoading={isBtnLoading}
                                    type="submit"
                                    case="uppercase"
                                >
                                    {t("buttons.send")}
                                </Button>
                                <Button
                                    classes="py-3 mr-3"
                                    btnTheme="ghost"
                                    size="normal"
                                    clicked={closeModalHandler}
                                    case="uppercase"
                                >
                                    {t("buttons.cancel")}
                                </Button>
                            </div>
                        }
                    </Form>
                )
            }}
        </Formik>
    );
}

export default SendForm
