import React from 'react'
import ReactExport from "react-export-excel";
import Utils from 'services/utils';

const Export = (props) => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
        <ExcelFile element={<button>Download</button>}>
            <ExcelSheet data={props.data} name="Transactions">
                <ExcelColumn label="ID" value="ID" />
                <ExcelColumn label="Date" value={(col) => Utils.getDate(col.InsertDate)} />
                <ExcelColumn label="Type" value="SourceType" />
                <ExcelColumn label="Status" value={(col) => col.IsPending ? "Pending" : "Executed"} />
                <ExcelColumn label="CurrencyISO" value="CurrencyIso" />
                <ExcelColumn label="Amount" value="Amount" />
                <ExcelColumn label="From" value="SourceAccountName" />
                <ExcelColumn label="To" value="TargetAccountName" />
            </ExcelSheet>
        </ExcelFile>
    );
}

export default Export
