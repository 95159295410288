import React, { useRef, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import SendContextProvider from 'context/send';

import classes from './ModalLayout.module.css'
import { ReactComponent as CloseIcon } from 'assets/images/svg/close-icon.svg';
import Button from 'components/button/Button';

const ModalLayout = (props) => {
    let history = useHistory();
    const node = useRef();

    const handleKeyPress = (e) => {
        //TODO rethink
        if (e.key === "Escape" && !document.getElementById('beneficiaryPicker') && !document.getElementById('pinCode')) {
            closeModalHandler();
        }
    }

    const closeModalHandler = () => {
        history.goBack();
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.modal} ref={node}>
            <div className={classes.wrapper}>
                <div className='bg-money-normal'></div>
                <div className='bg-platinum'></div>
            </div>
            <div className={classes.backdrop}>
                <div className={classes.modalContent + ' bg-white relative mx-8 my-8'}>
                    <Button
                        classes={classes.close + ' focus:outline-none'}
                        clicked={closeModalHandler} >
                            <CloseIcon alt="close" width="32" height="32"/>
                    </Button>
                    <SendContextProvider>
                        <main className={classes.content + " pt-16 pb-16 mx-auto"}>
                            {props.children}
                        </main>
                    </SendContextProvider>
                </div>
            </div>
        </div>
    )
}

export default ModalLayout
