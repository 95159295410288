import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import classes from './Send.module.css';

import SendForm from 'pages/send/sendForm/SendForm'
import BeneficiaryCard from 'components/beneficiaries/beneficiaryCard/BeneficiaryCard';
import BeneficiaryPicker from 'components/beneficiaries/beneficiaryPicker/BeneficiaryPicker';
import CounterpartyTabs from 'components/counterpartyTabs/CounterpartyTabs';
import NewFriendForm from 'components/newFriendForm/NewFriendForm';
import NewBeneficiaryForm from 'components/beneficiaries/newBeneficiaryForm/NewBeneficiaryForm';
import StepsTabs from 'components/beneficiaries/stepsTabs/StepsTabs';

import { AppContext } from 'context/app';
import { SendContext } from 'context/send';
import queryConfig from 'services/query';
import { useQuery } from "react-query";
import { AuthContext } from 'context/auth-context';
import Utils from 'services/utils';
import SortingService from 'services/sortingService';

var isLoadingLists = true;
var initialSortedFriendList = [];

const Send = () => {
    const [isGreenBtn, setIsGreenBtn] = useState(true);
    const [sortedFriendList, setSortedFriendList] = useState([]);
    const [isInternalTab, setIsInternalTab] = useState(false);
    const [newFriendId, setNewFriendId] = useState(null);
    const [addedBeneficiarySuccessfully, setAddedBeneficiarySuccessfully] = useState('');
    const [disabledCard, setDisabledCard] = useState(false);

    const authContext = useContext(AuthContext);
    const { isPickerOpen, setIsPickerOpen } = useContext(AppContext);
    const { selectedFriend, setSelectedFriend, withAddBeneficiary, setWithAddBeneficiary, isExternalSelected } = useContext(SendContext);
    const { data: beneficiariesList, isLoading: isLoadingBeneficiary } = useQuery(queryConfig.getBeneficiaries());

    let queryFriendsConfig = queryConfig.getFriends();
    queryFriendsConfig.enabled = !authContext.isMerchant;
    const { data: friendsList, isLoading: isLoadingFriendsList } = useQuery(queryFriendsConfig);

    const [cardData, setCardData] = useState({
        fullName: "Select / add a counterparty",
        isInternal: false,
        pictureType: "default",
        profileImage: null
    });

    const { id } = useParams();

    useEffect(() => {
        if (Object.keys(selectedFriend).length > 0) {
            // after selecting a friend, close the picker, 
            // update card data and hide add beneficiary form
            setIsPickerOpen(false);
            //TODO set card DaTA
            setCardData({
                fullName: selectedFriend.fullName,
                profileImage: selectedFriend.profileImage,
                isInternal: selectedFriend.isInternal,
                pictureType: "profile"
            });
            setIsGreenBtn(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFriend]);

    useEffect(() => {
        if (withAddBeneficiary) {
            // if add new beneficiary is selected, close the picker, 
            // update card data and reset selected friend context
            setIsPickerOpen(false);
            setCardData({
                fullName: "New counterparty",
                isInternal: false,
                pictureType: "face",
                profileImage: null
            });
            setSelectedFriend({});
            setIsGreenBtn(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withAddBeneficiary]);

    useEffect(() => {
        if (!isLoadingBeneficiary && !isLoadingFriendsList) {
            if (beneficiariesList?.length === 0 && friendsList?.Items.length === 0) {
                setCardData({
                    fullName: "Add a counterparty",
                    isInternal: false,
                    pictureType: "default",
                    profileImage: null
                });
            }

            let allFriends = [];
            if (!authContext.isMerchant) {
                if (!isLoadingFriendsList && friendsList && !isLoadingBeneficiary && beneficiariesList) {
                    allFriends = Utils.getAllFriends(friendsList, beneficiariesList);
                    allFriends.sort((a, b) => a.fullName.localeCompare(b.fullName));
                    isLoadingLists = false;
                }
            } else {
                if (!isLoadingBeneficiary && beneficiariesList) {
                    allFriends = Utils.getAllFriends({ Items: [] }, beneficiariesList);
                    allFriends.sort((a, b) => a.fullName.localeCompare(b.fullName));
                    isLoadingLists = false;
                }
            }
            allFriends = SortingService.sortAllFriendsByFirstLetter(allFriends);
            initialSortedFriendList = allFriends;
            setSortedFriendList(allFriends);

            if (addedBeneficiarySuccessfully) {
                let newMember = beneficiariesList.find(item => item.BankAccount_id === +addedBeneficiarySuccessfully);
                setSelectedFriend({
                    friendId: newMember.BankAccount_id,
                    fullName: newMember.NickName,
                    isInternal: false,
                    pictureType: "profile",
                    profileImage: newMember.LogoAsBytes
                });
                setDisabledCard(true);
            } else if (id) {
                allFriends.forEach(item => {
                    let foundItem = item.list.find(friend => +friend.friendId === +id);
                    if (foundItem) {
                        setSelectedFriend({
                            friendId: foundItem.friendId,
                            fullName: foundItem.fullName,
                            isInternal: foundItem.isInternal,
                            pictureType: "profile",
                            profileImage: foundItem.profileImage
                        })
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingBeneficiary, isLoadingFriendsList, beneficiariesList, friendsList])

    const toggleBeneficiaryList = () => {
        if (!isPickerOpen) {
            // reset context when open the picker so that
            // we can select again the same option as before
            setSelectedFriend({});
            setWithAddBeneficiary(false);
            setNewFriendId(null);
        }
        setIsPickerOpen(!isPickerOpen);
    }

    const clickedOutsideHandler = () => {
        let name = "Select or add a counterparty";
        if (beneficiariesList?.length === 0 && friendsList?.Items.length === 0) {
            name = "Add a counterparty";
        }
        setCardData({
            fullName: name,
            isInternal: false,
            pictureType: "default",
            profileImage: null
        });
        setIsGreenBtn(true);
        setSelectedFriend({})
        setIsPickerOpen(false);
    }

    return (
        <div className={classes["send-container"]}>
            <h1 className="mb-8">Send funds</h1>
            <p className="caption mb-2">To</p>
            <div className={classes["send-layout"]}>
                <BeneficiaryCard disabled={disabledCard} classes={withAddBeneficiary ? "" : "mb-6"} isAddButton={isGreenBtn} value={cardData} click={toggleBeneficiaryList} />

                {withAddBeneficiary ? <>
                    <CounterpartyTabs
                        isInternalTab={isInternalTab}
                        setIsInternalTab={setIsInternalTab}
                        setNewFriendId={setNewFriendId}
                    />
                    {addedBeneficiarySuccessfully &&
                        <StepsTabs
                            addedBeneficiarySuccessfully={addedBeneficiarySuccessfully}
                            displaySendTab={true}
                        />
                    }
                    {!addedBeneficiarySuccessfully ?
                        isInternalTab ? <NewFriendForm setNewFriendId={setNewFriendId} />
                            : <NewBeneficiaryForm displaySendTab={true} setAddedBeneficiarySuccessfully={setAddedBeneficiarySuccessfully} />
                        : null
                    }
                </> : null
                }

                {!withAddBeneficiary || (withAddBeneficiary && isInternalTab) || addedBeneficiarySuccessfully ?
                    <SendForm isExternal={isExternalSelected} newFriendId={newFriendId}/>
                    : null
                }

                {isPickerOpen && <BeneficiaryPicker
                    sortedFriendList={sortedFriendList}
                    isLoadingLists={isLoadingLists}
                    initialSortedFriendList={initialSortedFriendList}
                    setSortedFriendList={setSortedFriendList}
                    className={classes['picker']}
                    clickedOutside={clickedOutsideHandler}
                />
                }
            </div>
        </div>
    )
}

export default Send
