import React from 'react';
import Cleave from 'cleave.js/react';

const CustomInput = ({
    field: {onChange, ...fieldProps}, // { name, value, onBlur }
    form: { touched, errors },
    ...props
}) => (
    <Cleave placeholder={props.placeholder}
        options={props.options}
        onChange={event => {
            const tempEvent = event;
            tempEvent.target.value = event.target.rawValue;
            onChange(tempEvent);
        }}
        {...fieldProps}
        {...props} />
);


export default CustomInput;
