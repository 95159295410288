const transactionType = {
    "MoneyIn": 1,
    "MoneyOut": 2,
    "Exchange": 3,
    "Confirmed": 4,
    "Pending": 5,
    "Rejected": 6
}

const transactionTypeColor = {
    "MoneyIn": 1,
    "MoneyOut": 2,
    "Exchange": 3,
    "Confirmed": "text-money-normal",
    "Pending": "",
    "Rejected": "text-reor"
}

const transactionFilter = {
    transactions: {
        "Money in": { checked: false, id: transactionType.MoneyIn, flag: "type" },
        "Money out": { checked: false, id: transactionType.MoneyOut, flag: "type" },
        "Exchange": { checked: false, id: transactionType.Exchange, flag: "type" }
    },
    status: {
        "Success": { checked: false, id: transactionType.Confirmed, flag: "status" },
        "Pending": { checked: false, id: transactionType.Pending, flag: "status" },
        "Rejected": { checked: false, id: transactionType.Rejected, flag: "status" },
    }
}

const requestFilter = {
    transaction: {
        "I requested": {checked: false, id: transactionType.MoneyIn, flag: "type"},
        "They requested": {checked: false, id: transactionType.MoneyOut, flag: "type"}
    },
    status: {
        "Confirmed": { checked: false, id: transactionType.Confirmed, flag: "status" },
        "Pending": { checked: false, id: transactionType.Pending, flag: "status" },
        "Rejected": { checked: false, id: transactionType.Rejected, flag: "status" },
    }
}

const requestType = {
    "Friend": 1,
    "Money": 2
}

const requestMoneyType = {
    "RequestFrom": 1,
    "RequestTo": 2
}

let Enums = {
    transactionType: transactionType,
    transactionTypeColor: transactionTypeColor,
    requestType: requestType,
    requestMoneyType: requestMoneyType,
    transactionFilter: transactionFilter,
    requestFilter: requestFilter
}
export default Enums;