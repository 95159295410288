import React, { useState, useEffect, useContext } from 'react'
import BeneficiaryCard from 'components/beneficiaries/beneficiaryCard/BeneficiaryCard';
import { useQuery } from "react-query";
import queryConfig from 'services/query';
import { useHistory } from "react-router-dom";
import { AuthContext } from 'context/auth-context';
import { useTranslation } from 'react-i18next';

import classes from './BeneficiariesList.module.css'
import Loading from 'components/loading/Loading';
import PlusIcon from 'components/icons/PlusIcon';
import Utils from 'services/utils';
import Button from 'components/button/Button';

var isLoadingLists = true;
const BeneficiariesList = () => {
    const authContext = useContext(AuthContext);
    let history = useHistory();
    const { t } = useTranslation();
    const [friends, setFriends] = useState([]);
    let queryFriendsConfig = queryConfig.getFriends();
    queryFriendsConfig.enabled = !authContext.isMerchant;
    const { data: friendsList, isLoading: isLoadingFriendsList } = useQuery(queryFriendsConfig);
    const { data: beneficiariesList, isLoading: isLoadingBeneficiariesList } = useQuery(queryConfig.getBeneficiaries());

    useEffect(() => {
        let allFriends = [];
        if (!authContext.isMerchant) {
            if (!isLoadingFriendsList && friendsList && !isLoadingBeneficiariesList && beneficiariesList) {
                allFriends = Utils.getAllFriends(friendsList, beneficiariesList);
                allFriends.sort((a, b) => a.fullName.localeCompare(b.fullName));
                allFriends.splice(Utils.maxFriendsListNumber);
                isLoadingLists = false;
            }
        } else {
            if (!isLoadingBeneficiariesList && beneficiariesList) {
                allFriends = Utils.getAllFriends({ Items: [] }, beneficiariesList);
                allFriends.sort((a, b) => a.fullName.localeCompare(b.fullName));
                allFriends.splice(Utils.maxFriendsListNumber);
                isLoadingLists = false;
            }
        }
        setFriends(allFriends);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingFriendsList, friendsList, beneficiariesList, isLoadingBeneficiariesList])

    const goToDetails = (friendInfo) => {
        history.push({ pathname: `/send/${friendInfo.friendId}` });
    }

    return (
        <div className="friends">
            <div className="friends-header flex justify-between items-center mb-8">
                <h3>{t('Send money to')}:</h3>
            </div>
            <Loading condition={isLoadingLists}>
                <div className={classes['friends-list']}>
                    {
                        friends?.length > 0 ?
                            friends.map((value, index) => {
                                return <BeneficiaryCard value={value} key={index} click={() => { return goToDetails(value); }} />
                            }) :
                            <div>no data found</div>
                    }
                    <Button
                        classes={classes.button + " flex items-center justify-center"}
                        clicked={() => history.push('/add-beneficiary')}
                        btnTheme="primary"
                        size="normal">
                        <PlusIcon size={24} />
                        <p className="caption ml-3">{t('Add a counterparty')}</p>
                    </Button>
                </div>
            </Loading>
        </div>
    )
}

export default BeneficiariesList
