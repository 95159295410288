let APIConfig = {};
const domain = process.env.REACT_APP_DOMAIN;

APIConfig = {
    apimUrl: `${domain}/api`,
    apimKey: "93470014529847529ed6ecb7cb340def"
};

const Constants = {
    userRole: {
        individual: 15,
        merchant: 20
    },
    api: APIConfig
};
export default Constants;