import BeneficiaryCard from 'components/beneficiaries/beneficiaryCard/BeneficiaryCard'
import React from 'react'

import { ReactComponent as CheckedIcon } from 'assets/images/svg/checked-icon.svg';

import classes from './Sent.module.css';
import Button from 'components/button/Button';
import { useTranslation } from 'react-i18next';

const Sent = (props) => {
    const { t } = useTranslation();
    const { transaction } = props;
    const cardData = {
        fullName: transaction.avatarName,
        profileImage: transaction.profileImage,
        isInternal: transaction.isInternal,
        pictureType: "profile"
    }
    return <>
        <h2 className="semibold mb-12">{t("labels.sentFunds")}</h2>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light self-start">{t("labels.sentTo")}:</p>
            <BeneficiaryCard value={cardData} />
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.amount")}:</p>
            <p className="medium-font normal-weight mono">{transaction.amount}</p>
        </div>

        { transaction.pending === false &&
            <div className={[classes['group'], 'mb-5'].join(' ')}>
                <p className="small text-desaturate-light">{t("labels.status")}:</p>
                <div className="flex">
                    <p className="caption mr-2">{t("labels.complete")}</p>
                    <div className={classes['success-icon']}><CheckedIcon /></div>
                </div>
            </div>
        }

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.date")}:</p>
            <p className="small">{transaction.displayDate}, {transaction.displayHour}</p>
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.fromAccount")}:</p>
            <p className="small">{transaction.currency} {t("labels.account")}</p>
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.description")}:</p>
            <p className="small">{transaction.comment}</p>
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.systemFee")}:</p>
            <p className="small">$0.56</p>
        </div>

        <div className={[classes['group'], 'mb-10'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.transactionFee")}:</p>
            <p className="small">$0.00</p>
        </div>

        <div className="flex justify-end">
            <Button
                clicked={props.closeModal}
                btnTheme="primary"
                size="medium"
                case="uppercase" >
                {t("buttons.done")}
            </Button>
        </div>
    </>
}

export default Sent
