import React from "react";
import Modal from 'components/modal/Modal';
import UpdatePasswordForm from "../updatePasswordForm/UpdatePasswordForm";

const UpdatePasswordModal = (props) => {
  return <Modal closeModal={props.closeModal}>
    <h1 className="mb-8">Password Update</h1>
    <UpdatePasswordForm {...props} />
  </Modal>
};

export default UpdatePasswordModal;