import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const NavigationItem = (props) => {
    let activeClass = '';
    if(props.type !== '' && props.type === 'sidebar') {
        activeClass = 'activeSidebar';
    }
    if(props.type !== '' && props.type === 'tab') {
        activeClass = 'activeTab';
    }

    return (
        <NavLink
            className={props.classes}
            activeClassName={classes[activeClass]}
            to={props.link}
            exact={props.exact}
            onClick={props.onClick}>
                {props.children}
        </NavLink>
    )
};

export default NavigationItem;