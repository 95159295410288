import React, { useEffect, useRef, useState } from 'react';

import classes from "./Filter.module.css";
import { ReactComponent as FilterIcon } from 'assets/images/svg/filter-icon.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/checked-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';

const Filter = ({ checkedFilters, setCheckedFilters, propsClasses, filterOptionsState }) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterOptions, setfilterOptions] = useState(filterOptionsState);
    const filterNode = useRef();
    const iconNode = useRef();

    useEffect(() => {
        if(checkedFilters.length > 0) {
            //to set a filter checked = true at init we should update the 
            //filterOptions object here and not in the Enums file
            checkedFilters.forEach(item => {
                let filterKey = "";
                Object.entries(filterOptions[item.flag]).forEach(([key, element]) => {
                    if(element.id === item.id) {
                        filterKey = key;
                    }
                });
                let newState = {
                    ...filterOptions,
                    [item.flag]: {
                        ...filterOptions[item.flag],
                        [filterKey]: item
                    }
                }
                setfilterOptions(newState);
            });
        }
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (evt) => {
        if (filterNode.current.contains(evt.target) || iconNode.current.contains(evt.target)) {
            //inside click
            return;
        }

        setIsFilterOpen(false);
    }

    const toggleFilterHandler = () => {
        setIsFilterOpen(prevState => !prevState);
    }

    const toggleCheckboxHandler = (value, filterId, flag, filterKey, categoryKey) => {

        let newValue = !value;

        let tempFilters = {
            ...filterOptions,
            [categoryKey]: {
                ...filterOptions[categoryKey],
                [filterKey]: {
                    ...filterOptions[categoryKey][filterKey],
                    checked: newValue
                }
            }
        }
        let filter = {
            checked: newValue,
            id: filterId,
            flag: flag
        }
        if (newValue) {
            setCheckedFilters(prevState => [...prevState, filter]);
        } else {
            let filteredChecks = checkedFilters.filter(item => item.id !== filterId);
            setCheckedFilters(filteredChecks);
        }

        setfilterOptions(tempFilters);
    }

    const removeFiltersHandler = () => {
        setCheckedFilters([]);
        setfilterOptions(filterOptionsState);
    }

    return (
        <div className={["relative flex items-center", (propsClasses ? propsClasses : "")].join(' ')}>
            {checkedFilters.length > 0 ?
                <div className={["mr-2", classes["filter-counter"]].join(' ')}>
                    <p className="caption mr-1">{checkedFilters.length}</p>
                    <CloseIcon width="16px" height="16px" onClick={removeFiltersHandler} />
                </div>
                : null
            }
            <FilterIcon
                ref={iconNode}
                className={["cursor-pointer", (isFilterOpen ? "bg-white" : "")].join(' ')}
                onClick={toggleFilterHandler}
            />
            <div ref={filterNode} className={[classes["filter-container"], (isFilterOpen ? classes['filterOpen'] : "")].join(' ')}>

                {Object.entries(filterOptions).map(([categoryKey, category]) => {
                    return <div key={categoryKey}>
                        <p className="caption text-desaturate-light mb-4 capitalize">{categoryKey}</p>
                        {Object.entries(category).map(([filterKey, filter]) => {
                            return <div className="flex items-center mb-4 cursor-pointer" key={filterKey} onClick={() => toggleCheckboxHandler(filter.checked, filter.id, filter.flag, filterKey, categoryKey)}>
                                <div className={["mr-2 checkbox", (filter.checked ? "checked" : "")].join(' ')}>
                                    <CheckedIcon />
                                </div>
                                <p className={["small", classes["filter-name"]].join(' ')}>{filterKey}</p>
                            </div>
                        })}
                    </div>
                })}
            </div>
        </div>
    )
}

export default Filter
