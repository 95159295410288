import React from 'react'

import Button from 'components/button/Button';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/checked-icon.svg';

import classes from './Exchanged.module.css';
import { useTranslation } from 'react-i18next';

const Exchanged = (props) => {
    const { t } = useTranslation();
    const { transaction } = props;

    return <>
        <h2 className="semibold mb-12">{t("exchange")}</h2>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.spent")}:</p>
            <p className="medium-font normal-weight mono">{transaction.currencyFromAmount}</p>
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.received")}:</p>
            <p className="medium-font normal-weight mono">{transaction.amount}</p>
        </div>

        { transaction.pending === false &&
            <div className={[classes['group'], 'mb-5'].join(' ')}>
                <p className="small text-desaturate-light">{t("labels.status")}:</p>
                <div className="flex">
                    <p className="caption mr-2">{t("labels.complete")}</p>
                    <div className={classes['success-icon']}><CheckedIcon /></div>
                </div>
            </div>
        }

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.date")}:</p>
            <p className="small">{transaction.displayDate}, {transaction.displayHour}</p>
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.exchangeRate")}:</p>
            <p className="small"></p>
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.systemFee")}:</p>
            <p className="small">$0.56</p>
        </div>

        <div className="flex justify-end">
            <Button
                clicked={props.closeModal}
                btnTheme="primary"
                size="medium"
                case="uppercase" >
                {t("buttons.done")}
            </Button>
        </div>
    </>
}

export default Exchanged
