import React, { useState } from 'react';

export const SendContext = React.createContext({
    selectedFriend: {},
    setSelectedFriend: () => { },
    withAddBeneficiary: false,
    setWithAddBeneficiary: () => { },
    beneficiaryAdded: false,
    isExternalSelected: false,
    setIsExternalSelected: () => {}
});

const SendContextProvider = (props) => {
    const [selectedFriend, setSelectedFriend] = useState({});
    const [withAddBeneficiary, setWithAddBeneficiary] = useState(false);
    const [isExternalSelected, setIsExternalSelected] = useState(false);

    const setSelectedFriendHandler = (friendInfo) => {
        setSelectedFriend(friendInfo);
    }

    const setWithAddBeneficiaryHandler = (value) => {
        setWithAddBeneficiary(value);
    }

    const setIsExternalSelectedHandler = (value) => {
        setIsExternalSelected(value);
    }
    return (
        <SendContext.Provider value={
            {
                selectedFriend: selectedFriend,
                setSelectedFriend: setSelectedFriendHandler,
                withAddBeneficiary: withAddBeneficiary,
                setWithAddBeneficiary: setWithAddBeneficiaryHandler,
                isExternalSelected: isExternalSelected,
                setIsExternalSelected: setIsExternalSelectedHandler
            }}>
            {props.children}
        </SendContext.Provider>
    )
}

export default SendContextProvider;