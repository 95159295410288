import React, { useState } from "react";

import { ReactComponent as PaysetLogo } from 'assets/images/svg/pays-logo.svg';
import { ReactComponent as GlobeLogo } from 'assets/images/svg/globe.svg';

import classes from "./LandingLayout.module.css";

var pictures = ["coin-world", "man-coin"];
const LandingLayout = (props) => {
    const [selectedPicture] = useState(pictures[Math.floor(Math.random() * pictures.length)])

    return (
        <div className={classes["login-container"]}>
            <div className={[classes["sidebar"], "bg-money-normal p-8"].join(" ")}>
                <PaysetLogo className="mb-12" />
                <GlobeLogo />
                <img className="self-center" alt="alt-text" src={`./images/${selectedPicture}.png`} />
                <div className="grid mb-16">
                    <p className="flex text-white mb-4">
                        <span className={classes["bullet"]}></span>
                        Multi currency wallets for your business needs.
                    </p>
                    <p className="flex text-white">
                        <span className={classes["bullet"]}></span>
                        Dedicated IBANs. Send, receive, exchange, move <br />
                        money with no borders
                    </p>
                </div>
                <p className="caption text-metal">
                    Pay Set Limited, an authorized electronic money institution FCA <br />
                    reference number 900920,incorporated under the laws of England and <br />
                    Wales, with registered office at Kimberley House, 31 Burnt Oak Broadway,<br />
                    Edgware, United Kingdom, HA8 5LD.
                </p>
            </div>
            <div className={classes['main-content']}>
                {props.children}
            </div>
        </div>
    )
}

export default LandingLayout
