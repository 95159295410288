import './App.css';
import './assets/css/typography.css'

import { useAuth0 } from "@auth0/auth0-react";
import { Switch } from "react-router-dom";

import AppLoader from "components/appLoader/AppLoader"
import PublicRoute from 'components/sessionManager/PublicRoute';
import PrivateRoute from 'components/sessionManager/PrivateRoute';

import Dashboard from 'pages/dashboard/Dashboard';
import PageLayout from 'components/layout/pageLayout/PageLayout';
import ModalLayout from 'components/layout/modalLayout/ModalLayout';
import Settings from 'pages/settings/Settings';
import AuthContextProvider from 'context/auth-context';
import Send from 'pages/send/Send';
import AppContextProvider from 'context/app';
import { ToastContainer } from 'react-toastify';
import Landing from 'pages/landing/Landing';
import LandingLayout from 'components/layout/landingLayout/LandingLayout';
import 'react-toastify/dist/ReactToastify.css';
import Exchange from 'pages/exchange/Exchange';
import Beneficiaries from 'pages/beneficiaries/Beneficiaries';
import BeneficiaryDetails from 'pages/beneficiaryDetails/BeneficiaryDetails';
import AddBeneficiary from 'pages/addBeneficiary/AddBeneficiary';


// import Requests from 'pages/Requests/Requests';
// import Accounts from "pages/Accounts/Accounts"
// import Transactions from "pages/Transactions/Transactions"
// import WalletDetails from "pages/WalletDetails/WalletDetails";


function App() {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AuthContextProvider>
      <AppContextProvider>
        <Switch>
          <PrivateRoute path="/accounts" component={Dashboard} layout={PageLayout} />
          <PrivateRoute path="/settings" component={Settings} layout={PageLayout} />
          <PrivateRoute path="/send/:id?" component={Send} layout={ModalLayout} />
          <PrivateRoute path="/exchange" component={Exchange} layout={ModalLayout} />
          <PrivateRoute path="/beneficiaries" component={Beneficiaries} layout={PageLayout} />
          <PrivateRoute path="/beneficiary-details/:id" component={BeneficiaryDetails} layout={ModalLayout} />
          <PrivateRoute path="/add-beneficiary" component={AddBeneficiary} layout={ModalLayout} />


          <PublicRoute path="/" component={Landing} layout={LandingLayout} redirectIfAuth={true}/>
          {/*
            <PrivateRoute path={props.match.url + '/transactions'} component={Transactions} layout={Wrapper} />
            <PrivateRoute path={props.match.url + '/wallet/:id'} component={WalletDetails} layout={Wrapper} />
            <PrivateRoute path={props.match.url + '/requests'} component={Requests} layout={Wrapper} />
            <PrivateRoute path={props.match.url} component={Accounts} layout={Wrapper} />
        */}
        </Switch>
        <ToastContainer
                    autoClose={3000}
                    hideProgressBar
                    pauseOnHover
                    draggable
                    closeOnClick={false}
                    closeButton={false} />
      </AppContextProvider>
    </AuthContextProvider>
  );
}

export default App;
