import React from 'react'
import { useTranslation } from 'react-i18next';

import classes from './CounterpartyTabs.module.css';
import { ReactComponent as PaysetLogo } from 'assets/images/svg/payset-logo.svg';

const CounterpartyTabs = ({ isInternalTab, setIsInternalTab, setNewFriendId, hideExternal }) => {
    const { t } = useTranslation();
    return <>
        <div className="flex items-start mt-8 mb-10">
            {hideExternal ? null : <h3 className={["mr-6", classes['tab'], (isInternalTab ? "" : classes['active-tab'])].join(' ')}
                onClick={() => { setIsInternalTab(false); setNewFriendId && setNewFriendId(null)}}
            >
                {t('External')}
            </h3>
            }
            <h3 className={["flex items-center", classes['tab'], (isInternalTab ? classes['active-tab'] : "")].join(' ')}
                onClick={() => setIsInternalTab(true)}
            >
                {t('Internal')}
                <PaysetLogo className="ml-2" />
            </h3>
        </div>
    </>
}

export default CounterpartyTabs
