const identityDetails = {
    "d": {
        "BrandName": "Payset test program",
        "CompanyName": "",
        "CopyRightText": "",
        "DomainName": "",
        "IsActive": true,
        "IsConvert": true,
        "IsIban": true,
        "IsPayout": true,
        "IsPrepaid": true,
        "IsTransfer": true,
        "Name": "Test Program",
        "Theme": "Tmp_Payset",
        "URLDevCenter": "",
        "URLMerchantCP": "",
        "URLProcess": "",
        "URLWallet": "",
        "URLWebsite": "",
        "__type": "AppIdentity.AppIdentityDetails:WebServices"
    }
};
export default identityDetails;;