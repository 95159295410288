import React from 'react'
import SVGIcon from 'components/svgIcon/SVGIcon'


const PlusIcon = (props) => {
    return (
        <SVGIcon width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="11" y="2" width="2" height="20" fill="#E12D26" />
            <rect x="22" y="11" width="2" height="20" transform="rotate(90 22 11)" fill="#E12D26" />
        </SVGIcon>
    )
}

export default PlusIcon
