const friends = {
    "d": {
        "Code": 0,
        "IsSuccess": true,
        "Items": [
            {
                "ActiveStatus": "Activated",
                "DestWalletId": "9090102",
                "FullName": "Rudi Reager",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932815",
                "FullName": "mihai Gomoi",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932815",
                "FullName": "mihai Gomoi asd",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932815",
                "FullName": "Leth 99",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932816",
                "FullName": "Anton Vasile",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932817",
                "FullName": "Buldozer Traian",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932818",
                "FullName": "Marian Pana",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932819",
                "FullName": "Zoran Michael",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932820",
                "FullName": "Cecilia Peter",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932821",
                "FullName": "Lionel Messi",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932822",
                "FullName": "Kevin De Bruyne",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932822",
                "FullName": "Scofield Michael",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932822",
                "FullName": "Ionut Paul",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932822",
                "FullName": "Paul Ban",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932822",
                "FullName": "Robert Nicolae",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932822",
                "FullName": "Jamie Vardy",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            },
            {
                "ActiveStatus": "FullyActive",
                "DestWalletId": "1932822",
                "FullName": "Kluivert Justin",
                "ProfileImage": null,
                "ProfileImageSize": 0,
                "RelationType": 31,
                "__type": "Customer.CustomerFriend:WebServices"
            }
        ]
    }
};
export default friends;