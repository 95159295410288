import React from 'react'

import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/svg/error-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close-icon.svg';

import classes from './InlineNotification.module.css';
import Button from 'components/button/Button';

const InlineNotification = (props) => {

    let icon = <InfoIcon />;
    if (props.isError) {
        icon = <ErrorIcon />;
    }

    return (
        <div className={[classes["notification"], (props.classes? props.classes : ""), (props.isGreyBg ? classes['grey-bg'] : "")].join(' ')}>
            {icon}
            <p className="small ml-2"> <span className="bold">{props.title}.</span> {props.text} </p>
            {props.dismissible ? <Button
                classes='focus:outline-none'
                clicked={props.clicked} >
                <CloseIcon alt="close" className={classes["close-icon"]} />
            </Button> : null
            }
        </div>
    )
}

export default InlineNotification
