import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorIcon } from 'assets/images/svg/error.svg';
import { ReactComponent as LoadingIcon } from 'assets/images/svg/loading.svg';
import classes from './NewFriendForm.module.css';
import BeneficiaryCard from 'components/beneficiaries/beneficiaryCard/BeneficiaryCard';

const NewFriendForm = ({setNewFriendId}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("")
    const [foundFriend, setFoundFriend] = useState(null);

    const wait = (ms, value) => {
        return new Promise(resolve => {
            setTimeout(function () {
                resolve(value);
            }, ms);
        })
    }

    const searchFriendHandler = async (evt) => {
        let value = evt.target.value;
        if(setNewFriendId) {
            setNewFriendId(value);
        }

        setErrorMsg("");
        if(value !== "") {
            setIsLoading(true);
            const data = await wait(700, { isSucces: true, Message: "Error message"});
            setIsLoading(false);
            if(data.isSucces) {
                setFoundFriend({
                    fullName: "Dummy data",
                    profileImage: undefined,
                    isInternal: true,
                    pictureType: "profile"
                });
                setNewFriendId(value);
            } else {
                setErrorMsg(data.Message);
            }
        } else {
            setFoundFriend(null);
        }
    }
    return <div className="mb-12">
        <div className={classes["input-container"]}>
            <input className={[classes['input'], "p"].join(' ')} 
                name="findFriend" 
                type="text" 
                placeholder={t('labels.findPaysetUser')}
                onBlur={searchFriendHandler}
            />
            <label className={[classes['label'], "caption"].join(' ')} htmlFor="findFriend">{t('labels.findPaysetUser')}</label>
            <div className={classes['icon']}>
                {isLoading && <LoadingIcon className={["animate-spin", classes["loading-icon"]].join(' ')} />}
                {errorMsg && <ErrorIcon />}
            </div>
            {errorMsg && <p className="caption mt-3 text-reor">{errorMsg}</p>}
        </div>
        { foundFriend &&
            <div className="mt-4">
                <BeneficiaryCard value={foundFriend}/>
            </div>
        }
    </div>
}

export default NewFriendForm
