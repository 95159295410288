import { createServer } from "miragejs";
import { objectToJsonResponse } from './utils/response';
import customer from "./sampleData/get/customer";
import identityDetails from "./sampleData/get/identityDetails";
import balance from "./sampleData/get/balance";
import requests from "./sampleData/get/requests";
import balanceTotal from "./sampleData/get/balanceTotal";
import currencyRates from "./sampleData/get/currencyRates";
import beneficiaries from "./sampleData/get/beneficiaries";
import friendRequests from "./sampleData/get/friendsRequests";
import friends from "./sampleData/get/friends";
import transferAmount from "./sampleData/mutations/transferAmount";
import requestPayment from "./sampleData/mutations/requestPayment";
import getStaticData from "./sampleData/get/getStaticData";
import getSupportedCurrencies from "./sampleData/get/getSupportedCurrencies";
import getAccessLog from "./sampleData/get/getAccessLog";
import transferAmountBetweenCurrencies from "./sampleData/mutations/transferAmountBetweenCurrencies";
import updatePassword from "./sampleData/mutations/updatePassword";
import updatePincode from "./sampleData/mutations/updatePincode";
import getRulesForAddABeneficary from "./sampleData/get/getRulesForAddABeneficary";
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const host = process.env.REACT_APP_DOMAIN;

export function makeServer({ environment = "test" } = {}) {

  let server = createServer({
    routes() {
      const config = { timing: 0 };
      //this.host = domain;
      //this.namespace = `api`;
      this.urlPrefix =`${host}/api`;

      // GET
      this.post("/getCustomer", () => objectToJsonResponse(customer, false), config);
      this.post("/getIdentityDetails", () => objectToJsonResponse(identityDetails, false), config);
      this.post("/balanceGetRows", () => objectToJsonResponse(balance, false), config);
      this.post("/GetRequests", () => objectToJsonResponse(requests, false), config);
      this.post("/getTotal", () => objectToJsonResponse(balanceTotal, false), config);
      this.post("/getCurrencyRates", () => objectToJsonResponse(currencyRates, false), config);
      this.post("/getFriends", () => objectToJsonResponse(friends, false), config);
      this.post("/getBeneficiaries", () => objectToJsonResponse(beneficiaries, false), config);
      this.post("/getFriendRequests", () =>  objectToJsonResponse(friendRequests, false), config);
      this.post("/getStaticData", () =>  objectToJsonResponse(getStaticData, false), config);
      this.post("/getSupportedCurrencies", () =>  objectToJsonResponse(getSupportedCurrencies, false), config);
      this.post("/getAccessLog", () =>  objectToJsonResponse(getAccessLog, false), config);
      this.post("/getRulesForAddABeneficiary", () => objectToJsonResponse(getRulesForAddABeneficary, false), config);

      //Mutations/POST
      this.post("/transferAmount", () => objectToJsonResponse(transferAmount, false), config);
      this.post("/requestPayment", () => objectToJsonResponse(requestPayment, false), config);
      this.post("/transferAmountBetweenCurrencies", () => objectToJsonResponse(transferAmountBetweenCurrencies, false), config);
      this.post("/updatePassword", () => objectToJsonResponse(updatePassword, false), config);
      this.post("/updatePincode", () => objectToJsonResponse(updatePincode, false), config);

      // this is very important, otherwise auth is not working properly
      // auth0 needs to make a request to https://payset.eu.auth0.com/oauth/token
      this.passthrough(`https://${auth0Domain}/**`);
      // cloudflare calls
      this.passthrough(`https://cloudflareinsights.com/cdn-cgi/rum`);
    }
  })
  return server
}