import React, { useState } from 'react'
import UserAvatar from 'react-user-avatar'

import classes from "./TransactionItem.module.css";

import { ReactComponent as ErrorIcon } from 'assets/images/svg/error.svg';
import { ReactComponent as ExchangeIcon } from 'assets/images/svg/exchange-icon.svg';
import { ReactComponent as PaysetLogo } from 'assets/images/svg/payset-logo.svg';

import Enums from 'enums/Enums';

const TransactionItem = (props) => {
    const [item] = useState(props.transactionDetails);

    let image = <UserAvatar size="40" className={classes['transaction-img']} name={item.avatarName} colors={['#E0BAB9', '#A3AFBF', '#656567']} />;
    if (item.type === Enums.transactionType.Exchange) {
        image = <ExchangeIcon className={classes['transaction-img']} />
    }
    return (
        <div className={classes.transaction + " px-3 py-6"} onClick={props.onClick}>
            {image}
            <p className="flex items-center">
                {item.isInternal && item.type !== Enums.transactionType.Exchange && <PaysetLogo className="mr-2" />}
                {item.transactionHeader}
            </p>
            <p className={"transaction-amount mono" + (item.rejected ? " text-reor" : "")}>
                {item.amount}
            </p>
            <div className={classes['transaction-details'] + " flex items-center"}>
                {item.rejected ? <p className="text-reor caption flex"><ErrorIcon className="mr-1" /> Rejected <span className="mx-1 text-desaturate-normal">/</span></p> : null}
                <p className="caption transaction-hour text-desaturate-normal">{item.displayHour}</p>
                {item.comment !== "" ? <p className="caption transaction-invoice text-desaturate-normal">&nbsp;{`/ ${item.comment}`}</p> : null}
            </div>
            <div className={classes['transaction-exchange'] + " caption text-desaturate-normal"}>{item.currencyFromAmount}</div>
        </div>
    );
}

export default TransactionItem
