import { Switch } from "react-router-dom";
import Header from 'components/header/Header';
import Tabs from "components/tabs/Tabs";
import NavigationItem from "components/navigationItem/NavigationItem";

import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import PublicRoute from "components/sessionManager/PublicRoute";
import Wrapper from "components/wrapper/Wrapper";
import Accounts from "pages/accounts/Accounts";
import Transactions from "pages/transactions/Transactions";
import WalletDetails from "pages/walletDetails/WalletDetails";
import Requests from "pages/requests/Requests";

function Dashboard({ currency, match }) {
    let tabs = null;
    //const [page] = useState(1);
    const { t } = useTranslation();
    const location = useLocation();

    let params = {};
    params.filters = {
        CurrencyIso: currency,
        RequestStatuses: [2] // 0Rejected, 1Confirmed, 2Pending
    }
    if (location.pathname.indexOf('/wallet/') === -1) {
        tabs = (
            <Tabs>
                <NavigationItem
                    exact
                    link={match.url}
                    classes={"cursor-pointer h3 mr-6 opacity-50"}
                    type="tab">
                        {t('Accounts')}
                </NavigationItem>
                <NavigationItem
                    link={match.url + '/transactions'}
                    classes={"cursor-pointer h3 mr-6 opacity-50"}
                    type="tab">
                        {t('Transactions')}
                </NavigationItem>
            </Tabs>
        )
    }
    return (<>
        <Header />
        {/** TODO this looks odd, should we not move this to a proper component */}
        {tabs}
        {/** TODO this looks odd, all routes should be defined in the same place or? */}
        <Switch>
            <PublicRoute path={match.url + '/transactions'} component={Transactions} layout={Wrapper} />
            <PublicRoute path={match.url + '/wallet/:id'} component={WalletDetails} layout={Wrapper} />
            <PublicRoute path={match.url + '/requests'} component={Requests} layout={Wrapper} />
            <PublicRoute path={match.url} component={Accounts} layout={Wrapper} />
        </Switch>
    </>
    );
}

export default Dashboard;