import React from 'react'
import Button from 'components/button/Button'

import { ReactComponent as CloseIcon } from 'assets/images/svg/close-icon.svg';
import classes from './Modal.module.css'

const Modal = (props) => {

    return (
        <div className={classes["modal-container"]}>
            <div className={classes["modal-backdrop"]} onClick={props.closeModal}></div>
            <div className={classes["modal-content"]}>
                <Button
                    classes={classes.close}
                    clicked={props.closeModal} >
                    <CloseIcon alt="close" width="32" height="32" />
                </Button>
                {props.children}
            </div>
        </div>
    )
}

export default Modal
