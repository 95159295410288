import React, { useState, useEffect } from 'react';

export const AppContext = React.createContext({
    incognito: false,
    setIncognito: () => { },
    isPickerOpen: false,
    isAccountBlocked: false,
    setIsAccountBlocked: () => { },
    wrongCodeCount: null,
    setWrongCodeCount: () => { },
    isMerchant: false,
    setIsMerchant: () => { },
    username: "",
    setUsername: () => {}
});

const AppContextProvider = (props) => {
    const [incognito, setIncognito] = useState(false);
    const [isPickerOpen, setisPickerOpen] = useState(false);
    const [isAccountBlocked, setIsAccountBlocked] = useState(false);
    const [wrongCodeCount, setWrongCodeCount] = useState(0);
    const [isMerchant, setIsMerchant] = useState(false);
    const [username, setUsername] = useState("");

    useEffect(() => {
        let localStorageIncognito = JSON.parse(localStorage.getItem('incognito'));
        if (localStorageIncognito) {
            setIncognito(true);
        }
    }, [])

    const setIncognitoHandler = (value) => {
        localStorage.setItem('incognito', value);
        setIncognito(value);
    }

    const setIsPickerOpenHandler = (value) => {
        setisPickerOpen(value);
    }

    const setIsAccountBlockedHandler = (value) => {
        setIsAccountBlocked(value);
    }

    const setWrongCodeCountHandler = (value) => {
        setWrongCodeCount(value)
    }
    const setIsMerchantHandler = (value) => {
        setIsMerchant(value);
    }

    const setUsernameHandler = (value) => {
        setUsername(value);
    }

    return (
        <AppContext.Provider value={{
            incognito: incognito,
            setIncognito: setIncognitoHandler,
            isPickerOpen: isPickerOpen,
            setIsPickerOpen: setIsPickerOpenHandler,
            isAccountBlocked: isAccountBlocked,
            setIsAccountBlocked: setIsAccountBlockedHandler,
            wrongCodeCount: wrongCodeCount,
            setWrongCodeCount: setWrongCodeCountHandler,
            isMerchant: isMerchant,
            setIsMerchant: setIsMerchantHandler,
            username: username,
            setUsername: setUsernameHandler
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContextProvider;