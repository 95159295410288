import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from "react-router";
import { useQuery } from "react-query";
import queryConfig from 'services/query';
import { toast } from 'react-toastify';

import classes from './ExternalBeneficiary.module.css';

const ExternalBeneficiary = () => {
    let history = useHistory();
    const { data: beneficiaryData, isFetching } = useQuery(queryConfig.getBeneficiaries());
    const { id } = useParams();
    const [beneficiary, setBeneficiary] = useState({});

    let avatar = beneficiary?.LogoAsBytes ? "data:image/png;base64," + btoa(String.fromCharCode.apply(null, new Uint8Array(beneficiary.LogoAsBytes))) : undefined;

    useEffect(() => {
        if (!isFetching && beneficiaryData) {
            const beneficiaryTemp = beneficiaryData.find(item => item.BankAccount_id === +id);
            setBeneficiary(beneficiaryTemp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, beneficiaryData]);

    return (
        <div className={classes["beneficiary-container"]}>
            <h1 style={{height: 24 + 'px'}} className="mb-14 col-start-1 col-end-last">{beneficiary.AccountName}</h1>
            <div className="col-start-2 col-end-3 row-start-2 row-end-last flex flex-col items-center">
                <img src={avatar} className="rounded-full" alt="avatar" />
                <div>
                    <p className="caption mb-4 mt-12 cursor-pointer" onClick={() => history.push("/send")}>Send funds</p>
                    <p className="caption mb-8 cursor-pointer" onClick={() => toast("Not implemented!")}>Transactions (8)</p>
                </div>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>IBAN</p>
                <p className="">{beneficiary.IBAN}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>Address</p>
                <p className="">{beneficiary.BeneficiaryAddress}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>Country</p>
                <p className="">{beneficiary.BeneficiaryCountry}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>City</p>
                <p className="">{beneficiary.BeneficiaryCity}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>ZIP</p>
                <p className="">{beneficiary.BeneficiaryZip}</p>
            </div>

            <h3 className="mb-4 mt-8">BANK DETAILS</h3>

            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>Bank name</p>
                <p className="">{beneficiary.BankName}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>Bank Code</p>
                <p className="">{beneficiary.BankPostalCode}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>Bank Address</p>
                <p className="">{beneficiary.BankStreet1}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>Country</p>
                <p className="">{beneficiary.BankCountryISOCode}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>City</p>
                <p className="">{beneficiary.BankCity}</p>
            </div>
            <div className={classes["group"]}>
                <p className={classes.label + " text-desaturate-light"}>ZIP</p>
                <p className="">{beneficiary.BankPostalCode}</p>
            </div>
        </div>
    )
}

export default ExternalBeneficiary
