//import { optionsKnob } from '@storybook/addon-knobs'
import BeneficiaryCard from 'components/beneficiaries/beneficiaryCard/BeneficiaryCard'
import NotificationContent from 'components/notifications/NotificationContent'
import Button from 'components/button/Button'
import Loading from 'components/loading/Loading'
import Pincode from 'components/pincode/Pincode'
import Enums from 'enums/Enums'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import fetch from 'services/fetch'
import queryConfig from 'services/query'
import Utils from 'services/utils'

import classes from "./RequestedMoney.module.css"

const RequestedMoney = ({ request, closeModal }) => {
    const { t } = useTranslation();
    const cardData = {
        fullName: request.friendName || "Unknown",
        pictureType: "profile"
    }

    const { transactionType, requestMoneyType } = Enums;

    const [selectedCurrency, setSelectedCurrency] = useState(request.CurrencyISOCode);
    const [showPincode, setShowPincode] = useState(false);
    const [modalAction, setModalAction] = useState("");
    const [isRejectBtnLoading, setIsRejectBtnLoading] = useState(false);
    const [isAcceptBtnLoading, setIsAcceptBtnLoading] = useState(false);

    const [errorMsg, setErrorMsg] = useState("")

    const { data: walletList, isLoading } = useQuery(queryConfig.getTotal());

    const queryClient = useQueryClient();
    const {mutateAsync: mutateRequest} = useMutation(fetch("replyRequest"), {
        onSuccess: (data) => {
            if (data.IsSuccess) {
                queryClient.invalidateQueries('cbo.balance.GetRequests');
                queryClient.invalidateQueries('cbo.balance.getTotal');
            }
        }
    });

    const changeCurencyHandler = (evt) => {
        setSelectedCurrency(evt.target.value);
    }

    const showPincodeHandler = (value) => {
        if (value === "accept") {
            setIsAcceptBtnLoading(true);
            setTimeout(() => {
                setIsAcceptBtnLoading(false);
                setModalAction("accept");
                setShowPincode(true);
            }, 500);
        } else {
            setIsRejectBtnLoading(true);
            setTimeout(() => {
                setIsRejectBtnLoading(false);
                setModalAction("reject");
                setShowPincode(true);
            }, 500);
        }
    }

    const onPinSucess = (message) => {
        closeModal();
        showNotification(message, "success");
    }

    const onPinFail = (error) => {
        setErrorMsg(error);
    }

    const onCallFail = (message) => {
        closeModal();
        showNotification(message, "error");
    }

    const onPinEntered = async (pinCode) => {
        let approveValue = modalAction === "accept" ? true : false;
        setErrorMsg("");
        const data = await mutateRequest({
            requestId: request.ID,
            approve: approveValue,
            pinCode: pinCode,
            text: ""
        });
        return data
    }

    const showNotification = (data, type) => {
        if (type === "success") {
            toast(<NotificationContent type="success" data={data} />, {
                className: 'white-toast default-toast'
            })
        } else {
            toast(<NotificationContent type="error" description={data} />, {
                className: 'white-toast default-toast'
            })
        }
    }

    return <>
        <h1 className="mb-12">{t("labels.fundsRequest")}</h1>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light self-start">{t("labels.cameFrom")}:</p>
            <BeneficiaryCard value={cardData} />
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.amount")}:</p>
            <p className="medium-font mono">{request.displayAmount}</p>
        </div>

        <div className={[classes['group'], 'mb-5'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.date")}:</p>
            <p className="small">{request.displayDate}, {request.displayHour}</p>
        </div>

        <div className={[classes['group'], 'mb-8'].join(' ')}>
            <p className="small text-desaturate-light">{t("labels.description")}:</p>
            <p className="small">{request.Text}</p>
        </div>

        <h1 className="mt-8 mb-6">{t("labels.sendFunds")}</h1>

        <p className="caption text-desaturate-light mb-2">{t("labels.from")}:</p>
        <div className="flex justify-between mb-10 border-b border-platinum pb-2">
            <label className="p" htmlFor="walletList">{selectedCurrency} {t("labels.account")}</label>
            <select name="walletList" id="walletList" value={selectedCurrency} onChange={changeCurencyHandler}>
                <Loading condition={isLoading}>
                    {walletList.map(wallet => {
                        return <option key={wallet.CurrencyIso} value={wallet.CurrencyIso}>{Utils.currencyCodes[wallet.CurrencyIso]} {wallet.Current}</option>
                    })}
                </Loading>
            </select>
        </div>
        {/* <p className="caption mono mb-6">+ Fee $45.34</p> */}

        {(request.status === transactionType.Pending && request.flag === requestMoneyType.RequestFrom) ?
            showPincode ?
                <Pincode setShowPincode={setShowPincode}
                    isError={false}
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    onPinEntered={onPinEntered}
                    onPinSucess={onPinSucess}
                    onPinFail={onPinFail}
                    onCallFail={onCallFail}
                />
                : <div className="flex justify-end">
                    <Button clicked={() => showPincodeHandler('reject')}
                        isLoading={isRejectBtnLoading}
                        classes="mr-4"
                        btnTheme="danger"
                        size="medium"
                        case="uppercase">
                        Reject
                    </Button>
                    <Button clicked={() => showPincodeHandler('accept')}
                        isLoading={isAcceptBtnLoading}
                        btnTheme="primary"
                        size="medium"
                        case="uppercase">
                        Send funds
                    </Button>
                </div>
            :
            <Button clicked={closeModal}
                isLoading={isAcceptBtnLoading}
                classes="ml-auto"
                size="medium"
                btnTheme="primary"
                case="uppercase"
            >
                Done
            </Button>
        }
    </>
}

export default RequestedMoney
