import React, { useEffect, useState } from 'react';

import classes from './StepsTabs.module.css';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/checked-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/svg/error.svg';
import { useTranslation } from 'react-i18next';


const StepsTabs = ({ isError, displaySendTab, hasProvidedCounterpartyDetails, setHasProvidedCounterpartyDetails, addedBeneficiarySuccessfully, setWantsToChangeDetails }) => {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState("counterparty");
    const [counterpartyDetailsIsCompleted, setCounterpartyDetailsIsCompleted] = useState(false);
    const [comesFromBankDetailsTab, setComesFromBankDetailsTab] = useState(false);



    useEffect(() => {
        if (hasProvidedCounterpartyDetails) {
            setActiveTab("bank");
            setCounterpartyDetailsIsCompleted(true);
        }
    }, [hasProvidedCounterpartyDetails]);

    const toggleTabs = (value) => {
        if (counterpartyDetailsIsCompleted) {
            setHasProvidedCounterpartyDetails(false);
            setComesFromBankDetailsTab(true);
            if(setWantsToChangeDetails) {
                setWantsToChangeDetails(true);
            }
        }
        if (value === "bank") {
            setHasProvidedCounterpartyDetails(true);
            setComesFromBankDetailsTab(false);
        }
    }

    return <div className="flex border-t border-platinum mb-12">

        <div onClick={() => toggleTabs("counterparty")}
            className={
                [classes['tab'],
                (activeTab === "counterparty" && !addedBeneficiarySuccessfully ? classes['active'] : ""),
                (counterpartyDetailsIsCompleted && !addedBeneficiarySuccessfully ? classes["completed"] : ""),
                (comesFromBankDetailsTab && !addedBeneficiarySuccessfully ? classes["completedAndSelected"] : ""),
                (addedBeneficiarySuccessfully ? classes['border-top'] : "")
                ].join(' ')
            }
        >
            <div className={classes["checked-icon"]}><CheckedIcon /></div>
            <p className={["caption ml-2", classes['tab-text']].join(' ')}>{t("labels.CounterpartyDetails")}</p>
        </div>

        <div onClick={() => toggleTabs("bank")}
            className={
                [classes['tab'],
                (activeTab === "bank" && !addedBeneficiarySuccessfully ? classes['active'] : ""),
                (addedBeneficiarySuccessfully ? classes['border-top'] : "")
                ].join(' ')
            }
        >
            {isError ?
                <ErrorIcon />
                : <div className={classes["checked-icon"]}><CheckedIcon /></div>
            }
            <p className={["caption ml-2", classes['tab-text']].join(' ')}>{t("labels.BankAccountDetails")}</p>
        </div>

        {displaySendTab &&
            <div className={[classes['tab'], (addedBeneficiarySuccessfully ? classes['active'] : "")].join(' ')}>
                <div className={classes["checked-icon"]}><CheckedIcon /></div>
                <p className={["caption ml-2", classes['tab-text']].join(' ')}>{t("labels.SendFunds")}</p>
            </div>
        }
    </div>
}

export default StepsTabs
