import fetch from 'services/fetch';
import CryptoJS from 'crypto-js';

const generalQueryConfig = {
    staleTime: 10000,
    cacheTime: 10000,
    refetchOnWindowFocus: false,
    refetchInterval: 660000,
    retry: false,
    onError: (err) => {
    }
}

let queryConfig = {};

//app identity
queryConfig.getIdentityDetails = (configOptions) => {
    return {
        queryKey: "cbo.appIdentity.getIdentityDetails",
        queryFn: () => fetch("getIdentityDetails")({}),
        ...generalQueryConfig, 
        ...configOptions
    };
}

queryConfig.getFriends = (params) => {
    return {
        queryKey: "cbo.customer.getFriends",
        queryFn: () => fetch("getFriends")({
            "destWalletId": ""
        }),
        ...generalQueryConfig
    };
}

queryConfig.getFriendRequests = (configOptions) => {
    return {
        queryKey: "cbo.customer.getFriendRequests",
        queryFn: () => fetch("getFriendRequests")({}),
        ...generalQueryConfig, 
        ...configOptions
    };
}

queryConfig.findFriend = (params) => {
    return {
        queryKey: ["cbo.customer.findFriend", { params }],
        queryFn: () => fetch("findFriend")({
            "searchTerm": params.query,
            "sortAndPage": {
            }
        }),
        ...generalQueryConfig
    };
}

queryConfig.getBeneficiaries = (params) => {
    return {
        queryKey: "cbo.paymentMethods.getBeneficiaries",
        queryFn: () => fetch("getBeneficiaries")({}),
        ...generalQueryConfig
    };
}



queryConfig.getCustomer = (configOptions) => {
    return {
        queryKey: "cbo.customer.getCustomer",
        queryFn: () => fetch("getCustomer")({
            "isExtendedDetails": false
        }),
        ...generalQueryConfig, 
        ...configOptions
    };
}

queryConfig.searchTransactions = (params) => {
    return {
        queryKey: "cbo.transactions.search",
        queryFn: () => fetch("search")({
            "filters": {},
            "loadOptions": {
                "LoadPayment": true,
                "LoadPayer": true,
                "LoadMerchant": true,
                LoadFees: true
            },
            "sortAndPage": {
            }
        }),
        ...generalQueryConfig
    };
}

//Balance
queryConfig.balanceGetRows = (params) => {
    let data = {
        filters: {},
        sortAndPage: {}
    };
    if (params?.filters) {
        data.filters = params.filters;
    }
    if (params?.sortAndPage) {
        data.sortAndPage = params.sortAndPage;
    }

    return {
        queryKey: ["cbo.balance.getRows", data],
        queryFn: () => fetch("balanceGetRows")(data),
        keepPreviousData: true,
        ...generalQueryConfig
    };
}

queryConfig.balanceGetRequests = (params) => {
    let data = {
        filters: {},
        sortAndPage: {}
    };
    if (params?.filters) {
        data.filters = params.filters;
    }
    if (params?.sortAndPage) {
        data.sortAndPage = params.sortAndPage;
    }

    let hash = CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(JSON.stringify(params)));
    return {
        queryKey: ["cbo.balance.GetRequests", hash],
        queryFn: () => fetch("GetRequests")(data),
        ...generalQueryConfig
    };
}

queryConfig.getTotal = (configOptions) => {
    return {
        queryKey: "cbo.balance.getTotal",
        queryFn: () => fetch("getTotal")({}),
        ...generalQueryConfig,
        ...configOptions
    };
}

// International
queryConfig.getCurrencyRates = (params) => {
    return {
        queryKey: "cbo.international.getCurrencyRates",
        queryFn: () => fetch("getCurrencyRates")({}),
        ...generalQueryConfig
    };
}

queryConfig.getBalanceTransferTypes = (params) => {
    return {
        queryKey: "cbo.international.getBalanceTransferTypes",
        queryFn: () => fetch("getBalanceTransferTypes")({}),
        ...generalQueryConfig
    };
}

queryConfig.getStaticData = (configOptions) => {
    return {
        queryKey: "cbo.international.getStaticData",
        queryFn: () => fetch("getStaticData")({}),
        ...generalQueryConfig, 
        ...configOptions
    };
}

// IBAN
queryConfig.getAccountDetails = (params, configOptions) => {
    return {
        queryKey: "cbo.iban.Account_AccountDetails",
        queryFn: () => fetch("Account_AccountDetails")(params ? params : {}),
        ...generalQueryConfig, 
        ...configOptions
    };
}

//APPIdentity
queryConfig.getSupportedCurrencies = (configOptions) => {
    return {
        queryKey: "cbo.appIdentiy.GetSupportedCurrencies",
        queryFn: () => fetch("getSupportedCurrencies")({}),
        ...generalQueryConfig, 
        ...configOptions
    };
}

//Store
queryConfig.getMerchant = (params, configOptions) => {
    return {
        queryKey: "cbo.shop.getMerchant",
        queryFn: () => fetch("getMerchant")({
            "merchantNumber": params.merchantNumber
        }),
        ...generalQueryConfig, 
        ...configOptions
    };
}

//Rules
queryConfig.getRules = (params, configOptions) => {
    let hash = CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(JSON.stringify(params)));
    return {
        queryKey: ["rules.getMerchant", hash],
        queryFn: () => fetch("getRulesForAddABeneficiary")(params),
        ...generalQueryConfig, 
        ...configOptions
    };
}

queryConfig.getAccessLog = (params, configOptions) => {
    return {
        queryKey: "getLoginHistory",
        queryFn: () => fetch('getAccessLog')(params),
        ...generalQueryConfig,
        ...configOptions
    }
};



/*
useQuery.saveCustomer = (params) => {
    return useQuery("customer.saveCustomer", fetch("saveCustomer", params), generalQueryConfig);
}

// Balance
useQuery.getTotal = (params) => {
    return useQuery("balance.getTotal", fetch("getTotal", params), generalQueryConfig);
}

useQuery.getWallets = (params) => {
    return useQuery("balance.getRows", fetch("getRows", params), generalQueryConfig);
}



// Transactions
useQuery.searchTransactions = (params) => {
    return useQuery("transactions.search", fetch(params), generalQueryConfig);
}
*/
export default queryConfig;