import Enums from 'enums/Enums';
import React from 'react'
import UserAvatar from 'react-user-avatar';
import Utils from 'services/utils'

import classes from './RequestItem.module.css';

const RequestItem = ({ request, onClick }) => {
    return (
        request ?
            <div className={classes.request + " px-3 py-6"
            } key={request.ID} onClick={onClick} >
                <div className={[classes["request-img-container"], (request.flag === Enums.requestMoneyType.RequestFrom ? classes["requestFrom"] : "")].join(' ')}>
                    <UserAvatar size="32" className={classes['img-user']}
                        name={request.username ? request.username : "-"}
                    />
                    <UserAvatar size="40" className={classes['img-friend']}
                        name={request.friendName ? request.friendName : "+"}
                        colors={['#E0BAB9', '#A3AFBF', '#656567']}
                    />
                </div>
                <p className="font-normal">
                    {request.requestHeader}
                </p>
                <p className="">
                    {request.displayAmount}
                </p>
                <div className={classes['request-details'] + " flex items-center"}>
                    <p className={ Enums.transactionTypeColor[request.RequestStatus] + " caption"}>{request.RequestStatus}</p>
                    <p className="caption opacity-60">&nbsp;{`/ ${Utils.getHour(request.RequestDate)}`}</p>
                    {request.Text && <p className="caption text-desaturate-normal">&nbsp;{`/ ${request.Text}`}</p>}
                </div>
                <div className={classes['request-exchange'] + " caption opacity-60"}></div>
            </div >
            : ""
    )
}

export default RequestItem
