import React from 'react';
import { Field } from 'formik';

import classes from './InputField.module.css';
import { ReactComponent as ErrorSign } from 'assets/images/svg/error.svg';

const InputField = (props) => {
    return (
        <div className={props.classes + ' ' + classes.inputWrapper}>
            <Field
                className={classes.input + ' ' + props.inputStyles + " p text-charcoal focus:outline-none row-start-2 " + (props.isGreyBg ? classes['grey-bg'] : "")}
                name={props.name}
                autoFocus={props.autoFocus}
                placeholder={props.placeholder}
                disabled={props.disabled}
                pattern={props.pattern}
                type={props.type}
            />
            <label className={classes.label + " caption flex row-start-1 row-end-2"} htmlFor={props.name}>
                {props.children}
            </label>
            {props.errors ?
                props.touched[props.name] && props.errors[props.name] ? <ErrorSign className={classes.errorSign} /> : null
                : null
            }
            {props.errors ?
                props.errors[props.name] && props.touched[props.name]
                && <div className={classes.error + " caption text-reor col-start-1"}>
                    {props.errors[props.name]}
                </div>
                : null
            }
        </div>
    )
}

export default InputField;
