import Enums from "enums/Enums";
import Utils from "./utils";

const sortAllFriendsByFirstLetter = (allFriends) => {
    let sortedFriends = []
    allFriends.forEach(item => {
        let firstLetter = isLetter(item)
        if (sortedFriends.length === 0) {
            sortedFriends.push({
                letter: firstLetter,
                list: [item]
            })
        } else {
            let indexInList = sortedFriends.findIndex(elem => elem.letter === firstLetter);
            if (indexInList < 0) {
                // not found, create new entry
                sortedFriends.push({
                    letter: firstLetter,
                    list: [item]
                })
            } else {
                // found, push in the list
                sortedFriends[indexInList].list.push(item);
            }
        }
    })
    sortedFriends.sort((a, b) => {
        //we need this check because we need to put #-group at the end of the list
        //ex: '#'.localCompare('a') returns -1 which means '#' comes before 'a' and we need the opposite
        if (a.letter === Utils.otherSymbol) {
            return 1;
        }
        if (b.letter === Utils.otherSymbol) {
            return -1;
        }
        return a.letter.localeCompare(b.letter);
    });

    sortedFriends.map(item => {
        return item.list.sort((a, b) => a.fullName.localeCompare(b.fullName));
    });

    return sortedFriends;
}

const isLetter = (word) => {
    let firstLetter = word.fullName[0].toUpperCase();
    let isLetter = Utils.alphabetLetters.some(letter => letter === firstLetter);
    if (isLetter) {
        return firstLetter;
    }
    return Utils.otherSymbol;
}

const filterAllFriends = (allfriends, searchValue) => {
    if (searchValue) {
        let filteredTemp = allfriends.map(item => {
            let newArr = item.list.filter((friend, index) => {
                let friendNameArr = friend.fullName.split(" ");
                let test = friendNameArr.some(item => {
                    return item.toLowerCase().startsWith(searchValue.toLowerCase());
                })
                return test;
            });
            return newArr;
        });
        let filteredFriends = [];
        if (filteredTemp.length > 0) {
            let tempSearchArr = filteredTemp.filter(item => item.length > 0);
            if (tempSearchArr.length > 0) {
                filteredFriends = [{ list: tempSearchArr.flat(1) }];
            }
        }
        return filteredFriends;
    }
    return allfriends;
}

const filterFriendsByType = (friendsList, type) => {
    if (type) {
        let filteredFriends = [];
        friendsList.forEach(item => {
            let newArr = item.list.filter(friend => {
                return friend[type] === true
            });
            if (newArr.length > 0) {
                filteredFriends.push({
                    letter: item.letter,
                    list: [...newArr]
                })
            }
        })
        return filteredFriends;
    }

    return friendsList;
}

const sortTransactionsByDate = (transactionList) => {
    let transactionArr = []
    transactionList.forEach(transaction => {
        let trDate = Utils.getDate(transaction.date || transaction.RequestDate);
        if (transactionArr.length === 0) {
            transactionArr.push({
                day: trDate,
                list: [transaction]
            })
        } else {
            let indexInList = transactionArr.findIndex(elem => elem.day === trDate);
            if (indexInList < 0) {
                // not found, create new entry
                transactionArr.push({
                    day: trDate,
                    list: [transaction]
                });
            } else {
                // found, push in the list
                transactionArr[indexInList].list.push(transaction);
            }
        }
    })
    return transactionArr;
}

/**TODO TRANSACTION SERVICE */
const uniteExchangeEntries = (transactionList) => {
    let list = [...transactionList];
    list.forEach((transaction, index) => {
        if (transaction.SourceType === "System.TransferCurrencies" && transaction.Amount > 0) {
            let exchangeFrom = transactionList.find((item) => {
                return item.ID === transaction.SourceID;
            });
            transaction.CurrencyIsoFrom = exchangeFrom?.CurrencyIso;
            transaction.CurrencyFromAmount = exchangeFrom?.Amount;
            if (exchangeFrom) {
                exchangeFrom._hide = true;
            }
        }
    });
    return list;
}

const isInternal = (transaction) => {
    let sourceType = transaction.SourceType.split('.');
    if (sourceType[0] === 'System') {
        return true;
    }
    return false;
}

const buildTransactionForApp = (transactionList, services) => {
    let translate = services.translate;
    let tempTransactionList = [...transactionList];
    let transactionListForApp = [];
    // unite exchange entries
    tempTransactionList = uniteExchangeEntries(tempTransactionList);
    // remove unwanted entries
    tempTransactionList = transactionList.filter((t) => {
        return !t._hide &&
            t.SourceType !== "System.TransferFee" &&
            t.SourceType !== "TransferCur.ConversionFee" &&
            t.SourceType !== "System.AdminFee" &&
            t.SourceType !== "System.Admin" &&
            t.SourceType !== "PaymentRequest.ConversionFee";
    });
    tempTransactionList.forEach(transaction => {
        let tempItem = {
            currency: transaction.CurrencyIso,
            original: transaction,
            avatarName: "ERR",
            date: transaction.InsertDate,
            id: transaction.ID,
            pending: transaction.IsPending,
            status: transaction.IsPending ? Enums.transactionType.Pending : Enums.transactionType.Confirmed,
            amount: Utils.getDisplayAmount(Math.abs(transaction.Amount), transaction.CurrencyIso),
            displayHour: Utils.getHour(transaction.InsertDate),
            displayDate: Utils.beautifyDate(Utils.getDate(transaction.InsertDate)),
            comment: transaction.Comment !== "" ? transaction.Comment : "",
            isInternal: isInternal(transaction),
            profileImage: transaction.SourceAccountProfileImage

        }
        // Receive from
        if (transaction.SourceType === "System.Transfer" && transaction.Amount > 0) {
            tempItem.avatarName = transaction.SourceAccountName !== "" ? transaction.SourceAccountName : "+";
            tempItem.transactionHeader = translate('ReceiveFrom', { name: transaction.SourceAccountName });
            tempItem.type = Enums.transactionType.MoneyIn;
        }
        //Sended to
        if (transaction.SourceType === "System.Transfer" && transaction.Amount < 0) {
            tempItem.avatarName = transaction.TargetAccountName !== "" ? transaction.TargetAccountName : "+";
            tempItem.transactionHeader = translate('SendTo', { name: transaction.TargetAccountName });
            tempItem.type = Enums.transactionType.MoneyOut;
        }
        //Exchange
        if (transaction.SourceType === "System.TransferCurrencies" && transaction.Amount > 0) {
            tempItem.avatarName = transaction.TargetAccountName !== "" ? transaction.TargetAccountName : "+";
            tempItem.transactionHeader = translate('ExchangeFrom', { currency: transaction.CurrencyIsoFrom });
            tempItem.currencyFromAmount = Utils.getDisplayAmount(transaction.CurrencyFromAmount, transaction.CurrencyIsoFrom);
            tempItem.type = Enums.transactionType.Exchange;
        }
        //Wire request
        if (transaction.SourceType === "Wires.PaymentRequest" && transaction.Amount < 0) {
            tempItem.avatarName = transaction.TargetAccountName !== "" ? transaction.TargetAccountName : "+";
            tempItem.transactionHeader = translate('ExternalSend', { name: transaction.TargetAccountName }) + " " + transaction.ID;
            tempItem.type = Enums.transactionType.MoneyOut;
        }
        tempItem.searchBundle = [tempItem.avatarName, transaction.Amount, transaction.Comment].join(' ');
        transactionListForApp.push(tempItem);
    });
    return transactionListForApp;
}

const transactionsListGrooming = (transactionsList, currency, services) => {
    let transactionsListForApp = buildTransactionForApp(transactionsList, services);
    if (currency) {
        transactionsListForApp = transactionsListForApp.filter((t) => {
            return t.currency === currency;
        });
    }
    return SortingService.sortTransactionsByDate(transactionsListForApp);
}

const filterTransactions = (transactionsList, filterArr) => {
    if (filterArr.length === 0) {
        //if no filter, just return the list
        return transactionsList;
    }

    let filteredTransactionsList = [];

    filterArr.forEach(filter => {
        let tempFilter = transactionsList.map((transaction, index) => {
            let filteredList = transaction.list.filter(item => item[filter.flag] === filter.id);
            if (filteredTransactionsList.length > 0) {
                //removing duplicates,if any, with 'new Set'
                var mergedList = [...new Set([...filteredTransactionsList[index].list, ...filteredList])];
            }
            let newObj = { ...transaction, list: mergedList || filteredList };
            return newObj;
        });
        filteredTransactionsList = [...tempFilter];
    })
    return filteredTransactionsList;
}

const filterRequests = (requestList, filterArr) => {
    if (filterArr.length === 0) {
        //if no filter, just return the list
        return requestList;
    }

    let filteredTransactionsList = [];

    filterArr.forEach(filter => {
        let tempFilter = requestList.map((transaction, index) => {
            let filteredList = transaction.list.filter(item => item[filter.flag] === filter.id);
            if (filteredTransactionsList.length > 0) {
                //removing duplicates,if any, with 'new Set'
                var mergedList = [...new Set([...filteredTransactionsList[index].list, ...filteredList])];
            }
            let newObj = { ...transaction, list: mergedList || filteredList };
            return newObj;
        });
        filteredTransactionsList = [...tempFilter];
    })
    return filteredTransactionsList;
}

const filterListBySearch = (list, searchValue) => {
    if (searchValue === "") {
        //if no search value, just return the list
        return list;
    }

    let tempSearch = list.map(listItem => {
        let filteredList = listItem.list.filter(item => {
            let wordsArr = item.searchBundle.split(' ');
            let isInArray = wordsArr.some(word => {
                return word.toLowerCase().startsWith(searchValue.toLowerCase());
            });
            if (isInArray) {
                return isInArray;
            }
            //if searchValue is not present in the words array, do an extra search in the entire string
            let isInString = item.searchBundle.toLowerCase().includes(searchValue.toLowerCase());
            return isInString;
        });
        let newObj = { ...listItem, list: filteredList };
        return newObj;
    });
    return tempSearch;
}

const buildRequestsForApp = (requestsList, services, username) => {
    let translate = services.translate;
    let tempRequestsList = [...requestsList];
    let requestsListForApp = [];
    tempRequestsList.forEach(request => {
        let tempItem = {
            ...request,
            displayHour: Utils.getHour(request.RequestDate),
            displayDate: Utils.beautifyDate(Utils.getDate(request.RequestDate)),
            displayAmount: Utils.getDisplayAmount(Math.abs(request.Amount), request.CurrencyISOCode),
            category: Enums.requestType.Money,
            status: Enums.transactionType[request.RequestStatus],
            username: username
        }

        if (request.SourceAccountName === username) {
            tempItem.requestHeader = translate("RequestTo", { target: request.TargetAccountName });
            tempItem.flag = Enums.requestMoneyType.RequestTo;
            tempItem.friendName = request.TargetAccountName;
            tempItem.type = Enums.transactionType.MoneyIn;
        } else {
            tempItem.requestHeader = translate("RequestFrom", { source: request.SourceAccountName });
            tempItem.flag = Enums.requestMoneyType.RequestFrom;
            tempItem.friendName = request.SourceAccountName;
            tempItem.type = Enums.transactionType.MoneyOut;
        }
        tempItem.searchBundle = [tempItem.friendName, request.Amount, request.Text].join(' ');
        requestsListForApp.push(tempItem);
    });
    return requestsListForApp
}

const mergeRequests = (requestsList, friendsList, services, username) => {
    let allRequests = [];
    let requestsTemp = buildRequestsForApp(requestsList, services, username);
    if (requestsList.length > 0) {
        allRequests = SortingService.sortTransactionsByDate(requestsTemp);
    }
    return allRequests;
}

let SortingService = {
    sortAllFriendsByFirstLetter: sortAllFriendsByFirstLetter,
    filterAllFriends: filterAllFriends,
    sortTransactionsByDate: sortTransactionsByDate,
    uniteExchangeEntries: uniteExchangeEntries,
    transactionsListGrooming: transactionsListGrooming,
    filterTransactions: filterTransactions,
    filterRequests: filterRequests,
    filterListBySearch: filterListBySearch,
    mergeRequests: mergeRequests,
    filterFriendsByType: filterFriendsByType
}
export default SortingService;