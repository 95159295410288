import React from 'react'
import SVGIcon from 'components/svgIcon/SVGIcon'

const IncognitoIcon = (props) => {

    let path = <>
        <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" fill="#274828"/>
        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#E0BAB9"/>
    </>;
    if (props.isincognito === "true") {
        path = <>
            <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" fill="white"/>
            <path d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12" stroke="#274828" strokeWidth="2.5" strokeLinejoin="round"/>
        </>
    }
    return (
        <SVGIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
            <g clipPath="url(#clip0)">
                {path}
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    )
}

export default IncognitoIcon
