import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * PublicRoute is accessible without authentication, if authenticated can resolve a default protected route if needed
 * @component
 * @param {component} component - the page component that the route resolve to
 * @param {component} layout - the layout used to display the component to
 */
const PublicRoute = ({ component: Component, layout: Layout, redirectIfAuth = false, ...rest }) => {
    const { isAuthenticated } = useAuth0();
    const Cmp = (props)=>{
        return (
            !isAuthenticated ? (<Layout><Component {...props} /></Layout>) : (<Redirect to={`/accounts`} />) 
        );
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                return redirectIfAuth ? <Cmp {...props}/> : (<Layout><Component {...props} /></Layout>);
            }
            }
        />
    );
}
export default PublicRoute;