import React, { useState, useContext, useEffect, useRef } from 'react';
import NavigationItem from 'components/navigationItem/NavigationItem'
import queryConfig from 'services/query';
import { useQuery } from "react-query";
import classes from './Sidebar.module.css'

import { AuthContext } from '../../context/auth-context';
import UserAvatar from 'react-user-avatar'

import PlusIcon from 'components/icons/PlusIcon';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import Loading from 'components/UI/Loading/Loading';
import Button from 'components/button/Button';

import { ReactComponent as ExternalLink } from 'assets/images/svg/external-link.svg';
import { ReactComponent as Logo } from 'assets/images/svg/logo-metal.svg';
import Help from './help/Help';
import { CSSTransition } from 'react-transition-group';
// import * as atatus from 'atatus-spa';
import { AppContext } from 'context/app';
import { useAuth0 } from "@auth0/auth0-react";

const Sidebar = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const nodeRef = useRef(null);
    const [name, setName] = useState("-");
    const [customerID, setCustomerID] = useState("-");
    const [profileImage, setProfileImage] = useState("");
    const [company, setCompany] = useState("-");
    //const [language, setLanguage] = useState("en-US");
    const [showHelp, setShowHelp] = useState(false);
    const authContext = useContext(AuthContext);
    let merchantNumber = localStorage.getItem('merchantNumber');
    let queryAccountDetailsConfig = authContext.isMerchant ? queryConfig.getMerchant({ "merchantNumber": merchantNumber }, { refetchOnWindowFocus: false }) : queryConfig.getCustomer({ refetchOnWindowFocus: false });
    const { data: accountDetails, isLoading: isLoadingCustomer } = useQuery(queryAccountDetailsConfig);
    const { data: appIdentity, isLoading: isLoadingIdentity } = useQuery(queryConfig.getIdentityDetails({ refetchOnWindowFocus: false }));
    //const { data: staticData, isLoading: isLoadingStaticData } = useQuery(queryConfig.getStaticData({ refetchOnWindowFocus: false }));
    const { logout } = useAuth0();

    const { setUsername } = useContext(AppContext);

    useEffect(() => {
        if (!isLoadingCustomer) {
            authContext.isMerchant ? setName(`${accountDetails?.Name}`) : setName(`${accountDetails?.FirstName} ${accountDetails?.LastName}`);
            authContext.isMerchant ? setProfileImage(undefined) : setProfileImage(accountDetails?.ProfileImage);
            setCustomerID(accountDetails?.CustomerNumber || accountDetails?.Number);

            //temp
            setUsername(`${accountDetails?.FirstName} ${accountDetails?.LastName}`);
        }
        if (!isLoadingIdentity) {
            setCompany(appIdentity?.BrandName)
        }
        // atatus.setUser(customerID, accountDetails?.EmailAddress, name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingCustomer, isLoadingIdentity, accountDetails, appIdentity])

    const logoutHandler = async () => {
        //authContext.logout(false);
        await logout({ returnTo: window.location.origin });
    }
    /*
    const changeLanguage = e => {
        setLanguage(e.target.value);
        i18n.changeLanguage(e.target.value);
    };
    */
    const toggleHelpHandler = () => {
        setShowHelp(prevState => !prevState);
    }

    const closeHelpHandler = () => {
        setShowHelp(false);
    }

    return <div className="relative">
        <div className={classes.sidebar + " grid content-start bg-money-normal text-metal p-8"}>
            <div style={{ minHeight: "48px" }} className="mb-4 text-charcoal">
                {!isLoadingCustomer ? <UserAvatar size="48" name={name} colors={['#E0BAB9', '#A3AFBF', '#656567']} src={profileImage} className={classes.avatar} /> : null}
            </div>
            <h4 className="name caption mb-1">{`${name} / ID ${customerID}`}</h4>
            <p className="caption mb-16">{company}</p>
            <div className="mb-20 flex flex-col items-start">
                <NavigationItem type="sidebar" link='/accounts' classes={"h3 mb-3 hover:text-white"}>{t('Accounts')}</NavigationItem>
                <div className="flex justify-between items-center mb-3 w-full">
                    <NavigationItem type="sidebar" link='/beneficiaries' classes={"h3 hover:text-white"}>{t('Counterparties')}</NavigationItem>
                    <Button
                        classes={classes.addButton + " flex"}
                        btnTheme="primary"
                        size="small"
                        clicked={() => history.push('/add-beneficiary')} >
                        <PlusIcon size={16} />
                        <p className="caption ml-1">{t('add')}</p>
                    </Button>
                </div>
                <NavigationItem type="sidebar" link='/settings' classes={"h3 hover:text-white"}>{t('Settings')}</NavigationItem>
            </div>
            <div>
                {authContext.isMerchant &&
                    <a href="https://merchants.payset.co.uk/WebSite/login.aspx" target="_blank" rel="noopener noreferrer">
                        <Button
                            classes="p-3 h3 flex items-center justify-between justify-self-start mb-12 bg-pink hover:bg-metal focus:bg-metal text-charcoal active:text-white focus:outline-none active:bg-charcoal" >
                            <span className="semibold mr-2">{t('Merchant panel')}</span>
                            <ExternalLink alt="external-link" />
                        </Button>
                    </a>
                }
            </div>
            <div className="flex flex-col items-start">
                <button className="h3 mb-4 text-left hover:text-white" onClick={toggleHelpHandler}>{t('Help')}</button>
                <button className="h3 mb-4 cursor-pointer text-left hover:text-white" onClick={logoutHandler}>{t('Log out')}</button>
            </div>
            {
                /*
                <Loading condition={isLoadingStaticData}>
                    <select className="text-charcoal" value={language} onChange={(option) => changeLanguage(option)}>
                        {staticData?.Languages ?
                            staticData.Languages.map(language => {
                                return <option key={language.Key} value={language.Key}>{t(language.Name)}</option>
                            }) : <option>No language available</option>
                        }
                    </select>
                </Loading>
                */
            }
            <Logo alt="payset logo" className="self-end" />
        </div>

        <CSSTransition
            in={showHelp}
            timeout={300}
            nodeRef={nodeRef}
            unmountOnExit
            classNames={{
                enter: classes.myHelpEnter,
                enterActive: classes.myHelpEnterActive,
                enterDone: classes.myHelpEnterDone,
                exit: classes.myHelpExit,
                exitActive: classes.myHelpExitActive,
                exitDone: classes.myHelpExitDone,
            }}
        >
            <Help closeHelpHandler={closeHelpHandler} nodeRef={nodeRef} />
        </CSSTransition>

    </div>
}

export default Sidebar;