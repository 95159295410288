
const isNumber = (val) => {
    return !isNaN(+val);
}

const countDecimals = (value) => {
    if (!isNumber(value)) {
        return NaN;
    }

    if (Math.floor(+value) === +value && !value.includes('.')) return 0;
    return value.toString().split(".")[1].length || 0;
}

const avoidScientificNotation = (x) => {
    let e;
    if (Math.abs(x) < 1.0) {
        e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x = x * Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

const toFixed = (value, digits) => {
    if (isNaN(value)) return 0;

    if (digits === 0) {
        return value;
    }

    value = value.toString();
    if (countDecimals(value) >= digits) {
        let integer = value.split('.')[0];
        let decimals = value.split('.')[1].substring(0, digits);
        value = integer + "." + decimals;
        return avoidScientificNotation(value * 1);
    }

    while (value.length > 1 && value.charAt(0) === '0' && value.charAt(1) !== '.') {
        value = value.substring(1);
    }

    return value || 0;
}

let NumberUtils = {
    toFixed: toFixed,
}

export default NumberUtils