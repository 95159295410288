const friendRequests = {
    "d": {
        "Code": 0,
        "IsSuccess": true,
        "Items": [],
        "Key": "Success",
        "Message": "Success",
        "Number": null,
        "__type": "Customer.CustomerFriendList:WebServices"
    }
};
export default friendRequests;
