import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import classes from './NewBeneficiaryForm.module.css';

import fetch from 'services/fetch';
import { ReactComponent as PlusIcon } from 'assets/images/svg/plus-big.svg';
import StepsTabs from 'components/beneficiaries/stepsTabs/StepsTabs';
import Utils from 'services/utils';
import Button from 'components/button/Button';
import { useHistory } from 'react-router-dom';
import NewBeneficiaryInputs from 'components/beneficiaries/newBeneficiaryForm/newBeneficiaryInputs/NewBeneficiaryInputs';
import NewBankInputs from 'components/beneficiaries/newBeneficiaryForm/newBankInputs/NewBankInputs';

var initialValidationSchema = {
    nickname: Yup.string()
        .required('Counterparty name field is required'),
    country: Yup.string()
        .required("Country field is required"),
    bankCountry: Yup.string()
    .required("Bank account country field is required"),
    currency: Yup.string()
        .required("Currency field is required"),
    name: Yup.string()
        .required('Bank account holder name field is required')
}

const NewBeneficiaryForm = ({ className, displaySendTab, setAddedBeneficiarySuccessfully }) => {
    const { t } = useTranslation();
    let history = useHistory();

    const queryClient = useQueryClient();

    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [hasProvidedCounterpartyDetails, setHasProvidedCounterpartyDetails] = useState(false);
    const [isError, setIsError] = useState(false);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [validationSchema, setValidationSchema] = useState(initialValidationSchema);

    const [wantsToChangeDetails, setWantsToChangeDetails] = useState(false);

    const {mutateAsync: mutateAddbeneficiary} = useMutation(fetch("storeBeneficiaryBankAccount"), {
        onSuccess: () => {
            queryClient.invalidateQueries('cbo.paymentMethods.getBeneficiaries')
        }
    });

    const { counterpartyInitialFormValues, bankDetailsInitialFormValues } = Utils;
    const [initialFormValues, setInitialFormValues] = useState({
        ...counterpartyInitialFormValues,
        ...bankDetailsInitialFormValues
    });
    useEffect(()=> {
        if(wantsToChangeDetails) {
            setValidationSchema(initialValidationSchema);
        }
    }, [wantsToChangeDetails])

    const [file, setFile] = useState(null);

    const MyUploadImagePreviewer = props => {
        const handleChange = (event) => {
            let f = event.target.files[0];
            var reader = new FileReader();
            // Closure to capture the file information.
            reader.onload = (function (theFile) {
                return function (e) {
                    var binaryData = e.target.result;
                    //Converting Binary Data to base 64
                    setFile(URL.createObjectURL(f));
                    setInitialFormValues(prevState => ({
                        ...prevState,
                        image: binaryData
                    }))
                };
            })(f);
            reader.readAsDataURL(f);
        }

        let imageSet = null;
        if (file) {
            imageSet = <img src={file} className={classes["upload-picture-preview"]} alt="profile-preview" />
        }
        return (
            <>
                <input className={classes["upload-picture-input"]} onChange={handleChange} type="file" {...props} />
                <div className="grid">
                    <label className={classes["upload-picture-placeholder"] + " cursor-pointer " + (file ? "opacity-0" : "")} htmlFor={props.id}>
                        <PlusIcon width="40" height="40" />
                    </label>
                    {imageSet}
                </div>
                <label className="caption flex mt-4 cursor-pointer" htmlFor={props.id}>{t("labels.UploadPicture")}</label>
            </>
        )
    }

    const closeModalHandler = (event) => {
        event.preventDefault();
        history.goBack();
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object(validationSchema)}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
                setIsBtnLoading(true);
                if (!hasProvidedCounterpartyDetails) {
                    setHasProvidedCounterpartyDetails(true);
                    return;
                }

                if (hasProvidedCounterpartyDetails && !selectedPaymentType) {
                    setIsBtnLoading(false);
                    setIsError(true);
                    return;
                }

                const beneficiaryAdded = await mutateAddbeneficiary({
                    data: {
                        Aba: values.aba,
                        BeneficiaryProfileType: "1",

                        BankAccountName: values.name,
                        Iban: values.iban,
                        SwiftNumber: values.bic,
                        BeneficiaryAddress: values.address,
                        BeneficiaryCountry: values.country,
                        BeneficiaryCity: values.city,
                        BeneficiaryZip: values.zip,

                        BankName: values.bankName,
                        BankAccountNickName: values.nickname,
                        BankAccountNumber: values.accountNumber,
                        // BankAddress1: values.bankAddress,
                        // BankCity: values.bankCity,
                        // PostalCode: values.bankZip,
                        CountryIsoCode: values.bankCountry,
                        CurrencyIsoCode: values.currency,
                        //BankAddress2: "123123",
                        StateIsoCode: values.state,

                        Logo: values.image
                    }
                });

                if (beneficiaryAdded.IsSuccess) {
                    if(displaySendTab) {
                        setAddedBeneficiarySuccessfully(beneficiaryAdded.Number);
                    } else {
                        history.goBack();
                    }
                }
            }}
        >
            {({ errors, touched, values, setValues, setTouched }) => {
                return (
                    <Form className={[classes.form, (className ? className : "")].join(' ')}>

                        <StepsTabs isError={isError}
                            hasProvidedCounterpartyDetails={hasProvidedCounterpartyDetails}
                            setHasProvidedCounterpartyDetails={setHasProvidedCounterpartyDetails}
                            displaySendTab={displaySendTab}
                            setWantsToChangeDetails={setWantsToChangeDetails}
                        />

                        <div className="col-start-2 col-end-3 row-start-1 row-end-last flex flex-col items-center">
                            <MyUploadImagePreviewer name="image" id="upload" />
                        </div>

                        {!hasProvidedCounterpartyDetails ?
                            <NewBeneficiaryInputs
                                touched={touched}
                                errors={errors}
                            />
                            : <NewBankInputs
                                values={values}
                                touched={touched}
                                errors={errors}
                                setInitialFormValues={setInitialFormValues}
                                setValues={setValues}
                                setValidationSchema={setValidationSchema}
                                setTouched={setTouched}
                                selectedPaymentType={selectedPaymentType}
                                setSelectedPaymentType={setSelectedPaymentType}
                                isError={isError}
                                setIsError={setIsError}
                                setIsBtnLoading={setIsBtnLoading}
                            />
                        }

                        {/* {Object.entries(errors).map((error, index) =>{
                            return <p key={index}>{error}</p>
                        })} */}

                        <div className="flex items-center mt-12">
                            <Button
                                classes="py-3 px-4 mr-3"
                                btnTheme="primary"
                                size="normal"
                                type="submit"
                                case="uppercase"
                                isLoading={isBtnLoading}
                            >
                                {!hasProvidedCounterpartyDetails ? t("buttons.provideDetails") : (displaySendTab ? t("buttons.AddCounterpartyWithSend") : t("AddCounterparty"))}
                            </Button>
                            <Button
                                classes="py-3 mr-3"
                                btnTheme="ghost"
                                size="normal"
                                clicked={closeModalHandler}
                                case="uppercase" >
                                {t("Cancel")}
                            </Button>
                        </div>
                    </Form>
                )
            }
            }
        </Formik>
    );
}

export default NewBeneficiaryForm;
