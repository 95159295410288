import React from 'react';
import Loader from 'react-loader-spinner';
import ContentLoader from 'react-content-loader';
// https://skeletonreact.com/

const ListItemLoader = (props) => (
    <ContentLoader
        speed={0.7}
        width={400}
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="60" y="8" rx="3" ry="3" width="198" height="20" />
        <circle cx="22" cy="26" r="22" />
        <rect x="60" y="33" rx="3" ry="3" width="301" height="12" />
    </ContentLoader>
);

const WalletListLoader = (props) => (
    <div className="flex">
        <div className="m-auto">
            <ContentLoader
                speed={1.2}
                width={400}
                height={25}
                viewBox="0 0 400 25"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="8" y="0" rx="3" ry="3" width="100" height="25" />
                <rect x="128" y="0" rx="0" ry="0" width="100" height="25" />
                <rect x="246" y="50" rx="0" ry="0" width="0" height="1" />
            </ContentLoader>
        </div>
    </div>
);

function Loading({ children, condition, type, minHeight, ...rest }) {
    let LoaderElement;
    switch (type) {
        case "listItem":
            LoaderElement = () => {
                return (
                    <ListItemLoader />
                )
            }
            break;
        case "walletList2":
            LoaderElement = () => {
                return (
                    <WalletListLoader />
                )
            }
            break;
        default:
            LoaderElement = () => {
                return (<div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                >
                    <Loader type="Puff" color="#000000" height="30" width="30" />
                </div>)
            }
            break;
    }


    return (
        <>
            {condition ?
                <LoaderElement />
                :
                children
            }
        </>
    );
}

export default Loading;