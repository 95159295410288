import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import queryConfig from 'services/query';
import Utils from 'services/utils';
import SortingService from 'services/sortingService';
import Button from 'components/button/Button';
import { AuthContext } from 'context/auth-context';

import BeneficariesSorted from 'components/beneficiaries/beneficiariesSorted/BeneficiariesSorted';

import PlusIcon from 'components/icons/PlusIcon';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/checked-icon.svg';

import { useTranslation } from 'react-i18next';
import Search from 'components/search/Search';

var isLoadingLists = true;

const Beneficiaries = () => {
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    let history = useHistory();

    const [sortedFriendList, setSortedFriendList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [showFriendsOnly, setShowFriendsOnly] = useState(false);
    let queryFriendsConfig = queryConfig.getFriends();
    queryFriendsConfig.enabled = !authContext.isMerchant;
    const { data: friendsList, isLoading: isLoadingFriendsList } = useQuery(queryFriendsConfig);
    const { data: beneficiariesList, isLoading: isLoadingBeneficiaryList } = useQuery(queryConfig.getBeneficiaries());

    const goToDetails = (friendInfo) => {
        history.push({
            pathname: '/beneficiary-details/' + friendInfo.friendId,
            search: '?isInternal=' + friendInfo.isInternal
        });
    };

    useEffect(() => {
        let allFriends = [];
        if (!isLoadingFriendsList && !isLoadingBeneficiaryList) {
            if(authContext.isMerchant) {
                allFriends = Utils.getAllFriends({ Items: [] }, beneficiariesList);
            } else {
                allFriends = Utils.getAllFriends(friendsList, beneficiariesList);
            }
            isLoadingLists = false;

            allFriends = SortingService.sortAllFriendsByFirstLetter(allFriends);

            let searchedFriends = SortingService.filterAllFriends(allFriends, searchValue);

            let filteredFriends = [];
            if (showFriendsOnly) {
                filteredFriends = SortingService.filterFriendsByType(searchedFriends, "isInternal")
            } else {
                filteredFriends = SortingService.filterFriendsByType(searchedFriends, "");
            }

            setSortedFriendList(filteredFriends);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingFriendsList, friendsList, beneficiariesList, isLoadingBeneficiaryList, searchValue, showFriendsOnly])

    const filterFriends = (evt) => {
        setShowFriendsOnly(evt.target.checked);
    }

    return <div className="friends">

        <div className="friends-header">
            <h2 className="semibold mb-11">{t("Counterparties")}</h2>
            <div className="friends-header-btn flex justify-between items-center mb-11">
                <div className="flex p-3 bg-white">
                    <input type="checkbox" id="filterFriends" value="" className='checkbox-input' onChange={filterFriends} />
                    <label htmlFor="filterFriends" className="checkbox mr-2">
                        <CheckedIcon />
                    </label>
                    <label htmlFor="filterFriends" className="caption cursor-pointer">{t("labels.friendsOnly")}</label>
                </div>
                <div className="flex items-center">
                    <Search searchValue={searchValue} setSearchValue={setSearchValue} />
                    <Button
                        classes="flex ml-6"
                        btnTheme="primary"
                        size="small"
                        clicked={() => history.push('/add-beneficiary')} >
                        <PlusIcon size={16} />
                        <p className="caption ml-1">{t('add')}</p>
                    </Button>
                </div>
            </div>
        </div>
        <BeneficariesSorted isLoading={isLoadingLists} isInPicker={false} hideAlphabetList={true} onSelect={(friend) => goToDetails(friend)}>
            {sortedFriendList}
        </BeneficariesSorted>
    </div>
}

export default Beneficiaries;