import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AppLoader from "components/appLoader/AppLoader";

/**
 * PrivateRoute takes user to login if not authenticated yet but tries to access a protected route
 * @component
 * @param {component} component - the page component that the route resolve to
 * @param {component} layout - the layout used to display the component to
 */
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const Cmp = (props) => {
    return (
      <Layout><Component {...props}/></Layout>
    );
  }
  return (
    <Route
      component={withAuthenticationRequired(Cmp, {
        onRedirecting: () => <AppLoader />,
      })}
      {...rest}
    />
  );
}

export default PrivateRoute;