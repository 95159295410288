import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from "react-query";
import queryConfig from 'services/query';
import { AppContext } from 'context/app';
import { useTranslation } from 'react-i18next';

import NavigationItem from "../navigationItem/NavigationItem"
import Utils from 'services/utils';
import IncognitoIcon from 'components/icons/IncognitoIcon';
import Button from 'components/button/Button';
import Tab from 'components/tab/Tab';
import { ReactComponent as Send } from 'assets/images/svg/send-icon.svg';
import { ReactComponent as Exchange } from 'assets/images/svg/exchange-icon.svg';

const Header = React.memo(() => {
    const [total, setTotal] = useState(0);
    const { t } = useTranslation();
    const { incognito, setIncognito } = useContext(AppContext)
    const { data: walletList } = useQuery(queryConfig.getTotal());
    const { data: currencyRates } = useQuery(queryConfig.getCurrencyRates());

    useEffect(() => {
        if (walletList && currencyRates) {
            setTotal(Utils.getUserBalance(currencyRates, walletList));
        }
    }, [walletList, currencyRates]);

    const onClickHandler = () => {
        setIncognito(!incognito);
    }

    return (
        <>
            <div className="header">
                <div className="flex items-center">
                    <p className="caption flex">{t('Total Balance')}</p>
                    <Button
                        classes='ml-3'
                        clicked={onClickHandler} >
                        <IncognitoIcon isincognito={incognito.toString()} />
                    </Button>
                </div>

                <div className="flex justify-between items-center">
                    <h2 style={incognito ? { filter: 'blur(10px)' } : null} className="big-font mono">${total}</h2>

                    <div className="flex justify-end">
                        <NavigationItem link='/send' classes={"cursor-pointer caption"} >
                            <Tab label={t("Send")} >
                                <Send />
                            </Tab>
                        </NavigationItem>
                        <NavigationItem link='/exchange' classes={"cursor-pointer caption"} >
                            <Tab label={t("Exchange")} >
                                <Exchange />
                            </Tab>
                        </NavigationItem>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Header
