import React from "react";
import {Switch, Route, useHistory} from "react-router-dom";
import AccessLog from './accessLog/AccessLog';
import NavigationItem from 'components/navigationItem/NavigationItem';
import UpdatePasswordModal from "./updatePasswordModal/UpdatePasswordModal";
import UpdatePinModal from "./updatePinModal/UpdatePinModal";
import ToggleSwitch from './toggleSwitch/ToggleSwitch';
import {toast} from "react-toastify";
import NotificationContent from 'components/notifications/NotificationContent';

import classes from "./Security.module.css";

const Security = (props) => {
  let history = useHistory();
  const basePath = props.path || '/settings/security';

  const closeModal = () => {
    history.push(basePath);
  };
  const optSwitch = (checked) => {
      toast(
          <NotificationContent
            type="success"
            description={checked ? "One Time Password has been successfully enabled" : "One Time Password has been successfully disabled"} />,
        { className: 'white-toast default-toast' }
      )
  };

  return (
    <>
        <div className={classes["container"]}>
            <div className="col-start-2 col-end-last row-start-1 row-end-last flex flex-col items-center"/>
            <div className={classes["group"]}>
            <p className={"text-desaturate-light"}>Password</p>
            <p className="">••••••••</p>
            <p><NavigationItem link={`${basePath}/update/password`} classes={"cursor-pointer caption"}>Update</NavigationItem></p>
            </div>
            <div className={classes["group"]}>
            <p className={"text-desaturate-light"}>PIN code</p>
            <p className="">••••</p>
            <p><NavigationItem link={`${basePath}/update/pin`} classes={"cursor-pointer caption"}>Update</NavigationItem></p>
            </div>
        </div>
        <div className={classes["otp"]}>
            <p className={classes["otp-header"]}>One Time Password</p>
            <div className={classes["otp-note"]}>The code you receive on your mobile to access your account.</div>
            <div className={classes["otp-switch"]}>
            <ToggleSwitch onChange={optSwitch} />
            </div>
        </div>
        <div className={classes["al"]}>
            <h3 className={classes["al-header"]}>Access Log</h3>
            <AccessLog />
        </div>
        <Switch>
            <Route path={`${basePath}/update/password`}><UpdatePasswordModal closeModal={closeModal}/></Route>
            <Route path={`${basePath}/update/pin`}><UpdatePinModal closeModal={closeModal} /></Route>
        </Switch>
    </>
  )
};

export default Security;
