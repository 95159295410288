import React from 'react';

import UserAvatar from 'react-user-avatar';
import classes from './BeneficiaryCard.module.css';
import PlusIcon from 'components/icons/PlusIcon';

import { ReactComponent as PaysetLogo } from 'assets/images/svg/payset-logo.svg';
import { ReactComponent as FaceIcon } from 'assets/images/svg/happy-face-icon.svg';

const BeneficiaryCard = (props) => {
    let showLogo = null;
    let propsStyle = classes.friendCard + " " +
        (props.cardType ? classes[props.cardType] : "") + 
        " " + (props.classes ? props.classes : "") +
        " " + (props.isAddButton ? classes["isAddButton"] : "" +
        " " + (props.disabled ? classes['disabled']: ""));

    if (props.value?.isInternal) {
        showLogo = <PaysetLogo alt="payset-logo" className={classes.logo} />;
    }

    let pictureElem = <PlusIcon size={24} />;
    if (props.value?.pictureType === "profile") {
        pictureElem = <UserAvatar
            size="40"
            name={props.value.fullName}
            colors={['#E0BAB9', '#A3AFBF', '#656567']}
            src={props.value.profileImage} />
    } else if(props.value?.pictureType === "face") {
        pictureElem = <FaceIcon alt="face"/>
    }

    return (
        <button className={propsStyle + " cursor-pointer"} onClick={props.click}>
            {pictureElem}
            <p className={classes.friendName + " ml-3 caption"}>{props.value.fullName}</p>
            {showLogo}
        </button>
    )
}

export default BeneficiaryCard
