import React, { useState } from 'react';

import NewBeneficiaryForm from 'components/beneficiaries/newBeneficiaryForm/NewBeneficiaryForm';
import CounterpartyTabs from 'components/counterpartyTabs/CounterpartyTabs';
import NewFriendForm from 'components/newFriendForm/NewFriendForm';

const AddBeneficiary = () => {
    const [isInternalTab, setIsInternalTab] = useState(false);
    /*eslint-disable-next-line*/
    const [newFriendId, setNewFriendId] = useState(null);
    
    return (
        <div>
            <h1 className="mb-8">Add counterparty</h1>
            <CounterpartyTabs
                isInternalTab={isInternalTab}
                setIsInternalTab={setIsInternalTab}
                setNewFriendId={setNewFriendId}
            />

            {isInternalTab ?
                <NewFriendForm setNewFriendId={setNewFriendId}/>
                :
                <NewBeneficiaryForm className="mt-10" />
            }

        </div>
    )
}

export default AddBeneficiary;
