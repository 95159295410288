import Button from 'components/button/Button'
import React from 'react'
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/images/svg/close-icon.svg';

import classes from './Help.module.css'

const Help = (props) => {
    const { t } = useTranslation();
    return <>
        <div className={["grid content-start p-8", classes["help-container"]].join(' ')} ref={props.nodeRef}>
            <Button
                classes={classes.close + ' focus:outline-none'}
                clicked={props.closeHelpHandler} >
                <CloseIcon alt="close" width="32" height="32" />
            </Button>
            <h1 className="mb-8">{t("Help")}</h1>
            <img src="/images/shai.png" alt="profile" className="mb-3" />
            <p>{t("help.name")}</p>
            <p className="caption mb-6 text-metal">{t("help.text")}</p>
            <a className="mb-4" href={`tel:${t("help.phoneNumber")}`}>
                {t("help.phoneNumber")}
            </a>
            <a className="mb-8" href={`mailto:${t("help.email")}`}>
                {t("help.email")}
            </a>
            <div className="grid py-8 border-t border-desaturate-normal border-b mb-8">
                <p className="caption text-metal mb-2">{t("help.weekHours")}</p>
                <p className="caption text-metal">{t("help.weekendHours")}</p>
            </div>
            <p className="caption text-metal">{t("help.labelPhone1")}</p>
            <a className="mb-6" href={`tel:${t("help.phone1")}`}>
                {t("help.phone1")}
            </a>
            <p className="caption mb-2 text-metal">{t("help.labelPhone2")}</p>
            <a className="mb-8" href={`tel:${t("help.phone2")}`}>
                {t("help.phone2")}
            </a>
            <a className="pt-8 border-t border-desaturate-normal mb-4" href={`mailto:${t("help.paysetEmail")}`}>
                {t("help.paysetEmail")}
            </a>
            <p>{t("help.onlineChat")}</p>
        </div>
    </>
}

export default Help
