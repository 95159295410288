import React from 'react'

const SVGIcon = (props) => {
    return (
        <svg {...props}>
            {props.children}
        </svg>
    )
}

export default SVGIcon
