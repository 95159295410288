import React from 'react'
import classes from './PageLayout.module.css'
import Sidebar from 'components/sidebar/Sidebar';

const PageLayout = (props) => {
    return (
        <div className={classes.container}>
            <Sidebar />
            <main className={classes.mainContent}>
                {props.children}
            </main>
        </div>
    )
}

export default PageLayout;