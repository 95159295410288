import React from 'react';

import classes from './Tab.module.css';
// TODO we do not need this piece of code

const Tab = (props) => {
    return (
        <div className={classes.tab + " flex items-center hover:bg-white focus:bg-metal focus:outline-none"}>
            {props.children}
            <p className="caption ml-2">{props.label}</p>
        </div>
    )
}

export default Tab;
