import React, { useContext } from 'react'
import { useQuery } from "react-query";
import queryConfig from 'services/query';
import Table from "./Table";
import { AuthContext } from 'context/auth-context';

const AccessLog = () => {
    const { isMerchant } = useContext(AuthContext);
    const userDataFetchFunction = isMerchant ? queryConfig.getMerchant : queryConfig.getCustomer;
    const userIdFieldName = isMerchant ? 'Number' : 'CustomerNumber';

    let merchantNumber = localStorage.getItem('merchantNumber');
    const { data: customerData, isLoading: isCustomerLoading } = useQuery(userDataFetchFunction({ "merchantNumber": merchantNumber }, { refetchOnWindowFocus: false }))
    const customerNumber = customerData ? customerData[userIdFieldName] : null;
    const { isLoading, data } = useQuery(queryConfig.getAccessLog({ customerNumber, isMerchant }));

    const columns = React.useMemo(
        () =>
            [
                {
                    Header: 'Flag',
                    accessor: (row) => {
                        return <div className="currency-flag currency-flag-gbp"></div>;
                    },
                },
                {
                    Header: 'Country',
                    accessor: (row) => {
                        return "Great Britain";
                    },
                },
                {
                    Header: 'IP Address',
                    accessor: 'ipAddress',
                },
                {
                    Header: 'Date',
                    accessor: (row) => {
                        const utcDate = new Date(row.createdAt);
                        return utcDate.toUTCString();
                    },
                },
                {
                    Header: 'Source Info',
                    accessor: 'sourceInfo',
                }
            ],
        []
    )

    const tableData = React.useMemo(() => data?.loginHistoryRecords?.filter(record => record.createdAt != null) || [], [data])

    return <>
        {(isLoading || isCustomerLoading) ? `Loading history...` : (
            <Table columns={columns} data={tableData} />
        )}
    </>
}

export default AccessLog

