import NumberUtils from './numberUtils';

const accountCurrency = "USD";
const CODES = {
    "USD": "$",
    "EUR": "€",
    "CAD": "C$",
    "ILS": "₪",
    "GBP": "£",
    "INR": "₹",
    "AED": "د.إ",
    "SGD": "S$"
}

const notificationType = {
    'send': 'send',
    'topUp': 'topUp',
    'request': 'request',
    'exchange': 'exchange',
    'error': 'error',
    'success': 'success',
    'information': 'information'
}

// maximum friends to be shown on accounts page
const maxFriendsListNumber = 11;

const alphabetLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "#"];

//group all names that are starting with other letter than 'alphabetLetters' under this symbol
const otherSymbol = "#";

const transactionInitialFormValues = {
    currency: "",
    description: "",
    amount: ""
}

const addBeneficiaryInitialFormValues = {
    name: "",
    iban: "",
    address: "",
    country: "",
    city: "",
    zip: "",
    bankName: "",
    bankCode: "",
    bankAddress: "",
    bankCountry: "",
    transactionCurrency: "",
    bankCity: "",
    bankZip: "",
    image: ""
}

const counterpartyTypesEnum = {
    "individual": "individual",
    "bussiness": "company"
}

const paymentTypesEnum = {
    "1": "priority",
    "2": "regular"
}

//first form values, before sending requesting rules from currencyCloud
const counterpartyInitialFormValues = {
    nickname: "",
    country: "",
    bankCountry: "",
    currency: "",
    name: "",
    counterpartyType: "bussiness",
    image: ""
}

//second form values, after getting the rules
const bankDetailsInitialFormValues = {
    aba: "",
    bic: "",
    accountNumber: "",
    sortCode: "",
    iban: "",
    state: undefined,
    address: "",
    city: "",
    zip: "",
    bankName: "",
    bankCode: "",
    branchCode: ""
}

//mapping the key names from currencyCloud object with our form values name
const rulesMap = {
    "aba": "aba",
    "bic_swift": "bic",
    "acct_number": "accountNumber",
    "beneficiary_address": "address",
    "beneficiary_city": "city",
    "beneficiary_country": "country",
    "beneficiary_entity_type": "counterpartyType",
    "beneficiary_postcode": "zip",
    "beneficiary_state_or_province": "state",
    "iban": "iban",
    "payment_type": "paymentType",
    "sort_code": "sortCode",
    "bank_code": "bankCode",
    "branch_code": "branchCode"
}

//keys from this object should match with keys from translation file - 'checkboxLabel' object
const rulesLabel = {
    "aba": "aba",
    "iban": "iban",
    "bankCode": "bankCode",
    "branchCode": "branchCode",
    "bic": "bic",
    "accountNumber": "accountNumber",
    "address": "address",
    "sortCode": "sortCode",
}

const getCurrencySign = (type, currency) => {
    if (type === "code") {
        return currency;
    } else if (type === "sign") {
        let currencyCode = currency?.split('-')[0];
        let sign = CODES[currencyCode] ?? "";
        return sign;
    }
}

const getUserBalance = (currencyRates, walletList) => {
    let accountCurrencyExchange = currencyRates.find((item) => {
        return item.CurrencyIsoCode === accountCurrency;
    });
    accountCurrencyExchange = accountCurrencyExchange?.BaseRate ? accountCurrencyExchange?.BaseRate : 1;
    let currencyExchanges = walletList.map((value, index) => {
        let currentExchange = currencyRates.find((item) => {
            return item.CurrencyIsoCode === value.CurrencyIso;
        });
        currentExchange = currentExchange?.BaseRate ? currentExchange?.BaseRate : 1;
        return currentExchange / accountCurrencyExchange * value.Expected;
    });
    return Math.round((currencyExchanges.reduce((a, b) => a + b, 0) + Number.EPSILON) * 100) / 100;
}

const getLowBalanceWallet = (walletList, currencyRates) => {
    let accountCurrencyExchange = currencyRates.find((item) => {
        return item.CurrencyIsoCode === accountCurrency;
    });
    accountCurrencyExchange = accountCurrencyExchange?.BaseRate ? accountCurrencyExchange?.BaseRate : 1;
    let indexesWithLowBalance = [];
    let hasLowBalance = walletList.some((wallet, index) => {
        let walletExchangeRate = currencyRates.find((item) => {
            return item.CurrencyIsoCode === wallet.CurrencyIso;
        });
        walletExchangeRate = walletExchangeRate?.BaseRate ? walletExchangeRate?.BaseRate : 1;
        let amount = walletExchangeRate / accountCurrencyExchange * wallet.Expected;
        if (amount < 1) {
            indexesWithLowBalance.push(index);
            return true;
        }
        return false;
    });
    return {
        hasLowBalance,
        indexesWithLowBalance
    };
}

const getExchangeRate = (fromCurrency, toCurrency, exchangeRates) => {
    if (fromCurrency && toCurrency) {
        let fromExchangeRate = exchangeRates.find(item => item.CurrencyIsoCode === fromCurrency)?.BaseRate;
        let toExchangeRate = exchangeRates.find(item => item.CurrencyIsoCode === toCurrency)?.BaseRate;
        return NumberUtils.toFixed(fromExchangeRate / toExchangeRate, 2);
    }
}

const calculateResult = (amount, amountCurrency, resultCurrency, exchangeRates) => {
    let baseExchangeRate = exchangeRates.find(item => item.CurrencyIsoCode === amountCurrency).BaseRate;
    let quoteExchangeRate = exchangeRates.find(item => item.CurrencyIsoCode === resultCurrency).BaseRate;
    let result = baseExchangeRate / quoteExchangeRate * amount;
    return NumberUtils.toFixed(result, 2);

}

const getHour = (insertedDate) => {
    // inserted date format /Date(1593594283000+0000)/
    let date = insertedDate.split('(')[1].split(')')[0].split('+')[0];
    const lang = navigator.language || navigator.languages[0];
    let [hour, minute] = (new Date(+date)).toLocaleTimeString(lang, { hour12: false }).slice(0, 7).split(":")
    return `${hour}:${minute}`;
}

const getDate = (insertedDate) => {
    // inserted date format /Date(1593594283000+0000)/
    let date = insertedDate.split('(')[1].split(')')[0].split('+')[0];
    return new Date(+date).toLocaleDateString();
}

const getDisplayAmount = (value, currency) => {
    let result = '';
    if (value > 0) {
        result = `${getCurrencySign("sign", currency)}${value?.toString()}`
    } else {
        result = `-${getCurrencySign("sign", currency)}${value?.toString().split('-')[1]}`
    }
    return result;
}

const beautifyDate = (date) => {
    let today = new Date().toLocaleDateString();
    if (date === today) {
        return "Today";
    }

    let yesterday = (d => new Date(d.setDate(d.getDate() - 1)).toLocaleDateString())(new Date());
    if (date === yesterday) {
        return "Yesterday";
    }

    let months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let [day, month, year] = date.split("/");
    return `${day} ${months[+month]} ${year}`
}

const getAllFriends = (friendsList, beneficiariesList) => {
    let allFriends = [];
    friendsList.Items.forEach((elem) => {
        allFriends.push({
            fullName: elem.FullName,
            profileImage: elem.ProfileImage,
            isInternal: true,
            pictureType: "profile",
            friendId: elem.DestWalletId
        });
    })
    beneficiariesList.forEach((elem) => {
        let base64Image = elem.LogoAsBytes ? "data:image/png;base64," + btoa(String.fromCharCode.apply(null, new Uint8Array(elem.LogoAsBytes))) : undefined;
        allFriends.push({
            fullName: elem.NickName,
            profileImage: base64Image,
            isInternal: false,
            pictureType: "profile",
            friendId: elem.BankAccount_id
        });
    })
    return allFriends;
}

const getLoginURL = (isMerchant) => {
    let url = "/login";
    if (isMerchant) {
        url += "-merchant";
    }
    return url;
}

const getRulesByType = (rules, counterpartyType) => {
    let filteredRules = rules.filter(item => item.counterpartyType === counterpartyTypesEnum[counterpartyType]);
    let priority = [], regular = [];
    filteredRules.forEach(item => {
        if (item.paymentType === paymentTypesEnum[1]) {
            priority.push(item);
        } else {
            regular.push(item);
        }
    });

    return {
        priority: priority,
        regular: regular
    }
}

const mapRules = (rawRules) => {
    let mappedRules = [];

    rawRules.forEach((rule, index) => {
        let tempRule = {};
        Object.entries(rule).forEach(([key, value]) => {
            if (rulesMap[key]) {
                tempRule[rulesMap[key]] = value;
            } else {
                tempRule[key] = value;
            }
        })
        mappedRules.push(tempRule);
    })

    return mappedRules;
}

let Utils = {
    getCurrencySign: getCurrencySign,
    currencyCodes: CODES,
    notificationType: notificationType,
    getUserBalance: getUserBalance,
    getExchangeRate: getExchangeRate,
    calculateResult: calculateResult,
    getHour: getHour,
    getDisplayAmount: getDisplayAmount,
    getDate: getDate,
    beautifyDate: beautifyDate,
    getAllFriends: getAllFriends,
    maxFriendsListNumber: maxFriendsListNumber,
    alphabetLetters: alphabetLetters,
    getLoginURL: getLoginURL,
    getLowBalanceWallet: getLowBalanceWallet,
    transactionInitialFormValues: transactionInitialFormValues,
    addBeneficiaryInitialFormValues: addBeneficiaryInitialFormValues,
    counterpartyInitialFormValues: counterpartyInitialFormValues,
    bankDetailsInitialFormValues: bankDetailsInitialFormValues,
    getRulesByType: getRulesByType,
    mapRules: mapRules,
    paymentTypesEnum: paymentTypesEnum,
    otherSymbol: otherSymbol,
    rulesLabel: rulesLabel
}
export default Utils;