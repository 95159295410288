import React from "react";
import Modal from 'components/modal/Modal';
import UpdatePinForm from "../updatePinForm/UpdatePinForm";

const UpdatePinModal = (props) => {

  return <Modal closeModal={props.closeModal}>
    <h1 className="mb-8">PIN code update</h1>
    <UpdatePinForm {...props} />
  </Modal>
};

export default UpdatePinModal;