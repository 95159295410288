import React from 'react';

import classes from './NotificationContent.module.css';
import {ErrorIcon, ExchangeIcon, RequestIcon, InformationIcon, SendIcon, SuccessIcon, TopUpIcon} from 'components/notifications/notificationIcon/NotificationIcon';
import Utils from 'services/utils';

const NotificationContent = (props) => {
    let values = props.data?.values ? props.data.values : props.data;
    let title, description, icon, name = '';

    switch(props.type) {
        case Utils.notificationType.send:
            title = "Funds sent.";
            if(props.data.selectedFriend.fullName) {
                name = `to ${props.data.selectedFriend.fullName}`;
            }
            description = `You've sent ${Utils.currencyCodes[values.currency]}${values.amount} ${name}`;
            icon = <SendIcon />;
            break;
        case Utils.notificationType.exchange:
            title = "Money exchanged.";
            description = `You've changed ${Utils.currencyCodes[values.fromCurrency]}${values.fromAmount}
                to ${Utils.currencyCodes[values.toCurrency]}${values.toAmount}`;
            icon = <ExchangeIcon />;
            break;
        case Utils.notificationType.request:
            title = "Funds requested.";
            if(props.data.selectedFriend.fullName) {
                name = `from ${props.data.selectedFriend.fullName}`;
            }
            description = `You've requested ${Utils.currencyCodes[values.currency]}${values.amount} ${name}`;
            icon = <RequestIcon />;
            break;
        case Utils.notificationType.topUp:
            title = "Account top up.";
            icon = <TopUpIcon />;
            break;
        case Utils.notificationType.error:
            title = "Error.";
            description = props.description ? props.description : null ;
            icon = <ErrorIcon />;
            break;
        case Utils.notificationType.success:
            title = "Success.";
            description = props.description ? props.description : null ;
            icon = <SuccessIcon />;
            break;
        case Utils.notificationType.information:
            title = "Info.";
            description = props.description ? props.description : null ;
            icon = <InformationIcon />;
            break;
        default:
            break;
    }

    return (
        <div className={classes.wrapper + ' small'}>
            <div className={classes.titleWrapper}>
                {icon}
                <span className={classes.title}>{title = title || ''}</span>
            </div>
            <span className={classes.description}>{description = description || ''}</span>
        </div>
    )
}

export default NotificationContent;
