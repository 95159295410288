import React, { useEffect, useRef, useState } from 'react'

import classes from './Pincode.module.css';

import { ReactComponent as LoadingIcon } from 'assets/images/svg/loading.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/svg/error.svg';
import { ReactComponent as InfoIcon } from 'assets/images/svg/info.svg';

const Pincode = ({ setShowPincode, errorMsg, setErrorMsg, onPinEntered, onPinSucess, onPinFail, className, onCallFail }) => {

    const inputNode = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [pinValue, setPinValue] = useState("");

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if(errorMsg) {
            setPinValue("");
            inputNode.current.focus();
        }
    }, [errorMsg])

    const handleKeyPress = (evt) => {
        //TODO rethink
        if (evt.key === "Escape") {
            setShowPincode(false);
            setPinValue("");
            setErrorMsg("");
        }
    }

    const wait = (ms) => {
        return new Promise(function(resolve, reject) { 
            setTimeout(resolve, ms); 
        }); 
    }

    const onChangeHandler = async (evt) => {
        let value = evt.target.value;
        if (value !== "") {
            let charCode = value[value.length - 1].charCodeAt(0);
            //check if last entered value is a number
            if (charCode >= 48 && charCode <= 57) {
                setPinValue(value);
                if (value.length === 4) {
                    inputNode.current.blur();
                    setIsLoading(true);
                    setErrorMsg("");
                    const [data] = await Promise.all([onPinEntered(value), wait(700)]);
                    setIsLoading(false);
                    if (data.IsSuccess) {
                        onPinSucess(data);
                    } else {
                        if(data.Key === "PinCodeConfirmationMissmatch") {
                            onPinFail(data.Message);
                            return;
                        }
                        onCallFail(data.Message);
                    }
                }
            } else {
                //remove last entered value if it is not a number
                let oldValue = value.slice(0, -1);
                setPinValue(oldValue);
            }
        } else {
            setPinValue("");
        }
    }
    return <>
        <div id="pinCode" className={[classes["pin-container"], (className ? className : "")].join(' ')}>
            <p className="caption text-desaturate-light mb-3">PIN code:</p>
            <div className="flex justify-between items-center">
                <div className="grid">
                    <div className={classes["placeholder"]}>
                        <span>&middot;</span>
                        <span>&middot;</span>
                        <span>&middot;</span>
                        <span>&middot;</span>
                    </div>
                    <input className={classes["password"]}
                        ref={inputNode}
                        autoFocus={true}
                        onChange={onChangeHandler}
                        value={pinValue}
                        type="text"
                        minLength="4"
                        maxLength="4"
                        pattern="[0-9]*"
                        inputMode="numeric"
                    />
                </div>
                {isLoading && <LoadingIcon className={["animate-spin", classes["loading-icon"]].join(' ')} />}
                {errorMsg && <ErrorIcon />}
            </div>
            <div className={classes["info-container"]}>
                <InfoIcon className={classes["info-icon"]}/>
                <p className={["caption", classes["info-text"]].join(' ')}>
                    Please enter you PIN code. If you don’t <br/>
                    remember your PIN code please click <br/>
                    “Help” and contact the customer support.
                </p>
            </div>
        </div>
        {errorMsg && <p className="caption mt-3 text-reor">{errorMsg}</p>}
    </>
}

export default Pincode
