const balanceTotal = {
    "d": [
        {
            "CurrencyIso": "USD",
            "Current": 99,
            "Expected": 1,
            "Pending": 2
        },
        {
            "CurrencyIso": "EUR",
            "Current": 93.5,
            "Expected": 1,
            "Pending": 2
        }
    ]
};
export default balanceTotal;