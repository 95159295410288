import React, {useState, useRef} from 'react'
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import Button from "components/button/Button";
import InputField from 'components/inputField/InputField';
import {useMutation} from 'react-query';
import fetch from 'services/fetch';
import {toast} from "react-toastify";
import NotificationContent from 'components/notifications/NotificationContent';
import Pincode from 'components/pincode/Pincode'

const UpdatePinForm = (props) => {
  const closeModalHandler = props.closeModal;
  const [step, setStep] = useState(1);
  const [passwordError, setPasswordError] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [pinNewError, setPinNewError] = useState("");
  const [pinConfirmError, setPinConfirmError] = useState("");
  const formRef = useRef();
  const {mutateAsync: updatePincode} = useMutation(fetch('updatePincode'));

  const onPinNewSuccess = () => {
    nextStep();
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const onPinNewFail = (error) => {
    setPinNewError(error);
  };

  const onPinNewEntered = (value) => {
    setPinCode(value);
    return {IsSuccess: true};
  };

  const onPinConfirmSuccess = () => {
    closeModalHandler();
    toast(<NotificationContent type="success" description={"Your PIN code has been successfully updated"}/>, {
      className: 'white-toast default-toast'
    });
  };

  const onPinConfirmFail = (error) => {
    setStep(1);
    setPasswordError(error);
  };

  const onCallFail = (error) => {
    setStep(1);
    setPasswordError(error);
  }

  const onPinConfirmEntered = async (value) => {
    if (pinCode === value) {
      setPasswordError(null);
      if (formRef.current) {
        return await formRef.current.submitForm();
      }
    }
    return {IsSuccess: false, Key: "PinCodeConfirmationMissmatch", Message: "PIN code doesn't match"}
  };

  return (
    <Formik innerRef={formRef}
            initialValues={
              {
                password: ''
              }
            }
            validationSchema={Yup.object({
              password: Yup.string()
                .required('Password is required')
            })}
            onSubmit={async (values, {setSubmitting}) => {
              setSubmitting(false);
              return await updatePincode({
                password: values.password,
                newPincode: pinCode
              });
            }}
    >
      {({errors, touched, isSubmitting}) => (
        <div className="">
          {step === 1 && <div>To update the PIN code please enter your password.</div>}
          {step === 2 && <div>Enter your new PIN code.</div>}
          {step === 3 && <div>Confirm your new PIN code.</div>}
          <Form>
            {step === 1 && <div className="mb-6">
              <InputField classes="border-b border-metal" type="text" name="password" placeholder="Your password">
                Password
              </InputField>
              {touched.password && (errors.password || passwordError) &&
              <div className="text-reor">{errors.password || passwordError}</div>}
            </div>}
            {step === 2 && <div className="mb-6">
              <Pincode isError={false}
                       errorMsg={errors.pin_new || pinNewError}
                       setErrorMsg={setPinNewError}
                       onPinEntered={onPinNewEntered}
                       onPinSucess={onPinNewSuccess}
                       onPinFail={onPinNewFail}
              /></div>}
            {step === 3 && <div className="mb-6">
              <Pincode isError={false}
                       errorMsg={errors.pin_confirm || pinConfirmError}
                       setErrorMsg={setPinConfirmError}
                       onPinEntered={onPinConfirmEntered}
                       onPinSucess={onPinConfirmSuccess}
                       onPinFail={onPinConfirmFail}
                       onCallFail={onCallFail}
              /></div>}

            <div className="flex items-center">
              <Button
                classes="py-3 mr-3"
                btnTheme="ghost"
                size="normal"
                clicked={closeModalHandler}
                case="uppercase">
                Cancel
              </Button>
              {step === 1 && <Button
                type="submit"
                btnTheme="primary"
                size="normal"
                clicked={nextStep}
                case="uppercase">
                <p className="small semibold">Next</p>
              </Button>}
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
};

export default UpdatePinForm
