import React, {useState} from 'react';
import Modal from 'components/modal/Modal';

import Received from 'components/smallModals/Received/Received';
import Exchanged from 'components/smallModals/Exchanged/Exchanged';
import Sent from 'components/smallModals/Sent/Sent';
import RequestedFriend from 'components/smallModals/RequestedFriend/RequestedFriend';
import RequestedMoney from 'components/smallModals/RequestedMoney/RequestedMoney';
import Enums from 'enums/Enums';

const transactionComponents = {
  [Enums.transactionType.MoneyIn]: Received,
  [Enums.transactionType.MoneyOut]: Sent,
  [Enums.transactionType.Exchange]: Exchanged
};

const requestComponents = {
  [Enums.requestType.Friend]: RequestedFriend,
  [Enums.requestType.Money]: RequestedMoney
};

const OpenModal = (props) => {

  const [transaction] = useState(props.transaction);
  const [request] = useState(props.request);

  const ModalComponent = transaction?.type in transactionComponents ? transactionComponents[transaction.type] :
    request?.category in requestComponents ? requestComponents[request.category] : null;

  return ModalComponent ? <Modal closeModal={props.closeModal}><ModalComponent {...props} /></Modal> : null
};

export default OpenModal;