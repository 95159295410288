import React, { createRef } from 'react';
import Loading from 'components/loading/Loading';
import BeneficiaryCard from 'components/beneficiaries/beneficiaryCard/BeneficiaryCard';

import handleViewport from 'react-in-viewport';

import classes from './BeneficiariesSorted.module.css'
import Utils from 'services/utils';

const BeneficariesSorted = (props) => {
    const refs = props.children.reduce((acc, value) => {
        acc[value.letter] = createRef();
        return acc;
    }, {});

    const letterRefs = props.children.reduce((acc, value) => {
        acc[value.letter] = createRef();
        return acc;
    }, {});

    const handleClick = id => {
        if (refs[id]) {
            refs[id].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

    const onClickCard = (friend) => {
        props.onSelect(friend);
    }

    const Block = (props) => {
        const { forwardedRef } = props;
        return (
            <>
                {props.item.letter ? <p ref={forwardedRef} className="caption semibold text-desaturate-normal">{props.item.letter}</p> : null}
            </>
        );
    };

    const ViewportBlock = handleViewport(Block);

    const enterViewportHandler = itemEntering => {
        letterRefs[itemEntering].current?.classList.add(classes['in-view']);
    }

    const leaveViewportHandler = itemLeaving => {
        letterRefs[itemLeaving].current?.classList.remove(classes['in-view']);
    }

    return (
        <div className="relative">
            <div id="list" className={classes['list'] + " flex flex-col " + (props.isInPicker ? classes["fixed-height"] : "")}>
                <Loading condition={props.isLoading}>
                    {props.children && props.children.length > 0 ?
                        props.children.map((item, index) => {
                            return (
                                <div key={index} className="mb-3">
                                    <div className="flex mb-3" ref={refs[item.letter]}>
                                        <ViewportBlock item={item} onEnterViewport={() => enterViewportHandler(item.letter)} onLeaveViewport={() => leaveViewportHandler(item.letter)} />
                                    </div>
                                    <div className={classes["card-grid"]}>
                                        {item.list.map((friend, i) => {
                                            return <BeneficiaryCard value={friend} key={i}
                                                classes="mr-4"
                                                cardType={props.cardType ? props.cardType : ""}
                                                click={() => (onClickCard(friend))}
                                            />
                                        })}
                                    </div>
                                </div>
                            )
                        }) : <div className='grid h-full content-center justify-center'>No results</div>
                    }
                </Loading>
            </div>
            <div className={classes["alphabet"] + (props.hideAlphabetList ? " hidden" : "")}>
                {Utils.alphabetLetters.map((letter, index) => {
                    return <p key={index} ref={letterRefs[letter]} className="caption cursor-pointer" onClick={() => handleClick(letter)}>
                        {letter}
                    </p>
                })}
            </div>
        </div>
    )
}

export default BeneficariesSorted
