import React, { useRef, useState } from 'react'

import { ReactComponent as SearchIcon } from 'assets/images/svg/search.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close-icon.svg';
import { CSSTransition } from 'react-transition-group';

import classes from './Search.module.css'

import Button from 'components/button/Button';

const Search = (props) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const { searchValue, setSearchValue } = props;

    const nodeRef = useRef(null);
    const inputNode = useRef();

    const openSearchHandler = () => {
        setIsSearchOpen(prevState => !prevState);
    }

    const searchHandler = (e) => {
        setSearchValue(e.target.value);
    }

    const deleteValueHandler = () => {
        setSearchValue("");
        inputNode.current.focus();
    }

    const closeSearchBoxHandler = () => {
        if (searchValue === "") {
            setIsSearchOpen(false);
        }
    }
    return (
        <div className="flex items-center ml-8">
            <SearchIcon className={[(isSearchOpen ? classes["searchIsOpen"] : ""), "cursor-pointer"].join(" ")} alt="search" onClick={openSearchHandler} />
            <CSSTransition in={isSearchOpen}
                timeout={300}
                nodeRef={nodeRef}
                unmountOnExit
                classNames={{
                    enter: classes.searchEnter,
                    enterActive: classes.searchEnterActive,
                    enterDone: classes.searchEnterDone,
                    exit: classes.searchExit,
                    exitActive: classes.searchExitActive,
                    exitDone: classes.searchExitDone,
                }}
            >
                <div className={classes['search'] + " ml-2 relative"} ref={nodeRef}>
                    <input
                        className="focus:outline-none bg-transparent"
                        autoFocus={true}
                        type="text"
                        placeholder="Search"
                        value={searchValue}
                        onChange={searchHandler}
                        onBlur={closeSearchBoxHandler}
                        ref={inputNode}
                    />
                    {searchValue.length > 0 ?
                        <Button
                            classes={classes["delete-value"] + ' focus:outline-none'}
                            clicked={deleteValueHandler}
                        >
                            <CloseIcon />
                        </Button>
                        : null
                    }
                </div>
            </CSSTransition>
        </div>
    )
}

export default Search
