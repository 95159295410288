import React, { useState } from 'react';

import { ReactComponent as DismissIcon } from 'assets/images/svg/close-small.svg';
import { ReactComponent as ErrorSign } from 'assets/images/svg/error.svg';

import classes from './UploadButton.module.css';

const UploadButton = props => {
    const [allFiles, setAllFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const checkNumberOfFilesAdded = (newFiles) => {
        let numberOfTotalFiles = allFiles.length + newFiles.length;

        if (numberOfTotalFiles > 3) {
            setErrorMessage('Cannot upload more than 3 files');

            return false;
        }
        return true;
    }

    const checkMimeType = (file) => {
        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'text/plain'];

        if (types.every(type => file.type !== type)) {
            setErrorMessage(`${file.type} is not a supported format`);

            return false;
        }
        return true;
    }

    const checkDuplicateFile = (file) => {
        const existingFiles = allFiles.map(file => file.name);

        if(existingFiles.includes(file.name)) {
            setErrorMessage(`Cannot upload duplicate files`);

            return false;
        }

        return true;
    };

    const handleChange = (event) => {
        let newFiles = [...event.target.files];

        if(checkNumberOfFilesAdded(newFiles)) {
            newFiles.forEach(file => {
                if(checkMimeType(file) && checkDuplicateFile(file)) {
                    setAllFiles(existentFiles => [...existentFiles, file]);
                }
                return file;
            })
        }
    }

    const onCancelClickedHandler = (name) => {
        let tempFiles = [...allFiles]
        setAllFiles(tempFiles.filter((file) => file.name !== name));
        setErrorMessage('');
    }

    let fileNameDiv = '';
    let fileNamesArray = [];
    allFiles.forEach(file => {
        fileNameDiv = (
            <span key={file.name} className={classes.fileName + ' mt-4 caption flex items-center justify-between'}>
                {file.name}
                <DismissIcon
                    name={file.name}
                    className={classes.closeFileUpload + ' cursor-pointer'}
                    onClick={() => onCancelClickedHandler(file.name)}
                />
            </span>
        )
        fileNamesArray.push(fileNameDiv);
        return file;
    })

    return (
        <>
            <input
                className={classes.fileUpload}
                id="file"
                onChange={handleChange}
                type="file"
                name={props.name}
                multiple
                {...props} />
            <label htmlFor="file" className={classes.uploadButton}>UPLOAD</label>


            { !! errorMessage ?
                <span className="caption text-reor flex items-center mt-6">
                    {errorMessage}
                    <ErrorSign className="ml-4" alt="error message" />
                </span>
                : null
            }

            { fileNamesArray }
        </>
    )
}

export default UploadButton;