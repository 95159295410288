import React, { useState, useEffect, useContext } from 'react';
import queryConfig from 'services/query';
import { useQuery } from "react-query";
import classes from './RequestsList.module.css'
import Loading from 'components/loading/Loading';
//import fetch from 'services/fetch';

import Search from 'components/search/Search';
import Filter from 'components/filter/Filter';
import RequestItem from './requestItem/RequestItem';
import SortingService from 'services/sortingService';
import { useTranslation } from 'react-i18next';
import Utils from 'services/utils';
import Enums from 'enums/Enums';
import OpenModal from 'components/smallModals/OpenModal';
import { AppContext } from 'context/app';
import { AuthContext } from 'context/auth-context';

const RequestList = (props) => {
    const { t: translate } = useTranslation();
    const [allRequests, setAllRequests] = useState([]);

    //const [showModal, setShowModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [checkedFilters, setCheckedFilters] = useState([{checked: true, id: 5, flag: "status"}]);

    const { username } = useContext(AppContext);
    const { isMerchant } = useContext(AuthContext);

    let params = {};
    params.filters = {
        CurrencyIso: props?.currency,
        RequestStatuses: [0, 1, 2] // 0Rejected, 1Confirmed, 2Pending
    }
    const { data: transactionList, isLoading } = useQuery(queryConfig.balanceGetRequests(params));
    const { data: friendsList, isLoading: isLoadingFriendList } = useQuery(queryConfig.getFriendRequests({ refetchOnWindowFocus: false, enabled: !isMerchant }));

    useEffect(() => {
        if (!isLoading && !isLoadingFriendList) {
            let tempFriendsList = [];
            if(!isMerchant) {
                tempFriendsList = friendsList.Items;
            }
            let mergedRequests = SortingService.mergeRequests(transactionList, tempFriendsList, { translate }, username);

            let searchedRequests = SortingService.filterListBySearch(mergedRequests, searchValue);

            let filteredRequests = SortingService.filterRequests(searchedRequests, checkedFilters);

            setAllRequests(filteredRequests);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionList, isLoading, friendsList, isLoadingFriendList, searchValue, checkedFilters])

    const setSearchValueHandler = (value) => {
        setSearchValue(value);
    }

    const setCheckedFiltersHandler = (value) => {
         setCheckedFilters(value);
    }

    const openRequestDetailsModal = (request) => {
        setSelectedRequest(request);
    }

    const closeModalHandler = () => {
        setSelectedRequest(null)
    }

    return <>
        <div className="request-list-header flex justify-between mb-10">
            <h3 className="flex items-center">
                <Loading condition={false}>
                    <select
                        className={["ml-2 h1 normal-weight bg-transparent cursor-pointer", classes['select']].join(' ')}
                        name="accounts"
                        id="accounts"
                    >
                        <option key="all-accounts" value="">All requests</option>
                    </select>
                </Loading>
            </h3>
            <div className="flex items-center" >
                <Search searchValue={searchValue} setSearchValue={setSearchValueHandler} />
                <Filter propsClasses="ml-4"
                    checkedFilters={checkedFilters}
                    setCheckedFilters={setCheckedFiltersHandler}
                    filterOptionsState={Enums.requestFilter}
                />
            </div>
        </div>

        <div className="request-list-group">
            <Loading condition={isLoading} type="listItem">
                <div className="request-list">
                    {
                        allRequests.length > 0 ?
                            allRequests.map((value, index) => {
                                return value.list.length > 0 ?
                                    <div key={index} className="mb-10">
                                        <p className="caption text-desaturate-light semibold mb-8">{Utils.beautifyDate(value.day)}</p>
                                        <div className="grid">
                                            {value.list.map(request => {
                                                return <RequestItem key={request.ID} request={request} onClick={() => openRequestDetailsModal(request)} />
                                            })}
                                        </div>
                                    </div> : null
                            }) :
                            <div>{translate("NoData")}</div>
                    }
                </div>
            </Loading>
        </div>
        {selectedRequest && <OpenModal request={selectedRequest} closeModal={closeModalHandler} />}
    </>
}

export default RequestList
