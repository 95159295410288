import React, { useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { useQuery } from "react-query";
import queryConfig from 'services/query';

import classes from './WalletList.module.css'
import WalletBox from 'components/walletList/walletBox/WalletBox';
import Loading from 'components/loading/Loading';
import { AppContext } from 'context/app';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/checked-icon.svg';
import Utils from 'services/utils';

var originalWalletList = [];
function WalletList(props) {
    let history = useHistory();
    const { incognito } = useContext(AppContext);
    const [gridLayout, setGridLayout] = useState('');
    const [showCheckbox, setShowCheckbox] = useState(false);

    const [walletList, setWalletList] = useState(null);

    const { data: wallets, isLoading: isLoadingWallets } = useQuery(queryConfig.getTotal());
    const { data: currencyRates, isLoading: isLoadingCurrencyRates } = useQuery(queryConfig.getCurrencyRates());

    useEffect(() => {
        if (!isLoadingWallets && !isLoadingCurrencyRates) {
            let newlist = [...wallets];
            setLayout(newlist);

            if (newlist.length > 2) {
                const { hasLowBalance } = Utils.getLowBalanceWallet(newlist, currencyRates);
                if (hasLowBalance) {
                    setShowCheckbox(true);
                }
            }
            originalWalletList = [...newlist];
            setWalletList(newlist);
        }
    }, [isLoadingWallets, wallets, isLoadingCurrencyRates, currencyRates])

    const setLayout = (walletList) => {
        if (walletList.length <= 4) {
            setGridLayout(`grid-cols-${walletList.length}`);
        } else {
            setGridLayout('multi-items');
        }
    }

    const walletSelectedHandler = (id) => {
        history.push(props.match.url + '/wallet/' + id)
    }

    const toggleCheckboxHandler = (evt) => {
        let isChecked = evt.target.checked;
        const { indexesWithLowBalance } = Utils.getLowBalanceWallet(walletList, currencyRates);
        if (isChecked) {
            let filterArr = walletList.filter((wallet,index) => {
                return indexesWithLowBalance.some(item => item !== index);
            });
            setLayout(filterArr);
            setWalletList(filterArr);
        } else {
            setLayout(originalWalletList);
            setWalletList(originalWalletList);
        }
    }

    return <div className={["relative", (gridLayout === 'grid-cols-2' ? "mb-8" : "mb-10")].join(' ')}>
        {showCheckbox &&
            <div className={classes["hide-balances"]}>
                <input type="checkbox" id="checkbox" className='checkbox-input' onChange={toggleCheckboxHandler} />
                <label htmlFor="checkbox" className="checkbox">
                    <CheckedIcon />
                </label>
                <p className="caption ml-2">Hide small balances ($1&lt;)</p>
            </div>
        }
        <Loading condition={isLoadingWallets} type="walletList">
            <div className={[classes.wallets, classes[gridLayout]].join(' ')}>
                {walletList?.length > 0 ?
                    walletList.map((value, index) => {
                        return <WalletBox key={value.CurrencyIso} classes={gridLayout} isIncognito={incognito} value={value} clicked={() => walletSelectedHandler(value.CurrencyIso)} />;
                    }) : <div>no data found</div>
                }
            </div>
        </Loading>
    </div>
}

export default withRouter(WalletList);