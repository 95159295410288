const getAccessLog = {"d":{
    loginHistoryRecords: [
        {
            ipAddress: "122.321.23.12",
            createdAt: new Date(),
            sourceInfo: "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X)"
        },
        {
            ipAddress: "131.44.214.2",
            createdAt: new Date(),
            sourceInfo: "Windows11"
        },
        {
            ipAddress: "227.422.12.1",
            createdAt: new Date(),
            sourceInfo: "SafariOS"
        }
    ]
}};
export default getAccessLog;