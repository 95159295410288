import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useQuery, useMutation, useQueryClient } from "react-query";
import queryConfig from 'services/query';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import fetch from 'services/fetch';
import * as Yup from 'yup';
import CustomInput from 'components/customInput/CustomInput';
import { ReactComponent as SwapIcon } from 'assets/images/svg/swap.svg';

import classes from './Exchange.module.css';
import Utils from 'services/utils';
import Loading from 'components/loading/Loading';
import Button from 'components/button/Button';
import NotificationContent from 'components/notifications/NotificationContent';

const Exchange = () => {
    let history = useHistory();
    const [exchangeListOfCurrencies, setExchangeListOfCurrencies] = useState([]);
    const [initialFormState, setInitialFormState] = useState({
        fromCurrency: '',
        toCurrency: '',
        fromAmount: '',
        toAmount: ''
    });

    const { data: walletData, isLoading: isWalletFetching } = useQuery(queryConfig.getTotal());
    const { data: currencyList } = useQuery(queryConfig.getSupportedCurrencies());

    const { data: rates, isLoading: isRatesFetching } = useQuery(queryConfig.getCurrencyRates());
    const [maxAmount, setMaxAmount] = useState('');

    const queryClient = useQueryClient();
    const {mutateAsync: mutateExchange} = useMutation(fetch("transferAmountBetweenCurrencies"), {
        onSuccess: () => {
            queryClient.invalidateQueries('cbo.balance.getTotal')
            queryClient.invalidateQueries('cbo.balance.getRows')
        }
    });

    useEffect(() => {
        if (walletData && walletData.length>0 && currencyList) {
            setMaxAmount(walletData[0].Current);
            //combine existent wallets with all possible exchange currencies
            let temp = [...walletData];
            currencyList.forEach(currencyItem => {
                let existentWallet = walletData.find(walletItem => walletItem.CurrencyIso === currencyItem);
                if (!existentWallet) {
                    temp.push({
                        CurrencyIso: currencyItem,
                        Current: 0,
                        Expected: 0,
                        Pending: 0
                    });
                }
            });
            setExchangeListOfCurrencies(temp);
            setInitialFormState(prevState => ({
                ...prevState,
                fromCurrency: walletData[0]?.CurrencyIso,
                toCurrency: temp[1]?.CurrencyIso
            }));
        }
    }, [walletData, currencyList]);

    const handleFromCurrencyChange = (selected, setFieldValue, formValues) => {
        setFieldValue('fromCurrency', selected.target.value);
        walletData.forEach((value) => {
            if(value.CurrencyIso === selected.target.value) {
                setMaxAmount(value.Current);
            }
        });

        // if newly selected value is equal with the value from 
        // the other dropdown switch values
        if (selected.target.value === formValues.toCurrency) {
            setFieldValue('toCurrency', formValues['fromCurrency'])
        }
        if (+formValues.fromAmount > 0) {
            let result = 0;
            if (selected.target.value === formValues.toCurrency) {
                result = Utils.calculateResult(formValues.fromAmount, selected.target.value, formValues['fromCurrency'], rates);
            } else {
                result = Utils.calculateResult(formValues.fromAmount, selected.target.value, formValues.toCurrency, rates);
            }
            setFieldValue('toAmount', result);
        }
    }

    const handleToCurrencyChange = (selected, setFieldValue, formValues) => {
        setFieldValue('toCurrency', selected.target.value);

        // if newly selected value is equal with the value from
        // the other dropdown switch values
        if (selected.target.value === formValues.fromCurrency) {
            setFieldValue('fromCurrency', formValues['toCurrency'])
        }
        if (+formValues.toAmount > 0) {
            let result = 0;
            if (selected.target.value === formValues.fromCurrency) {
                result = Utils.calculateResult(formValues.toAmount, selected.target.value, formValues['toCurrency'], rates);
            } else {
                result = Utils.calculateResult(formValues.toAmount, selected.target.value, formValues.fromCurrency, rates);
            }
            setFieldValue('fromAmount', result);
        }
    }

    const handleFromAmountChange = (selected, setFieldValue, formValues) => {
        setFieldValue('fromAmount', selected.target.rawValue);
        let result = Utils.calculateResult(selected.target.rawValue, formValues.fromCurrency, formValues.toCurrency, rates);
        setFieldValue('toAmount', result);
    }

    const handleToAmountChange = (selected, setFieldValue, formValues) => {
        setFieldValue('toAmount', selected.target.rawValue);
        let result = Utils.calculateResult(selected.target.rawValue, formValues.toCurrency, formValues.fromCurrency, rates);
        setFieldValue('fromAmount', result);
    }

    const closeModalHandler = (event) => {
        event.preventDefault();
        history.goBack();
    }

    const switchCurrency = (event, setFieldValue, formValues) => {
        event.preventDefault();
        let aux = formValues["fromCurrency"];
        setFieldValue('fromCurrency', formValues['toCurrency']);
        setFieldValue('toCurrency', aux);
    }

    return (
        <>
            <h3 className="mb-16 pb-2">Exchange</h3>
            <Formik
                initialValues={initialFormState}
                validationSchema={Yup.object({
                    fromAmount: Yup.number()
                        .max(maxAmount, `The maximum allowed amount is ${maxAmount}`)
                        .moreThan(2, 'Amount must be larger than 2'),
                    toAmount: Yup.number()
                })}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    await mutateExchange({
                        SourceCurrency: values.fromCurrency,
                        SourceAmount: values.fromAmount,
                        TargetCurrency: values.toCurrency,
                        TargetAmount: null,
                        isFromAdmin: false
                    });

                    toast(
                        <NotificationContent type="exchange" data={values} />, {
                            className: 'white-toast default-toast'
                    });

                    history.goBack();
                }}
            >
                {({
                    values,
                    errors,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting
                }) => (
                        <Form>
                            <div className="flex mb-10">
                                <div className={classes.wallet}>
                                    <label className="text mb-2 inline-flex opacity-50 w-16" htmlFor="fromCurrency">From:</label>
                                    <div className="flex">
                                        <p className="mr-20 h3">{`${values.fromCurrency}`} account</p>
                                        <Loading condition={isWalletFetching}>
                                            <select className={classes.walletAmount}
                                                onChange={(value) => { value.persist(); handleFromCurrencyChange(value, setFieldValue, values); }}
                                                onBlur={() => setFieldTouched("fromCurrency", true)}
                                                name="fromCurrency"
                                                value={values.fromCurrency}
                                            >
                                                {walletData?.length > 0 ?
                                                    walletData.map((value, index) => {
                                                        return <option key={value.CurrencyIso} value={value.CurrencyIso}>
                                                            {Utils.currencyCodes[value.CurrencyIso]} {value.Current}
                                                        </option>
                                                    })
                                                    : <option>no data found</option>
                                                }
                                            </select>
                                        </Loading>
                                    </div>
                                </div>
                                <div>
                                <Button
                                    classes="mr-5 ml-5 mt-2 rounded-full"
                                    btnTheme="primary"
                                    size="medium"
                                    clicked={(event) => {switchCurrency(event, setFieldValue, values); }}
                                    type="submit"
                                    case="uppercase" >
                                         <SwapIcon/>
                                </Button>
                                </div>
                                <div className={classes.wallet}>
                                    <label className="text mb-2 inline-flex opacity-50 w-16" htmlFor="toCurrency">To:</label>
                                    <div className="flex">
                                        <p className="mr-20 h3">{`${values.toCurrency}`} account</p>
                                        <Loading condition={isWalletFetching}>
                                            <select className={classes.walletAmount}
                                                onChange={(value) => { value.persist(); handleToCurrencyChange(value, setFieldValue, values) }}
                                                onBlur={() => setFieldTouched("toCurrency", true)}
                                                name="toCurrency"
                                                value={values.toCurrency}
                                            >
                                                {exchangeListOfCurrencies?.length > 0 ?
                                                    exchangeListOfCurrencies.map((value, index) => {
                                                        return <option key={value.CurrencyIso} value={value.CurrencyIso}>
                                                            {Utils.currencyCodes[value.CurrencyIso]} {value.Current}
                                                        </option>
                                                    })
                                                    : <option>no data found</option>
                                                }
                                            </select>
                                        </Loading>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-10">
                                <label className="flex mb-2 opacity-50" htmlFor="fromAmount">Spend:</label>
                                <div className="flex">
                                    <p className={"huge-font mono text-metal " + classes.amountSign}>{Utils.currencyCodes[`${values.fromCurrency}`]}</p>
                                    <Field
                                        id="fromAmount"
                                        value={values.fromAmount}
                                        className={"huge-font mono " + classes.amount}
                                        name="fromAmount"
                                        placeholder="0"
                                        options={{
                                            numeral: true,
                                            numeralPositiveOnly: true
                                        }}
                                        onChange={(value) => { handleFromAmountChange(value, setFieldValue, values) }}
                                        onBlur={() => setFieldTouched("fromAmount", true)}
                                        component={CustomInput} />
                                </div>
                                {errors.fromAmount && <div className={classes.error + " text-reor"}>{errors.fromAmount}</div>}
                            </div>

                            <div className="mb-10">
                                <label className="flex mb-2 opacity-50" htmlFor="toAmount">Receive:</label>
                                <div className="flex">
                                    <p className={"huge-font mono text-metal " + classes.amountSign}>{Utils.currencyCodes[`${values.toCurrency}`]}</p>
                                    <Field
                                        id="toAmount"
                                        value={values.toAmount}
                                        className={"huge-font mono " + classes.amount}
                                        name="toAmount"
                                        placeholder="0"
                                        options={{
                                            numeral: true,
                                            numeralPositiveOnly: true
                                        }}
                                        onChange={(value) => { handleToAmountChange(value, setFieldValue, values) }}
                                        onBlur={() => setFieldTouched("toAmount", true)}
                                        component={CustomInput} />
                                </div>
                                {errors.toAmount && <div className={classes.error + " text-reor"}>{errors.toAmount}</div>}
                            </div>

                            <p className="text mb-2 opacity-50" htmlFor="rate">Exchange rate:</p>
                            <Loading condition={isRatesFetching}>
                                <h3 name="rate" className="mb-20">1 {`${values.fromCurrency}`} = {rates ? Utils.getExchangeRate(values.fromCurrency, values.toCurrency, rates) : ''} {`${values.toCurrency}`}</h3>
                            </Loading>

                            <div className="flex items-center">
                                <Button
                                    classes="py-3 px-4 mr-3"
                                    btnTheme="primary"
                                    size="normal"
                                    clicked={handleSubmit}
                                    type="submit"
                                    case="uppercase" >
                                        Exchange
                                </Button>
                                <Button
                                    classes="py-3 mr-3"
                                    btnTheme="ghost"
                                    size="normal"
                                    clicked={closeModalHandler}
                                    case="uppercase" >
                                        Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
            </Formik>
        </>
    )
}

export default Exchange;
