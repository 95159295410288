import React from 'react';
import classes from './WalletBox.module.css';
import 'assets/css/flags.css';

const WalletBox = (props) => {
    return (
        <button
            onClick={props.clicked}
            className={
                [classes.wallet,
                    "bg-white p-6 cursor-pointer text-left focus:outline-none",
                (props.classes ? classes[props.classes] : "")
                ].join(' ')
            }
        >
            <div className={`currency-flag currency-flag-${props.value?.CurrencyIso?.toLowerCase()}`}></div>

            <h3 className={[classes['currency'], ""].join(' ')}>{props.value.CurrencyIso}</h3>

            <h3 style={props.isIncognito ? { filter: 'blur(10px)' } : null}
                className={[classes['amount'], "medium-font mono"].join(' ')}>
                {props.value.Current}
            </h3>
        </button>
    )
}

export default WalletBox;
