import React from 'react';
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import fetch from 'services/fetch';
import Button from 'components/button/Button';

const InternalBeneficiary = () => {
    let history = useHistory();
    const { id } = useParams();

    const queryClient = useQueryClient();
    const {mutateAsync} = useMutation(fetch("removeFriend"),{
        onSuccess: () => {
            queryClient.invalidateQueries('cbo.customer.getFriends')
        }
    });

    const removeFriend = async () => {
        await mutateAsync({
            destWalletId: id
        });
        toast("Friend removed successfully!");
        history.goBack();
    }

    return (
        <div>
            InternalBeneficiary
            <br/>
            <Button btnTheme="secondary" size="normal" clicked={removeFriend}>Delete Friend</Button>
        </div>
    )
}

export default InternalBeneficiary
