import React, { useContext, useRef, useEffect, useState } from 'react'

import classes from './BeneficiaryPicker.module.css'
import { SendContext } from 'context/send';
import BeneficiariesSorted from 'components/beneficiaries/beneficiariesSorted/BeneficiariesSorted';
import SortingService from 'services/sortingService';
import PlusIcon from 'components/icons/PlusIcon';
import Button from 'components/button/Button';

import { ReactComponent as SearchIcon } from 'assets/images/svg/search.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/svg/close-small.svg';

var hideAlphabetList = false;

const BeneficiaryPicker = ({clickedOutside, className, isLoadingLists, sortedFriendList, setSortedFriendList, initialSortedFriendList}) => {
    const { setSelectedFriend, setWithAddBeneficiary, setIsExternalSelected } = useContext(SendContext);
    const [searchValue, setSearchValue] = useState("");
    const node = useRef();
    const searchNode = useRef();

    const handleClickOutsideModal = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }

        // outside click
        clickedOutside();
    }

    const handleKeyPress = (e) => {
        if (e.key === "Escape") {
            clickedOutside();
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideModal);
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideModal);
            document.removeEventListener("keydown", handleKeyPress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const selectedFriendHandler = (friendInfo) => {
        setIsExternalSelected(!friendInfo.isInternal);
        setSelectedFriend(friendInfo);
    }

    const withAddBeneficiaryHandler = () => {
        setIsExternalSelected(true);
        setWithAddBeneficiary(true);
    }

    useEffect(() => {
        if (searchValue === "") {
            setSortedFriendList(initialSortedFriendList);
            hideAlphabetList = false;
        } else {
            setSortedFriendList(SortingService.filterAllFriends(initialSortedFriendList, searchValue));
            hideAlphabetList = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    const searchHandler = e => {
        setSearchValue(e.target.value);
    }

    const deleteSearchValueHandler = () => {
        setSearchValue("");
        searchNode.current.focus();
    }

    return (
        <div id="beneficiaryPicker" className={classes.container + " " + (className ? className : "") + " pl-8 pr-4"} ref={node}>
            <div className="flex justify-between">
                <div className={classes['search-container']}>
                    <input className={classes['search-input']} ref={searchNode} type="text" placeholder="Search" autoFocus={true} value={searchValue} onChange={searchHandler} />
                    <SearchIcon className={classes['search-icon']} alt="search" />
                    <DeleteIcon className={classes['delete-icon']} alt="delete" onClick={deleteSearchValueHandler} />
                </div>
                <Button
                    classes="flex items-center p-2"
                    clicked={withAddBeneficiaryHandler}
                    btnTheme="primary"
                    size="small"
                    case="lowercase"
                    >
                        <PlusIcon size={16} className="mr-1"/>
                        <p className="caption"> add </p>
                </Button>
            </div>

            <div className="mt-5">
                <BeneficiariesSorted cardType="small-card" isInPicker={true} hideAlphabetList={hideAlphabetList} isLoading={isLoadingLists} onSelect={(friend) => selectedFriendHandler(friend)}>
                    {sortedFriendList}
                </BeneficiariesSorted>
            </div>
        </div>
    )
}

export default BeneficiaryPicker
