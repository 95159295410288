import InputField from 'components/inputField/InputField'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import classes from '../NewBeneficiaryForm.module.css';
import { useQuery } from 'react-query';
import queryConfig from 'services/query';
import { Field } from 'formik';
import Loading from 'components/loading/Loading';

const NewBeneficiaryInputs = ({ touched, errors }) => {
    const { t } = useTranslation();
    const [countriesList, setCountriesList] = useState([]);

    const { data: staticData, isLoading: isLoadingStaticData } = useQuery(queryConfig.getStaticData({ refetchOnWindowFocus: false }));
    const { data: currencyList, isLoading: isLoadingCurrencyList } = useQuery(queryConfig.getSupportedCurrencies({ refetchOnWindowFocus: false }));

    useEffect(()=>{
        if(!isLoadingStaticData) {
            let countriesTemp = staticData.Countries.sort((a,b) => a.Name.localeCompare(b.Name));
            setCountriesList(countriesTemp);
        }
    }, [isLoadingStaticData, staticData])

    return <>
        <InputField
            name="nickname"
            placeholder={t("labels.NicknamePlaceholder")}
            errors={errors}
            touched={touched}
            classes="mb-3"
        >
            {t("labels.Nickname")}
        </InputField>

        <div className="mb-12">
            <label className="caption flex text-desaturate-light" htmlFor="country">{t("labels.Country")}</label>
            <Loading condition={isLoadingStaticData}>
                <Field name="country" className="select" as="select" required>
                    <option value="" disabled hidden>{t("labels.SelectCountry")}</option>
                    {countriesList ?
                        countriesList.map(country => {
                            return <option key={country.Key} value={country.Key}>{country.Name}</option>
                        }) : <option>no data found</option>
                    }
                </Field>
            </Loading>
        </div>

        <div className="mb-3">
            <label className="caption flex text-desaturate-light" htmlFor="bankCountry">{t("labels.SelectBankCountry")}</label>
            <Loading condition={isLoadingStaticData}>
                <Field name="bankCountry" className="select" as="select" required>
                    <option value="" disabled hidden>{t("labels.SelectCountry")}</option>
                    {countriesList ?
                        countriesList.sort().map(country => {
                            return <option key={country.Key} value={country.Key}>{country.Name}</option>
                        }) : <option>no data found</option>
                    }
                </Field>
            </Loading>
        </div>

        <div className="mb-5">
            <label className="caption flex text-desaturate-light" htmlFor="currency">{t("labels.Currency")}</label>
            <Loading condition={isLoadingCurrencyList}>
                <Field name="currency" className="select" as="select" required>
                    <option value="" disabled hidden>{t("labels.SelectCurrency")}</option>
                    {currencyList ?
                        currencyList.map((currency, index) => {
                            return <option key={index} value={currency}>{currency}</option>
                        }) : <option>no data found</option>
                    }
                </Field>
            </Loading>
        </div>

        <InputField
            name="name"
            placeholder={t("labels.Name")}
            errors={errors}
            touched={touched}
            classes='mb-6'
        >
            {t("labels.Name")}
        </InputField>

        <div className="caption flex text-desaturate-light mb-5" id="my-radio-group">{t("labels.CounterpartyType")}</div>
        <div className="flex" role="group" aria-labelledby="my-radio-group">
            <div className="flex items-center mr-6">
                <Field className={classes['radio-input']} id="business" type="radio" name="counterpartyType" value="bussiness" />
                <label className={classes['radio-checkbox']} htmlFor="business"></label>
                <label className="ml-2 cursor-pointer" htmlFor="business">{t("labels.Bussiness")}</label>
            </div>

            <div className="flex items-center">
                <Field className={classes['radio-input']} id="individual" type="radio" name="counterpartyType" value="individual" />
                <label className={classes['radio-checkbox']} htmlFor="business"></label>
                <label className="ml-2 cursor-pointer" htmlFor="individual">{t("labels.Individual")}</label>
            </div>
        </div>
    </>
}

export default NewBeneficiaryInputs
