import React from 'react';
import classes from './ToggleSwitch.module.css';

const ToggleSwitch = ({ name, value, onChange }) => {

  const handleChange = function(e) {
    if(onChange && typeof onChange === 'function') {
      onChange(e.target.checked);
    }
  };

  return (
    <label className={classes["switch"]}>
      <input type="checkbox" name={name} value={value} onChange={handleChange} />
      <span className={classes["slider"]} />
    </label>
  )
};

export default ToggleSwitch;
