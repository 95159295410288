import React from 'react';

const Tabs = (props) => {
    return (
        <div className="tabs flex mt-6 pt-1 mb-8">
            {props.children}
        </div>
    )
}

export default Tabs;
