const getStaticData = {"d":{
    Countries:[{
        Icon: "ru",
        Key: "ru",
        Name: "Russia"
    },
    {
        Icon: "ro",
        Key: "ro",
        Name: "Romania"
    },
    {
        Icon: "es",
        Key: "es",
        Name: "Spain"
    },
    {
        Icon: "de",
        Key: "de",
        Name: "Germany"
    }]
}};
export default getStaticData;