const balance = {
    "d": [
        {
            "Amount": 300,
            "BalanceTransferType": 1,
            "BalanceTransferTypeName": "Fee",
            "Comment": "",
            "CurrencyIso": "CAD",
            "ID": 64,
            "InsertDate": "/Date(1593594283000+0000)/",
            "IsPending": false,
            "SourceAccountID": null,
            "SourceAccountName": "",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "",
            "SourceID": 0,
            "SourceType": "System.AdminFee",
            "TargetAccountID": null,
            "TargetAccountName": "",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "",
            "Text": "testdsfds",
            "Total": 300.3,
            "__type": "Balance.BalanceRow:WebServices"
        },
        {
            "Amount": 500,
            "BalanceTransferType": 2,
            "BalanceTransferTypeName": "Test Deposit",
            "Comment": "",
            "CurrencyIso": "EUR",
            "ID": 63,
            "InsertDate": "/Date(1593519255000+0000)/",
            "IsPending": false,
            "SourceAccountID": null,
            "SourceAccountName": "",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "",
            "SourceID": 0,
            "SourceType": "System.AdminFee",
            "TargetAccountID": null,
            "TargetAccountName": "",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "",
            "Text": "thank you",
            "Total": 500.09,
            "__type": "Balance.BalanceRow:WebServices"
        },
        {
            "Amount": -0.01,
            "BalanceTransferType": null,
            "BalanceTransferTypeName": "",
            "Comment": "",
            "CurrencyIso": "EUR",
            "ID": 61,
            "InsertDate": "/Date(1593439341000+0000)/",
            "IsPending": false,
            "SourceAccountID": 192,
            "SourceAccountName": "Lethv2 LastName",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "Customer",
            "SourceID": 59,
            "SourceType": "TransferCur.ConversionFee",
            "TargetAccountID": 192,
            "TargetAccountName": "Lethv2 LastName",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "Customer",
            "Text": "Transfer Between Currencies Conversion Fee",
            "Total": 0.09,
            "__type": "Balance.BalanceRow:WebServices"
        },
        {
            "Amount": 0.3,
            "BalanceTransferType": null,
            "BalanceTransferTypeName": "",
            "Comment": "",
            "CurrencyIso": "CAD",
            "ID": 67,
            "InsertDate": "/Date(1593439341000+0000)/",
            "IsPending": false,
            "SourceAccountID": 192,
            "SourceAccountName": "Lethv2 LastName",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "Customer",
            "SourceID": 59,
            "SourceType": "System.TransferCurrencies",
            "TargetAccountID": 192,
            "TargetAccountName": "Lethv2 LastName",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "Customer",
            "Text": "transfer between currencies",
            "Total": 0.3,
            "__type": "Balance.BalanceRow:WebServices"
        },{
            "Amount": 0.5,
            "BalanceTransferType": null,
            "BalanceTransferTypeName": "",
            "Comment": "",
            "CurrencyIso": "CAD",
            "ID": 65,
            "InsertDate": "/Date(1593439341000+0000)/",
            "IsPending": false,
            "SourceAccountID": 192,
            "SourceAccountName": "Lethv2 LastName",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "Customer",
            "SourceID": 59,
            "SourceType": "System.TransferCurrencies",
            "TargetAccountID": 192,
            "TargetAccountName": "Lethv2 LastName",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "Customer",
            "Text": "transfer between currencies",
            "Total": 0.3,
            "__type": "Balance.BalanceRow:WebServices"
        },{
            "Amount": 0.4,
            "BalanceTransferType": null,
            "BalanceTransferTypeName": "",
            "Comment": "",
            "CurrencyIso": "USD",
            "ID": 60,
            "InsertDate": "/Date(1593439341000+0000)/",
            "IsPending": false,
            "SourceAccountID": 192,
            "SourceAccountName": "Lethv2 LastName",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "Customer",
            "SourceID": 59,
            "SourceType": "System.TransferCurrencies",
            "TargetAccountID": 192,
            "TargetAccountName": "Lethv2 LastName",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "Customer",
            "Text": "transfer between currencies",
            "Total": 0.3,
            "__type": "Balance.BalanceRow:WebServices"
        },
        {
            "Amount": -0.2,
            "BalanceTransferType": null,
            "BalanceTransferTypeName": "",
            "Comment": "",
            "CurrencyIso": "GBP",
            "ID": 59,
            "InsertDate": "/Date(1593439341000+0000)/",
            "IsPending": false,
            "SourceAccountID": 192,
            "SourceAccountName": "Lethv2 LastName",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "Customer",
            "SourceID": 0,
            "SourceType": "System.TransferCurrencies",
            "TargetAccountID": 192,
            "TargetAccountName": "Lethv2 LastName",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "Customer",
            "Text": "transfer between currencies",
            "Total": 0.1,
            "__type": "Balance.BalanceRow:WebServices"
        },
        {
            "Amount": 0.3,
            "BalanceTransferType": 2,
            "BalanceTransferTypeName": "Test Deposit",
            "Comment": "",
            "CurrencyIso": "EUR",
            "ID": 58,
            "InsertDate": "/Date(1593433372000+0000)/",
            "IsPending": false,
            "SourceAccountID": null,
            "SourceAccountName": "",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "",
            "SourceID": 0,
            "SourceType": "System.Admin",
            "TargetAccountID": null,
            "TargetAccountName": "",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "",
            "Text": "testing account",
            "Total": 0.3,
            "__type": "Balance.BalanceRow:WebServices"
        },
        {
            "Amount": 0.5,
            "BalanceTransferType": 2,
            "BalanceTransferTypeName": "Test Deposit",
            "Comment": "",
            "CurrencyIso": "USD",
            "ID": 57,
            "InsertDate": "/Date(1593433355000+0000)/",
            "IsPending": false,
            "SourceAccountID": null,
            "SourceAccountName": "",
            "SourceAccountProfileImage": null,
            "SourceAccountProfileImageSize": 0,
            "SourceAccountType": "",
            "SourceID": 0,
            "SourceType": "System.Admin",
            "TargetAccountID": null,
            "TargetAccountName": "",
            "TargetAccountProfileImage": null,
            "TargetAccountProfileImageSize": 0,
            "TargetAccountType": "",
            "Text": "testing account wallet",
            "Total": 0.5,
            "__type": "Balance.BalanceRow:WebServices"
        }
    ]
};
export default balance;