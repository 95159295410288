import React from "react";
import Button from "components/button/Button";
import { useAuth0 } from "@auth0/auth0-react";

const Landing = () => {
    const { loginWithRedirect } = useAuth0();
    function submit() {
        loginWithRedirect({
            redirect_uri: 'http://localhost:3000/accounts'
        });
    }
    return <>
        <h1>Mocked Data Version + Test Github</h1>
        <Button
            clicked={submit}
            btnTheme="primary"
            size="large"
            case="uppercase" >
            <p className="small semibold">Log in</p>
        </Button>
    </>
}

export default Landing