import React, { useState, useEffect } from 'react';
import queryConfig from 'services/query';
import { useQuery } from "react-query";
import Loading from 'components/loading/Loading';
import Utils from 'services/utils';
import SortingService from 'services/sortingService';
import { useTranslation } from 'react-i18next';

import Filter from 'components/filter/Filter';
import TransactionItem from './transactionItem/TransactionItem';
import Export from 'components/export/Export';
import Search from 'components/search/Search';
import classes from './TransactionList.module.css';
import OpenModal from 'components/smallModals/OpenModal';
import Enums from 'enums/Enums';

const TransactionList = (props) => {
    const [page] = useState(1);

    let params = {};
    params.sortAndPage = {
        PageNumber: page,
        PageSize: 1000
    }

    const { t: translate } = useTranslation();
    const [transactions, setTransactions] = useState([]);
    const [balanceGetRowsFilter] = useState(params);
    const [searchValue, setSearchValue] = useState("");
    const [checkedFilters, setCheckedFilters] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(props.currency);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const { data: walletList, isFetching: isFetchingWalletList } = useQuery(queryConfig.getTotal({ refetchOnWindowFocus: false }));
    const { data: transactionList, isLoading } = useQuery(queryConfig.balanceGetRows(balanceGetRowsFilter));

    const isInWallet = () => {
        return props?.currency;
    }

    useEffect(() => {
        if (!isLoading && transactionList) {
            let allTransactions = SortingService.transactionsListGrooming(transactionList, selectedCurrency, { translate });

            let searchedTransactions = SortingService.filterListBySearch(allTransactions, searchValue);

            let filteredTransactions = SortingService.filterTransactions(searchedTransactions, checkedFilters);

            setTransactions(filteredTransactions);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [transactionList, isLoading, searchValue, checkedFilters]
    );

    const currencyFilterChange = (event) => {
        let value = event.target.value;
        setSelectedCurrency(value);
        if (!isLoading && transactionList) {
            setTransactions(SortingService.transactionsListGrooming(transactionList, value, { translate }));
        }
    }

    const setCheckedFiltersHandler = (value) => {
        setCheckedFilters(value);
    }

    const setSearchValueHandler = (value) => {
        setSearchValue(value);
    }

    const openTransactionDetailsModal = (transaction) => {
        setSelectedTransaction(transaction);
    }

    const closeModalHandler = () => {
        setSelectedTransaction(null)
    }

    return <div>
        <div className="transaction-list-header flex justify-between mb-10">
            <h3 className="flex items-center">
                {isInWallet() ?
                    props.title :
                    <Loading condition={isFetchingWalletList}>
                        <select
                            className={["ml-2 h1 normal-weight bg-transparent cursor-pointer", classes['select']].join(' ')}
                            name="accounts"
                            id="accounts"
                            value={selectedCurrency}
                            onChange={currencyFilterChange}
                        >
                            <option key="all-accounts" value="">{translate("AllAccounts")}</option>
                            {walletList &&
                                walletList.map((wallet) => {
                                    return <option key={wallet.CurrencyIso} value={wallet.CurrencyIso}>{translate("CurrAccount", { currencyIso: wallet.CurrencyIso })}</option>
                                })}
                        </select>
                    </Loading>
                }
            </h3>
            <div className="flex items-center" >
                <p className="caption"><Export data={transactionList} /></p>
                <Search searchValue={searchValue} setSearchValue={setSearchValueHandler} />
                {!isInWallet() ? <Filter propsClasses="ml-4"
                    checkedFilters={checkedFilters}
                    setCheckedFilters={setCheckedFiltersHandler}
                    filterOptionsState={Enums.transactionFilter}
                />
                    : null}
            </div>
        </div>
        <div className="transaction-list-group">
            <Loading condition={isLoading} type="listItem">
                <div className="transaction-list">
                    {
                        transactions.length > 0 ?
                            transactions.map((value, index) => {
                                return value.list.length > 0 ?
                                    <div key={index} className="mb-4">
                                        <p className="caption text-desaturate-light mb-4">{Utils.beautifyDate(value.day)}</p>
                                        <div className="grid">
                                            {value.list.map(transaction => {
                                                return <TransactionItem key={transaction.id} transactionDetails={transaction} onClick={() => openTransactionDetailsModal(transaction)} />
                                            })}
                                        </div>
                                    </div> : null
                            }) :
                            <div>{translate("NoData")}</div>
                    }
                </div>
            </Loading>
        </div>
        {selectedTransaction && <OpenModal transaction={selectedTransaction} closeModal={closeModalHandler} /> }
    </div>
}

export default TransactionList
