const customer = {
    "d": {
        "AboutMe": "",
        "ActiveStatus": "FullyActive",
        "AddressLine1": null,
        "AddressLine2": null,
        "CellNumber": "0040773869122",
        "City": null,
        "CountryIso": "RO",
        "CustomerNumber": "8851424",
        "DateOfBirth": null,
        "Education": "",
        "EmailAddress": "workleth+test_payset_app@gmail.com",
        "ExtendedDetails": null,
        "FirstName": "Leth",
        "Gender": "Male",
        "GroupID": null,
        "Industry": null,
        "Interests": [],
        "IsAgreePolicy": false,
        "IsAgreeTerms": false,
        "KYCLevel": 0,
        "LastName": "Mike",
        "MaritalStatus": "",
        "PersonalNumber": null,
        "PhoneNumber": null,
        "PostalCode": null,
        "ProfileImage": null,
        "ProfileImageSize": 0,
        "RegistrationDate": "/Date(1593419826000+0000)/",
        "SignificantOther": null,
        "Skills": [],
        "StateIso": null,
        "UserName": null,
        "__type": "Customer.CustomerData:WebServices"
    }
};
export default customer;