const getRulesForAddABeneficary = {
    "details": [
        {
            "payment_type": "priority",
            "beneficiary_entity_type": "individual",
            "beneficiary_address": "^.{1,255}",
            "beneficiary_city": "^.{1,255}",
            "beneficiary_country": "^[A-z]{2}$",
            "beneficiary_first_name": "^.{1,255}",
            "beneficiary_last_name": "^.{1,255}",
            "iban": "([A-Z0-9]\\s*){15,34}",
            "bic_swift": "^[0-9A-Z]{8}$|^[0-9A-Z]{11}$"
        },
        {
            "payment_type": "priority",
            "beneficiary_entity_type": "company",
            "beneficiary_address": "^.{1,255}",
            "beneficiary_city": "^.{1,255}",
            "beneficiary_country": "^[A-z]{2}$",
            "beneficiary_company_name": "^.{1,255}",
            "iban": "([A-Z0-9]\\s*){15,34}",
            "bic_swift": "^[0-9A-Z]{8}$|^[0-9A-Z]{11}$"
        },
        {
            "payment_type": "regular",
            "iban": "([A-Z0-9]\\s*){15,34}",
            "beneficiary_entity_type": "individual"
        },
        {
            "payment_type": "regular",
            "iban": "([A-Z0-9]\\s*){15,34}",
            "beneficiary_entity_type": "company"
        }
    ]
};
export default getRulesForAddABeneficary;