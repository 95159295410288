const currencyRates = {
    "d": [
        {
            "BaseRate": 1,
            "CurrencyIsoCode": "USD"
        },
        {
            "BaseRate": 0.9,
            "CurrencyIsoCode": "EUR"
        },
        {
            "BaseRate": 1,
            "CurrencyIsoCode": "CAD"
        }
    ]
}
export default currencyRates;