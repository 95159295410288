import React from 'react';

import classes from './Button.module.css';

import { ReactComponent as LoadingIcon } from 'assets/images/svg/loading.svg';

const Button = (props) => {
    let buttonType = !!props.type ? props.type : ''

    let children = props.children;
    if (props.isLoading) {
        children = <LoadingIcon className={classes["btn-loading"]} />
    }
    return (
        <button
            onClick={props.clicked}
            className={[
                (!!props.classes ? props.classes : ''),
                (props.isLoading ? classes.isLoading : ''),
                classes.button,
                classes[props.btnTheme],
                classes[props.size],
                classes[props.case]
            ].join(' ')}
            disabled={props.disabled}
            type={buttonType} >
            {children}
        </button>
    )
}

export default Button;
