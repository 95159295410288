import React from "react";
import Tabs from 'components/tabs/Tabs';
import NavigationItem from 'components/navigationItem/NavigationItem';
import Account from './account/Account';
import Security from "./security/Security";
import {Switch, Route, Redirect} from "react-router-dom";

import classes from './Settings.module.css';

function Settings() {
    let base = '/settings';
    let tabs = (
        <Tabs>
            <NavigationItem
                exact
                link={`${base}/account`}
                classes={"cursor-pointer semibold mr-6 active-with-dot opacity-50"}
                type="tab" >
                    Account
            </NavigationItem>
            <NavigationItem
                link={`${base}/security`}
                classes={"cursor-pointer semibold mr-6 active-with-dot opacity-50"}
                type="tab" >
                    Security
            </NavigationItem>
        </Tabs>
    )

    return (
        <>
            <p className={classes.title}>Settings</p>
            {tabs}
            <Switch>
                <Route path={`${base}/security`}><Security path={`${base}/security`} /></Route>
                <Route path={`${base}/account`}><Account /></Route>
                <Redirect from={base} to={`${base}/account`} />
            </Switch>
        </>
    )
}

export default Settings;